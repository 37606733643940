import styled from "styled-components";

export const StyledLoaderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: ${({ theme }) => theme.colors.secondaryBackground};
    z-index: 100;
    pointer-events: none;
`;

export const StyledLoader = styled.div`
    &::after {
        content: "";
        display: block;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        border: 5px solid;
        border-color: ${({ theme }) => theme.colors.primary} transparent ${({ theme }) => theme.colors.primary}
            transparent;
        animation: loading-animation 1.2s linear infinite;
    }

    @keyframes loading-animation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;
