import React from "react";
import { useTranslation } from "react-i18next";
import { UserEventLogCodeTranslations } from "../../../constants";
import { EventLog, EventsResponse } from "../../types";
import Skeleton from "../Skeleton";
import { VirtualizedAutosizer, VirtualizedColumn, VirtualizedTable } from "../virtualized";

type UserLogsProps = {
    events: EventsResponse | undefined;
    isLoading: boolean;
    isError: boolean;
};

const UserLogsTable: React.FC<UserLogsProps> = ({ events, isLoading, isError }) => {
    const { t } = useTranslation();

    const actionCellRenderer = ({ cellData }: { cellData: string }) => {
        const translationKey =
            UserEventLogCodeTranslations[cellData as keyof typeof UserEventLogCodeTranslations] || cellData;
        const translatedAction = t(translationKey);
        return <div>{translatedAction}</div>;
    };

    if (isLoading) {
        return <Skeleton count={5} />;
    }

    if (isError) {
        return <p>{t("user_admin_view.error_events")}</p>;
    }

    if (!events || events.data.length === 0) {
        return <p>{t("user_admin_view.no_logs")}</p>;
    }

    return (
        <VirtualizedAutosizer>
            {({ height, width }: { height: number; width: number }) => (
                <VirtualizedTable
                    width={width > 500 ? width : 500}
                    height={height > 300 ? height : 300}
                    headerHeight={20}
                    rowHeight={40}
                    rowCount={events.data.length}
                    rowGetter={({ index }: { index: number }) => events.data[index]}
                    rowClassName={({ index }: { index: number }) => (index < 0 ? "headerRow" : "tableRow")}
                >
                    <VirtualizedColumn
                        label={t("user_admin_view.action")}
                        dataKey="action"
                        width={300}
                        className="actionColumn"
                        cellRenderer={actionCellRenderer}
                    />
                    <VirtualizedColumn
                        label={t("user_admin_view.date")}
                        dataKey="created_at"
                        width={150}
                        minWidth={150}
                        flexGrow={1}
                        cellDataGetter={({ rowData }: { rowData: EventLog }) => {
                            const date = new Date(rowData.created_at);
                            return t("dates.simple", { date: date });
                        }}
                    />
                </VirtualizedTable>
            )}
        </VirtualizedAutosizer>
    );
};

export default UserLogsTable;
