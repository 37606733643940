import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import * as Sentry from "@sentry/browser";
import { CsrvDirResponse, CsrvFileResponse } from "csrvfs";
import CsrvWebFTP from "csrvfs/dist/CsrvRemoteFS";
import { fileManagerBlacklist } from "../../../../constants";
import { HostUrl } from "../../../../rootTypes";
import { getHiddenDirectoriesState } from "../../hooks/useHiddenDirectoriesState";

const WEBFTP_NOT_INITIALIZED_ERROR = { error: { status: 500, message: "webFTP is not initialized", data: null } };

export const api = createApi({
    reducerPath: "files",
    baseQuery: fetchBaseQuery({
        baseUrl: `${HostUrl}/api/v1/packages`,
        prepareHeaders: (headers) => {
            const transactionId = Math.random().toString(36).substr(2, 9);
            Sentry.configureScope((scope) => {
                scope.setTag("transaction_id", transactionId);
            });
            headers.set("X-transation-ID", transactionId);

            return headers;
        },
    }),
    endpoints: (builder) => ({
        ls: builder.query<CsrvDirResponse, { webFTP: CsrvWebFTP | null; path: string; depth?: number }>({
            queryFn: async ({ webFTP, path, depth }) => {
                if (!webFTP) {
                    return WEBFTP_NOT_INITIALIZED_ERROR;
                }
                const showHiddenDirectories = getHiddenDirectoriesState();
                const response = await webFTP.ls(path, {
                    depth: depth ? depth.toString() : "1",
                });
                return {
                    data: response
                        .filter((file) => !fileManagerBlacklist.includes(file.name) || showHiddenDirectories)
                        .map((file) => ({
                            ...file,
                            fullPath: `${path}/${file.name}`,
                        })),
                };
            },
        }),
        read: builder.query<CsrvFileResponse, { webFTP: CsrvWebFTP | null; path: string }>({
            queryFn: async ({ webFTP, path }) => {
                if (!webFTP) {
                    return WEBFTP_NOT_INITIALIZED_ERROR;
                }
                const response = await webFTP.read(path);
                return { data: response };
            },
        }),
        mkdir: builder.mutation<unknown, { webFTP: CsrvWebFTP | null; path: string }>({
            queryFn: async ({ webFTP, path }) => {
                if (!webFTP) {
                    return WEBFTP_NOT_INITIALIZED_ERROR;
                }

                await webFTP.mkdir(path);
                return { data: null };
            },
        }),
        uploadFromUrl: builder.mutation<unknown, { webFTP: CsrvWebFTP | null; path: string; url: string }>({
            queryFn: async ({ webFTP, path, url }) => {
                if (!webFTP) {
                    return WEBFTP_NOT_INITIALIZED_ERROR;
                }

                const response = await webFTP.uploadFromUrl(path, url);
                return { data: response };
            },
        }),
        // modify: builder.mutation<
        //     { error: Error | null },
        //     { csrvfs: CsrvFS; path: string; callback: (oldData: Blob) => Blob }
        // >({
        //     queryFn: async ({ csrvfs, path, callback }) => {
        //         const error = await csrvfs.modify(path, callback);
        //         return { error };
        //     },
        // }),
        // modifyAsync: builder.mutation<
        //     { error: Error | null },
        //     { csrvfs: CsrvFS; path: string; callback: (oldData: Blob) => Promise<Blob> }
        // >({
        //     queryFn: async ({ csrvfs, path, callback }) => {
        //         const error = await csrvfs.modifyAsync(path, callback);
        //         return { error };
        //     },
        // }),
        // delete: builder.mutation<unknown, { csrvfs: CsrvFS; path: string }>({
        //     queryFn: async ({ csrvfs, path }) => {
        //         const response = await csrvfs.delete(path);
        //         return { data: response };
        //     },
        // }),

        // upload: builder.mutation<
        //     unknown,
        //     { csrvfs: CsrvFS; path: string; data: File; abortController: AbortController }
        // >({
        //     queryFn: async ({ csrvfs, path, data, abortController }) => {
        //         const response = await csrvfs.upload(path, data, {
        //             abortController,
        //         });
        //         return { data: response };
        //     },
        // }),
        // uploadFolder: builder.mutation<
        //     unknown,
        //     { csrvfs: CsrvFS; path: string; data: File[]; abortController: AbortController }
        // >({
        //     queryFn: async ({ csrvfs, path, data, abortController }) => {
        //         const response = await csrvfs.uploadFolder(path, data, {
        //             abortController,
        //         });
        //         return { data: response };
        //     },
        // }),
        // moveFile: builder.mutation<unknown, { csrvfs: CsrvFS; path: string; newPath: string }>({
        //     queryFn: async ({ csrvfs, path, newPath }) => {
        //         const response = await csrvfs.move(path, newPath);
        //         return { data: response };
        //     },
        // }),
    }),
});

export const {
    useMkdirMutation,
    useUploadFromUrlMutation,
    useLsQuery,
    useLazyLsQuery,
    useReadQuery,
    useLazyReadQuery,
} = api;
