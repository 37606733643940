import React from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "../Skeleton";
import { VirtualizedAutosizer, VirtualizedColumn, VirtualizedTable } from "../virtualized";
import { UserTicketsResponse } from "../../../modules/Server/types";
import { TicketStatus, TicketStatusLabels } from "../../../constants";
import { Space } from "csrvui";

type TicketsTableProps = {
    tickets: UserTicketsResponse | undefined;
    isLoading: boolean;
    isError: boolean;
    isUserView?: boolean | null;
};

const TicketsTable: React.FC<TicketsTableProps> = ({ tickets, isLoading, isError, isUserView = null }) => {
    const { t } = useTranslation();

    const statusCellRenderer = ({ cellData }: { cellData: string }) => {
        const style = cellData === TicketStatus.PENDING ? { fontWeight: "bold" } : {};
        const statusKey = cellData as keyof typeof TicketStatusLabels;
        const translatedStatus = t(TicketStatusLabels[statusKey]);
        return <div style={style}>{translatedStatus}</div>;
    };

    if (isLoading) {
        return <Skeleton count={5} />;
    }

    if (isError) {
        return <p>{t("settings_account.error_tickets")}</p>;
    }

    if (!tickets || tickets.length === 0) {
        return <p>{t("settings_account.no_tickets")}</p>;
    }

    return (
        <Space
            $padding={0}
            style={{
                overflow: "auto",
                maxWidth: "100%",
                width: "100%",
                height: "100%",
                position: "relative",
            }}
        >
            <Space $padding={0} style={{ width: "100%", height: "100%", position: "absolute", minWidth: "500px" }}>
                <VirtualizedAutosizer>
                    {({ height, width }: { height: number; width: number }) => (
                        <VirtualizedTable
                            width={width}
                            height={height}
                            headerHeight={20}
                            rowHeight={40}
                            rowCount={tickets.length}
                            rowGetter={({ index }: { index: number }) => tickets[index]}
                            rowClassName={({ index }: { index: number }) =>
                                index < 0 ? "headerRow" : "tableRow clickableTable"
                            }
                            onRowClick={({ rowData }: { rowData: UserTicketsResponse[number] }) =>
                                window.open(rowData.url, "_blank")
                            }
                        >
                            <VirtualizedColumn
                                label={t("settings_account.ticket_name")}
                                dataKey="subject"
                                width={300}
                                className="subjectColumn"
                                minWidth={100}
                            />
                            <VirtualizedColumn
                                label={t("settings_account.ticket_created_at")}
                                dataKey="created_at"
                                width={150}
                                minWidth={150}
                                flexGrow={1}
                                cellDataGetter={({ rowData }: { rowData: UserTicketsResponse[number] }) => {
                                    const date = new Date(rowData.created_at);
                                    return t("dates.simple", { date: date });
                                }}
                            />
                            <VirtualizedColumn
                                label={t("settings_account.ticket_updated_at")}
                                dataKey="updated_at"
                                width={150}
                                minWidth={150}
                                flexGrow={1}
                                cellDataGetter={({ rowData }: { rowData: UserTicketsResponse[number] }) => {
                                    const date = new Date(rowData.updated_at);
                                    return t("dates.simple", { date: date });
                                }}
                            />
                            {isUserView && (
                                <VirtualizedColumn
                                    label={t("settings_account.status")}
                                    dataKey="status"
                                    width={100}
                                    minWidth={100}
                                    flexGrow={1}
                                    className="statusColumn"
                                    cellRenderer={statusCellRenderer}
                                />
                            )}
                        </VirtualizedTable>
                    )}
                </VirtualizedAutosizer>
            </Space>
        </Space>
    );
};

export default TicketsTable;
