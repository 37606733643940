import { useState, useEffect } from "react";

const getPreviewEnabled = () => JSON.parse(localStorage.getItem("craftserve-preview-enabled") || "false");
const setPreviewEnabled = (enabled: boolean) =>
    localStorage.setItem("craftserve-preview-enabled", JSON.stringify(enabled));

const usePreviewState = () => {
    const [isPreviewEnabled, setIsPreviewEnabled] = useState(getPreviewEnabled());

    const togglePreview = () => {
        const newPreviewState = !isPreviewEnabled;
        setPreviewEnabled(newPreviewState);
        window.localStorage.setItem("craftserve-preview-enabled", JSON.stringify(newPreviewState));
        window.dispatchEvent(new Event("storage"));
        setIsPreviewEnabled(newPreviewState);
    };

    useEffect(() => {
        const handleStorageChange = () => {
            const newPreviewState = getPreviewEnabled();
            if (newPreviewState !== isPreviewEnabled) {
                setIsPreviewEnabled(newPreviewState);
            }
        };

        window.addEventListener("storage", handleStorageChange);

        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, [isPreviewEnabled]);

    return [isPreviewEnabled, togglePreview] as const;
};

export default usePreviewState;
