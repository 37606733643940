import {
    Infer,
    any,
    array,
    assign,
    boolean,
    date,
    enums,
    nullable,
    number,
    object,
    optional,
    record,
    string,
} from "superstruct";
import { Rating } from "../../constants";
import { ProjectReleaseType } from "../../shared/types";

export interface AuthorInfo {
    author_name: string | null;
    website_url?: string | null;
}

export interface Tab {
    label: string;
    id: string;
}

export interface MarketplaceCardOptions {
    forceShowThumbUrl?: string;
}

export interface InstallReleaseOptions {
    force: boolean;
    installDeps: boolean;
}

export interface InstallProjectPayload {
    force: boolean;
    installDeps: boolean;
    backup: boolean;
    clearData: boolean;
    keepFilesPaths?: string[];
}

export interface InstallprojectModalOptions {
    hideInstallOptions: boolean;
}

export const ProjectMember = object({
    user_id: string(),
    role: string(),
    username: string(),
});
export type ProjectMember = Infer<typeof ProjectMember>;

export interface ExtendedPackage {
    name: string;
    tag: string;
    expr: string;
    provides: PackageSelector[];
    labels: Record<string, string>;
}

export type PackageSelector = ExtendedPackage | string;

export interface TransformedPackage {
    name: string;
    version: string;
    hash: string;
    provides: string[];
}

export interface NewDependencyFormValues {
    name: string;
    tag: string;
    expr: string;
    labels: {
        id: string;
        key: string;
        value: string;
    }[];
}

export interface installedPackage {
    name: string;
    version: string;
    hash: string;
    provides: string[];
}

export interface ReleaseArtifact {
    type: string;
    url: string;
    hash: string;
    details: Record<string, unknown>;
}

export interface ReviewsQueryData {
    limit?: number;
    projectId: string;
    before?: string;
}

export interface CreateReviewData {
    projectId: string;
    data: {
        comment: string;
        rate: string;
        release_id: string;
    };
}

export interface FollowMarketplaceProjectData {
    slug: string;
}

export interface ReviewsData {
    projectId?: string;
}

export const ProjectRelease = object({
    id: string(),
    project_id: string(),
    release_type: string(),
    description: string(),
    artifacts: array(
        object({
            type: string(),
            url: string(),
            hash: string(),
            details: record(string(), any()),
            // details: object({
            //     Tags: array(string()),
            //     Name: string(),
            //     Labels: record(string(), any()),
            //     SetEnv: nullable(array(record(string(), any()))),
            //     Subdir: string(),
            //     Depends: array(string()),
            //     Version: string(),
            //     Provides: array(string()),
            //     FilesHash: optional(string()),
            //     ManifestHash: string(),
            //     DownloadFiles: array(record(string(), string())),
            // }),
        })
    ),
    created_at: string(),
    published_by: string(),
    deleted_at: nullable(string()),
});

export const ProjectReleaseResponse = object({
    count: number(),
    has_more: boolean(),
    releases: array(ProjectRelease),
});

export type ProjectReleaseResponse = Infer<typeof ProjectReleaseResponse>;

export const PublicMarketProjectReview = object({
    id: string(),
    project_id: string(),
    release_id: string(),
    user_id: string(),
    username: string(),
    rate: enums(Object.values(Rating)),
    comment: string(),
    created_at: date(),
    deleted_at: nullable(date()),
});

export type PublicMarketProjectReview = Infer<typeof PublicMarketProjectReview>;

export const GetMarketProjectReviewsResponse = object({
    has_more: boolean(),
    reviews: array(PublicMarketProjectReview),
});

export type GetMarketProjectReviewsResponse = Infer<typeof GetMarketProjectReviewsResponse>;

// export interface GetMarketProjectReviewsResponse {
//     reviews: PublicMarketProjectReview[];
// }

export type ProjectRelease = Infer<typeof ProjectRelease>;

export enum ReleaseType {
    STABLE = "stable",
    LATEST = "latest",
    PRIVATE = "private",
}

const PublicMarketProjectMedia = object({
    name: string(),
    url: string(),
});

export const MarketProject = object({
    id: string(),
    slug: string(),
    name: string(),
    hash: optional(string()),
    project_type: ProjectReleaseType,
    description: string(),
    short_description: string(),
    labels: array(string()),
    author_info: object({
        author_name: nullable(string()),
        website_url: nullable(string()),
    }),
    project_members: array(ProjectMember),
    media: array(PublicMarketProjectMedia),
    visible: boolean(),
    verified_at: nullable(string()),
    positive_reviews: optional(number()),
    latest_release: optional(ProjectRelease),
    negative_reviews: optional(number()),
    created_at: string(),
    user_capabilities: record(string(), any()),
    archived_at: nullable(string()),
    version: optional(string()),
});

export type MarketProject = Infer<typeof MarketProject>;

export const MarketEventMetric = object({
    user_id: string(),
    username: string(),
    email: string(),
    project_id: string(),
    project_slug: string(),
    project_name: string(),
    points: number(),
});

export type MarketEventMetric = Infer<typeof MarketEventMetric>;

export interface MarketplaceMetricPayload {
    label: string;
    metricType: string;
}

export const GetMarketProjectResponse = assign(
    MarketProject,
    object({
        followers: number(),
        review: optional(PublicMarketProjectReview),
        positive_reviews: number(),
        negative_reviews: number(),
    })
);

export type GetMarketProjectResponse = Infer<typeof GetMarketProjectResponse>;

export const FollowedMarketplaceProjects = object({
    followed: array(MarketProject),
});

export type FollowedMarketplaceProjects = Infer<typeof FollowedMarketplaceProjects>;

export interface ReleaseArtifactFile {
    name: string;
    type: string;
    file: File;
}

export interface MarketplaceProjectWithLabels extends MarketProject {
    packageLabels: Record<string, string>;
    hash: string;
    version: string;
}

export interface ReviewFormValues {
    release_id: string;
    comment: string;
    rate: string;
}

export interface ReleaseArtifactMetadata {
    name: string;
    type: string;
}

export interface insertProjectReleasePayload {
    releaseType: ReleaseType;
    description: string;
    files: ReleaseFileData[];
    filesMetadata: ReleaseArtifactMetadata[];
}

export interface CreateProjectReleasePayload extends Omit<ProjectRelease, "id" | "createdAt" | "projectMembers"> {
    projectType: ProjectReleaseType;
}

export interface CreateMarketProjectPayload {
    name: string;
    slug: string;
    disable_prefix: boolean;
    short_description: string;
    description: string;
    labels: string[];
    project_type: ProjectReleaseType;
    author_info: AuthorInfo;

    media: File[];
}

export interface EditMarketProjectPayload {
    short_description: string;
    description: string;
    labels: string[];
    project_type: ProjectReleaseType;
    archived: boolean;

    media: File[];
}

export interface CreateProjectFromServerPayload {
    slug: string;
    version: string;
    description: string;
    file_paths: string[];
    depends_on?: Array<string | PackageSelector>;
    release_type: string;
}

export interface CreateMarketProjectFormValues {
    name: string;
    slug: string;
    short_description: string;
    description: string;
    project_type: ProjectReleaseType;
    author_name: string;
    author_website_url: string;
    gallery: File[];
    icon?: File;
    video?: File;
    thumbnail?: File;
    labels: string[];
    archived: boolean;
    disable_prefix: boolean;
}

export interface EditMarketProjectFormValues {
    name: string;
    slug: string;
    short_description: string;
    description: string;
    project_type: ProjectReleaseType;
    author_name: string;
    author_website_url: string;
    gallery: File[];
    icon?: File;
    video?: File;
    thumbnail?: File;
    labels: string[];
    archived: boolean;
    disable_prefix: boolean;
}

export enum MarketFilesName {
    ICON = "icon",
    VIDEO = "video",
    THUMBNAIL = "thumbnail",
}

export interface EditProjectReleasePayload {
    description: string;
    labels: string[];
    projectType: ProjectReleaseType;
    archived: boolean;
}

export interface ReleaseFileData {
    path: string;
    name: string;
}

export interface ProjectReleaseQuery {
    order?: string;
    limit?: number;
    after?: string;
}

export interface RedeemServerFormValues {
    email: string;
    verificationCode: string;
    agreement: boolean;
    agreement2: boolean;
    eula: boolean;
}

export interface DeleteMediaPayload {
    slug: string;
    name: string;
}
