import { Space, Title } from "csrvui";
import { useRef } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { Transition } from "react-transition-group";
import { StyledLoader, StyledLoaderContainer } from "./style";
import { Props } from "./types";

const duration = 300;

const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
};

const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
    unmounted: { opacity: 0 },
};

const GlobalTransitionLoader: React.FC<Props> = ({ text, inProp }) => {
    const { t } = useTranslation();
    const nodeRef = useRef(null);

    return createPortal(
        <Transition nodeRef={nodeRef} in={inProp} timeout={duration} unmountOnExit>
            {(state) => (
                <StyledLoaderContainer
                    style={{
                        ...defaultStyle,
                        ...transitionStyles[state],
                    }}
                >
                    <Space $padding={0} $gap={25}>
                        <StyledLoader />
                        <Title $level={2}>{text || t("loading.default")}</Title>
                    </Space>
                </StyledLoaderContainer>
            )}
        </Transition>,
        document.getElementById("loader") as HTMLElement
    );
};

export default GlobalTransitionLoader;
