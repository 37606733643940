import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../hooks";
import { useLazyServerByIdQuery } from "../../Server/api";
import { useLazyGetBackupsQuery } from "../../Server/modules/Backups/api";
import { FilesApi } from "../../Server/modules/Files";
import { ProcessState, ProcessType } from "../constants";
import { Process } from "../types";
import useUserData from "./useUserData";
import useWallets from "./useWallets";

export default function (process: Process) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [refreshBackups] = useLazyGetBackupsQuery();

    const [label, setLabel] = useState<string | undefined>();
    const [state, setState] = useState("");
    const [withProgress, setWithProgress] = useState(false);

    const [trigger] = useLazyServerByIdQuery();
    const { refreshWallets } = useWallets();
    const { updateUserData } = useUserData();

    const typeLabels = useMemo(
        () => ({
            [ProcessType.PREPARING_SERVER]: t("processes.preparing_server_label"),
            [ProcessType.UPLOADING_FILES]: t("processes.uploading_files_label"),
            [ProcessType.UPLOADING_FILES_FROM_URL]: t("processes.uploading_files_label"),
            [ProcessType.STARTING_GAME]: t("processes.starting_server_label"),
            [ProcessType.STOPPING_GAME]: t("processes.stopping_server_label"),
            [ProcessType.CREATING_SERVER_POD]: t("processes.creating_instance_server_label"),
            [ProcessType.SERVER_BACKUP]: t("processes.server_backup_label"),
            [ProcessType.SERVER_BACKUP_DELETION]: t("processes.server_backup_deletion_label"),
            [ProcessType.SERVER_RESTORE]: t("processes.server_restore_label"),
            [ProcessType.SERVER_FORMAT]: t("processes.server_format_label"),
            [ProcessType.SERVER_INSTALL_PACKAGE]: t("processes.server_install_package_label"),
            [ProcessType.SERVER_UNINSTALL_PACKAGE]: t("processes.server_uninstall_package_label"),
            [ProcessType.SERVER_MIGRATION]: t("processes.server_migration_label"),
            [ProcessType.SERVER_CLEANUP]: t("processes.server_cleanup_label"),
            [ProcessType.EXTRACTING_ARCHIVE_STATUS]: t("processes.server_extracting_archive"),

            [ProcessType.TRANSACTION_STATUS]: t("processes.transaction_status_label"),
            [ProcessType.EMAIL_CHANGE_STATUS]: t("processes.email_change_status_label"),
            [ProcessType.PASSWORD_CHANGE_STATUS]: t("processes.password_change_status_label"),
            [ProcessType.PASSWORD_RESET_STATUS]: t("processes.password_reset_status_label"),
            [ProcessType.SERVER_RENEW_STATUS]: t("processes.server_renew_status_label"),
            [ProcessType.DOMAIN_SET_STATUS]: t("processes.domain_set_status_label"),
            [ProcessType.PAY_FOR_SERVER_STATUS]: t("processes.pay_for_server_status_label"),
            [ProcessType.AUTHORIZATION_STATUS]: t("processes.authorization_status_label"),
            [ProcessType.SERVER_CREATE_STATUS]: t("processes.server_create_status_label"),
            [ProcessType.VOUCHER_ADD_STATUS]: t("processes.voucher_add_status_label"),
            [ProcessType.CHANGE_SERVER_NAME_STATUS]: t("processes.change_server_name_status_label"),
            [ProcessType.CREATING_FOLDER_STATUS]: t("processes.creating_folder_status_label"),
            [ProcessType.CREATING_FILE_STATUS]: t("processes.creating_file_status_label"),
            [ProcessType.MARKETPLACE_RELEASE_UPLOAD]: t("processes.marketplace_release_upload_label"),
            [ProcessType.SERVER_CHANGE_ENGINE]: t("processes.server_change_engine_label"),
            [ProcessType.SERVER_PACKAGE_CREATE]: t("processes.server_package_create_label"),
        }),
        [t]
    );

    const stateLabels = useMemo(
        () => ({
            [ProcessState.ABORTED]: t("processes.aborted_state"),
            [ProcessState.DONE]: t("processes.done_state"),
            [ProcessState.FAILED]: t("processes.failed_state"),
            [ProcessState.IN_PROGRESS]: t("processes.in_progress_state"),
            [ProcessState.TIMEOUT]: t("processes.timeout_state"),
        }),
        [t]
    );

    useEffect(() => {
        setState(stateLabels[process.state as ProcessState]);
        if (process.type in typeLabels) {
            setLabel(typeLabels[process.type]);
        } else {
            setLabel(process.label);
        }
        switch (process.type) {
            case ProcessType.UPLOADING_FILES:
            case ProcessType.UPLOADING_FILES_FROM_URL:
            case ProcessType.SERVER_FORMAT:
                setWithProgress(true);
                setLabel(typeLabels[process.type]);
                break;
            case ProcessType.SERVER_BACKUP:
            case ProcessType.SERVER_BACKUP_DELETION:
            case ProcessType.SERVER_RESTORE:
                setWithProgress(true);
                setLabel(typeLabels[process.type]);
                if (process.state === "DONE") {
                    dispatch(FilesApi.api.util.resetApiState());
                    refreshBackups(process.resourceId);
                }

                break;
            case ProcessType.CREATING_SERVER_POD:
            case ProcessType.PREPARING_SERVER:
            case ProcessType.STARTING_GAME:
            case ProcessType.STOPPING_GAME:
            case ProcessType.SERVER_UNINSTALL_PACKAGE:
            case ProcessType.SERVER_INSTALL_PACKAGE:
                setWithProgress(false);
                setLabel(typeLabels[process.type]);

                if (process.state === "DONE") {
                    trigger(process.resourceId);
                    refreshWallets();
                    updateUserData();
                }
                break;

            default:
                setWithProgress(false);
                setLabel(typeLabels[process.type]);
                break;
        }
    }, [dispatch, navigate, process, stateLabels, typeLabels, withProgress]);

    return { state, label, withProgress };
}
