import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import * as Sentry from "@sentry/browser";

import { array } from "superstruct";
import { HttpStatus } from "../../../../constants";
import { HostUrl } from "../../../../rootTypes";
import { transformToStruct } from "../../../../shared/helpers/customQuery";
import { transformError } from "../../../../shared/helpers/errors";
import { Transaction, WalletID, WalletLog } from "../../../../shared/types";
import { Wallet } from "../../../User/types";

export const api = createApi({
    reducerPath: "wallets",
    baseQuery: fetchBaseQuery({
        baseUrl: `${HostUrl}/api/wallets`,
        prepareHeaders: (headers) => {
            const transactionId = Math.random().toString(36).substr(2, 9);
            Sentry.configureScope((scope) => {
                scope.setTag("transaction_id", transactionId);
            });
            headers.set("X-transation-ID", transactionId);

            return headers;
        },
    }),
    endpoints: (builder) => ({
        walletById: builder.query<Wallet, string>({
            query: (walletId: WalletID) => ({
                url: `/${walletId}`,
                method: "GET",
                credentials: "include",
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformResponse(data: Wallet) {
                return transformToStruct(data, Wallet, "/wallets/:walletId");
            },
            transformErrorResponse: transformError,
        }),
        adminRechargeWallet: builder.mutation<
            void,
            { serviceId: string; cause: string; amount: { amount: number; currency: string } }
        >({
            query: ({ serviceId, cause, amount }) => ({
                url: `${serviceId}/topup`,
                method: "POST",
                credentials: "include",
                body: { cause, amount },
                validateStatus: (response) => response.status === HttpStatus.Created,
            }),
            transformErrorResponse: transformError,
        }),
        walletTransactions: builder.query<Transaction[], WalletID>({
            query: (walletId: WalletID) => ({
                url: `/${walletId}/transactions`,
                method: "GET",
                credentials: "include",
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformResponse(data: Transaction[]) {
                return transformToStruct(data, array(Transaction), "/wallets/:walletId/transactions");
            },
            transformErrorResponse: transformError,
        }),
        walletLogs: builder.query<WalletLog[], WalletID>({
            query: (walletId: WalletID) => ({
                url: `/${walletId}/logs`,
                method: "GET",
                credentials: "include",
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformResponse(data: WalletLog[]) {
                return transformToStruct(data, array(WalletLog), "/wallets/:walletId/logs");
            },
            transformErrorResponse: transformError,
        }),
    }),
});

export const { useWalletByIdQuery, useAdminRechargeWalletMutation, useWalletLogsQuery, useWalletTransactionsQuery } =
    api;
