import styled from "styled-components";

export const ModalContent = styled.div`
    width: 50vw;
    max-width: 700px;
    height: 100%;

    @media (max-width: 768px) {
        width: 100vw;
    }
`;
