import { useEffect } from "react";

interface Props {
    setInProp: React.Dispatch<React.SetStateAction<boolean>>;
}

const SuspenseLoadingSwitcher: React.FC<Props> = ({ setInProp }) => {
    useEffect(() => {
        setInProp(true);

        return () => setInProp(false);
    }, [setInProp]);

    return null;
};

export default SuspenseLoadingSwitcher;
