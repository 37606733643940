import styled from "styled-components";

export const Container = styled.div<{ mode: "show" | "hide" }>`
    position: fixed;
    left: 0;
    transition: all 0.4s;
    bottom: 0;
    height: 80px;
    width: 100%;
    z-index: 2;
    padding: 0;
    padding-left: 86px;
    background-color: ${({ theme }) => theme.colors.white};
    border-top: 1px solid ${({ theme }) => theme.colors.primary};
    z-index: 9999999;

    li {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: ${({ theme }) => theme.colors.secondaryBackground};
    }

    @media (max-width: ${({ theme }) => theme.mobileBreakpoint}) {
        position: fixed;
        padding-left: 0px;
        width: 100%;
        bottom: 0;
    }
`;

export const Scrolled = styled.div`
    overflow-x: scroll;
    overflow-y: hidden;
`;

export const List = styled.ul`
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    overflow-x: scroll;
    gap: 10px;
    width: max-content;
`;
