import { Button, Card, Image, Space, Tag, Title } from "csrvui";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import craftumIcon from "../../../assets/icons/craftum-icon.svg";
import { BillingService } from "../../../modules/Billings/types";
import { useUpdateCraftumMutation } from "../../../modules/Craftums/api";
import { Server } from "../../../modules/Server/types";
import useTheme from "../../hooks/useCustomTheme";
import ResponsiveModal from "../ResponsiveModal";
import getServerIdentificator from "../../../modules/Server/helpers/getServerIdentificator";

interface Props {
    service: BillingService;
    nested?: boolean;
    servers?: Server[];
}

const CraftumCard = styled(Card)<{ nested?: boolean }>`
    position: relative;
    width: auto;
    margin: 0;
    margin-left: ${({ nested }) => (nested ? "64px" : "0")};
    border-left: 2px solid #9281ff;
    position: relative;
    &:before {
        content: "";
        position: absolute;
        display: ${({ nested }) => (nested ? "block" : "none")};
        top: 0;
        left: -32px;
        width: 18px;
        height: 50%;
        border-left: 4px solid ${({ theme }) => theme.colors.lavender};
        border-bottom: 4px solid ${({ theme }) => theme.colors.lavender};
        border-radius: 8px;
    }
`;

const ServicesCraftumItem: React.FC<Props> = ({ service: { service_id, parameters }, nested, servers }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [updateCraftum] = useUpdateCraftumMutation();
    const [modalOpened, setModalOpened] = useState(false);

    return (
        <div>
            <Link to={`/c/${service_id}`} style={{ textDecoration: "none" }} title={service_id}>
                <CraftumCard nested={nested}>
                    <Space $direction="horizontal" $fullWidth $oppositeAlign="center" $align="space-between">
                        <Space $direction="horizontal" $oppositeAlign="center" $gap={24} $padding={0}>
                            <Image width={24} height={24} src={craftumIcon} alt="" />

                            <Title style={{ margin: 0, fontWeight: 500 }} $level={4}>
                                Craftum {service_id}
                            </Title>
                        </Space>
                        <Space
                            $direction="horizontal"
                            $padding={0}
                            $align="end"
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            {!nested && (
                                <>
                                    <Button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            setModalOpened(true);
                                        }}
                                        $sizeVariant="small"
                                    >
                                        {t("craftum.connect_to_server")}
                                    </Button>
                                    <ResponsiveModal
                                        styles={{
                                            modal: {
                                                background: theme.colors.secondaryBackground,
                                            },
                                        }}
                                        open={modalOpened}
                                        center
                                        onClose={() => setModalOpened(false)}
                                        animationDuration={0}
                                    >
                                        <Space>
                                            <Title>{t("account.select_server_to_connect_with_craftum")}</Title>
                                            {servers?.map((server) => (
                                                <Button
                                                    key={server.id}
                                                    onClick={async () => {
                                                        await updateCraftum({
                                                            id: service_id,
                                                            data: {
                                                                csrv_server_id: server.id,
                                                            },
                                                        });
                                                        setModalOpened(false);
                                                        window.location.reload();
                                                    }}
                                                    $fullWidth
                                                >
                                                    {getServerIdentificator(server)}
                                                </Button>
                                            ))}
                                        </Space>
                                    </ResponsiveModal>
                                </>
                            )}
                            <Tag $backgroundColor={theme.colors.lavender} id="expires">
                                {parameters.data.craftum_url}
                            </Tag>
                        </Space>
                    </Space>
                </CraftumCard>
            </Link>
        </div>
    );
};

export default ServicesCraftumItem;
