import { t } from "i18next";
import { TransactionAction } from "../types";

const translateTransaction = (action: string) => {
    switch (action) {
        case TransactionAction.RENEW_SERVER:
            return t("payments_screen.renew_server");
        case TransactionAction.RENEW_SERVICE:
            return t("payments_screen.renew_service");
        case TransactionAction.DEPOSIT:
            return t("payments_screen.deposit");
        case TransactionAction.VOUCHER_USE:
            return t("payments_screen.voucher_use");
        default:
            return "";
    }
};

export default translateTransaction;
