import { useCallback, useContext } from "react";
import { Currency, Money } from "ts-money";
import { UserTypes } from "..";
import { PaymentType } from "../../../constants";
import { CurrentServerContext } from "../../../shared/components/CurrentServerProvider";
import CustomError from "../../../shared/CustomError";
import { showApiError, showRequestErrorToast } from "../../../shared/helpers/showToast";
import useEventTracker from "../../../shared/hooks/useEventTracker";
import { EventAction, WalletID } from "../../../shared/types";
import { useCreateTransactionMutation } from "../../Payments/api";
import { useCreateWalletMutation, useWalletsQuery } from "../api";
import useUserData from "./useUserData";

export default function () {
    const { server } = useContext(CurrentServerContext);
    const { user } = useUserData();
    const {
        data: wallets,
        isLoading,
        refetch,
    } = useWalletsQuery(user ? user.id : "", {
        skip: !user,
    });
    const { trackEvent } = useEventTracker();
    const [createTransaction] = useCreateTransactionMutation();
    const [createWalletRequest] = useCreateWalletMutation();

    const refreshWallets = useCallback(async () => {
        try {
            if (wallets) {
                await refetch().unwrap();
            }
        } catch (err) {
            console.error(err);

            const { error, event_id } = err as CustomError;
            showRequestErrorToast(error, event_id);
        }
    }, [refetch]);

    const recharge = useCallback(
        async (id: WalletID, paymentType: PaymentType, details: Money) => {
            try {
                switch (paymentType) {
                    case PaymentType.Blik: {
                        const response = await createTransaction({
                            action: "DEPOSIT",
                            data: {},
                            payment_dto: {},
                            payment_details: details,
                            payment_type: PaymentType.Payu,
                            wallet_id: id,
                            blik: true,
                            service_id: server.id,
                        }).unwrap();

                        if (response.redirect_uri) {
                            window.location.href = response.redirect_uri;
                        }

                        break;
                    }
                    default: {
                        const response = await createTransaction({
                            action: "DEPOSIT",
                            data: {},
                            payment_dto: {},
                            payment_details: details,
                            payment_type: paymentType,
                            wallet_id: id,
                            service_id: server.id,
                        }).unwrap();

                        if (response.redirect_uri) {
                            window.location.href = response.redirect_uri;
                        }

                        break;
                    }
                }

                trackEvent(EventAction.RECHARGE_WALLET, {
                    label: paymentType,
                    status: "success",
                });
            } catch (err) {
                console.error(err);
                const { error, event_id } = err as CustomError;
                showRequestErrorToast(error, event_id);

                trackEvent(EventAction.RECHARGE_WALLET, {
                    label: paymentType,
                    status: "fail",
                });
            }
        },
        [createTransaction, server, trackEvent]
    );

    const createWallet = useCallback(
        async (currency: Currency) => {
            try {
                if (!user) {
                    throw new CustomError("User is not logged in");
                }

                await createWalletRequest({
                    userId: user.id,
                    currency: currency.code,
                }).unwrap();
            } catch (e) {
                showApiError(e);
            }
        },
        [user, createWalletRequest]
    );

    return { wallets: wallets as UserTypes.Wallet[], loading: isLoading, recharge, createWallet, refreshWallets };
}
