import { InternalLink, Space, Title } from "csrvui";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import usePermissions from "../../../../shared/hooks/usePermissions";
import { selectCurrentUser } from "../../../User/slices/authSlice";
import { MarketProject } from "../../types";
import MarketplaceListItem from "../MarketplaceListItem";
import useEventTracker from "../../../../shared/hooks/useEventTracker";
import { EventAction } from "../../../../shared/types";

interface Props {
    projects: MarketProject[];
    projectsLoading: boolean;
}

const UserProjects: React.FC<Props> = ({ projects, projectsLoading }) => {
    const { t } = useTranslation();
    const user = useSelector(selectCurrentUser);
    const { canCreateMarketplaceProject } = usePermissions();
    const { trackEvent } = useEventTracker();

    if (projectsLoading) return null; // FIXME loader

    return (
        <Space $fullWidth>
            <Space $direction="horizontal" $wrap={false} $oppositeAlign="center" $padding={0}>
                {canCreateMarketplaceProject && (
                    <InternalLink
                        $inline
                        onClick={() =>
                            trackEvent(EventAction.NAVIGATE_TO_MARKET_PROJECT_FORM, {
                                label: "",
                            })
                        }
                        $variant="primary"
                        to="/m/projects/create"
                    >
                        {t("marketplace.create_project")}
                    </InternalLink>
                )}
                {user && (
                    <InternalLink $variant="primary" to={`/m/${user.id}/projects`}>
                        {t("marketplace.your_projects")}
                    </InternalLink>
                )}
            </Space>
            <Space $direction="horizontal" $align="space-between" $oppositeAlign="center" $padding={0}>
                <Title>{t("marketplace.title")}</Title>
            </Space>

            <Space $gap={10} $padding={0} $width="auto">
                <Space $direction="horizontal" $align="start" $padding="20px 0">
                    {projects && projects.map((item, i) => <MarketplaceListItem item={item} key={i} />)}
                </Space>
            </Space>
        </Space>
    );
};

export default UserProjects;
