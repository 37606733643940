import { Card, Paragraph, Space, TextInput } from "csrvui";
import useAdminSearch from "../../hooks/useAdminSearch";
import AdminSearchResults from "../AdminSearchResults";
import { useTranslation } from "react-i18next";
import { IoIosArrowRoundForward } from "react-icons/io";
import { List, ListItem } from "../../styles";

const AdminSearch = () => {
    const { handleSearch, isLoading, results } = useAdminSearch();
    const { t } = useTranslation();
    return (
        <Card title={t("admin_screen.search_data_card_title")} $maxWidth={1400}>
            <Space $gap={0}>
                <Paragraph style={{ fontWeight: 500, fontSize: "20px" }}>{t("admin_screen.data_download")}</Paragraph>
                <List>
                    <ListItem>
                        <Paragraph style={{ width: "120px" }}>{t("admin_screen.transaction_id")}</Paragraph>{" "}
                        <IoIosArrowRoundForward /> <Paragraph>{t("admin_screen.transaction")}</Paragraph>
                    </ListItem>
                    <ListItem>
                        <Paragraph style={{ width: "120px" }}>{t("admin_screen.email_address")}</Paragraph>{" "}
                        <IoIosArrowRoundForward /> <Paragraph>{t("admin_screen.user")}</Paragraph>
                    </ListItem>
                    <ListItem>
                        <Paragraph style={{ width: "120px" }}>{t("admin_screen.server_id")}</Paragraph>{" "}
                        <IoIosArrowRoundForward /> <Paragraph>{t("admin_screen.server")}</Paragraph>
                    </ListItem>
                    <ListItem>
                        <Paragraph style={{ width: "120px" }}>{t("admin_screen.domain")}</Paragraph>{" "}
                        <IoIosArrowRoundForward /> <Paragraph>{t("admin_screen.domain_info")}</Paragraph>
                    </ListItem>
                </List>
            </Space>
            <Space style={{ padding: "0 20px 20px 20px" }}>
                <Paragraph style={{ fontWeight: 500, fontSize: "20px" }}>
                    {t("admin_screen.search_input_label")}
                </Paragraph>
                <TextInput
                    placeholder={t("common.find")}
                    onChange={(e) => handleSearch(e.target.value)}
                    $fullWidth
                    type="text"
                />

                <AdminSearchResults isLoading={isLoading} results={results} />
            </Space>
        </Card>
    );
};

export default AdminSearch;
