import "@fontsource/jetbrains-mono/400.css";
import "@fontsource/rajdhani/300.css";
import "@fontsource/rajdhani/400.css";
import "@fontsource/rajdhani/500.css";
import "@fontsource/rajdhani/600.css";
import "@fontsource/rajdhani/700.css";
import styled, { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";
import { Theme } from "../../../theme";
import { standardUITransition } from "../../styles";

export const GlobalStyles = createGlobalStyle<{ theme: Theme }>`
    ${normalize};

    @font-face {
    font-family: 'Hack';
    src: url('./') format('truetype');
    font-weight: 400; /* Regular */
    font-style: normal;
    }

    @font-face {
        font-family: 'Hack';
        src: url('/static/fonts/Hack/Hack-Italic.ttf') format('truetype');
        font-weight: 400; /* Regular */
        font-style: italic;
    }

    @font-face {
        font-family: 'Hack';
        src: url('/static/fonts/Hack/Hack-Bold.ttf') format('truetype');
        font-weight: 700; /* Bold */
        font-style: normal;
    }

    @font-face {
        font-family: 'Hack';
        src: url('/static/fonts/Hack/Hack-BoldItaloc.ttf') format('truetype');
        font-weight: 700; /* Bold */
        font-style: italic;
    }

    html {
        box-sizing: border-box;
        font-family: sans-serif;
        scroll-behavior: smooth;
    }

    svg {
        margin: 0 !important;
    }

    *,
    *::before,
    *::after {
        box-sizing: inherit;
    }

    * {
        scrollbar-color: white;
        scrollbar-width: thin;
    }

    *::-webkit-scrollbar {
        width: 1px;               
    }

    ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background:transparent;
}

::-webkit-scrollbar-track {
    border-radius: 15px;
}

::-webkit-scrollbar-thumb {
    border-radius: 15px;
    background: #d1d5d9;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
} 

#panel-wrapper {
    scrollbar-gutter: stable both-edges;
    
}
body {
    padding: 0;
    background-color: ${({ theme }) => theme.colors.primaryBackground};
    color: ${({ theme }) => theme.colors.primaryText};
    font-family: ${({ theme }) =>
        theme.fontFamilies
            .rajdhani}, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif,"Apple Color Emoji", "Segoe UI Emoji";
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    scroll-behavior: smooth;
    overscroll-behavior: none;
}

.Toastify__toast-container--bottom-center {
    transform: translate(-50%, 50%);
    bottom: 50%;
    left: 50%;
}

.markdownRenderer {
    p {
        word-wrap: anywhere;
    }

    pre {
        background: rgba(0, 0, 0, 0.05);
        padding: .5em;
    }
}


.backupDeleted {
    opacity: 0.5;        
}

.backupDeleted  .snapshotId {
        text-decoration: line-through;
    }

.ReactVirtualized__List {
    will-change: auto !important;
}

.ReactVirtualized__Table__headerRow.tableRow.clickableTable {
  &:hover {
    background-color: inherit !important;
    cursor: default !important;
  }
}

.clickableTable {
    cursor: pointer;
    :hover {
        background: rgba(156, 202, 76, 0.7)!important;
    }
}

.react-datepicker-wrapper input {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    background-color: ${({ theme }) => theme.colors.secondaryBackground};
    border: 1px solid rgb(204, 204, 204);
    border-radius: 3px;
    outline: none;
    color: ${({ theme }) => theme.colors.primaryText};

    ${standardUITransition}
}

.alice-carousel__next-btn-item, .alice-carousel__prev-btn-item  {
    font-size: 1.5em;
    font-weight: 600;
}

.react-responsive-modal-overlay, .react-responsive-modal-container, .react-responsive-modal-modal {
    animation-fill-mode: forwards !important;
}

.react-responsive-modal-modal {
    width: 90% !important;

    @media (min-width: 1000px) {
        width: fit-content !important;
        max-width: 90%;
    }

    @media (max-width: 1000px) {
        margin-top: 100px;
    }
}

.react-responsive-modal-overlay {
    opacity: 0.5 !important;
}

.react-datepicker {
    font-family: ${({ theme }) => theme.fontFamilies.rajdhani};
}

.tableRow {
    border-bottom: 1px solid rgba(0,0,0,0.1);

    &:nth-child(2n) {
        background-color: #fafafa;
    }
}

.table_reader {
    height: 90px;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;

   
    
    & .markdown-editable {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    & p {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        min-height: 39px;
    }

    & .mdxeditor-diff-source-wrapper {
        height: 100%;
    }

    & .mdxeditor-rich-text-editor {
        height: 100%;
        
    }

    & .mdxeditor-root-contenteditable {
        height: 100%;
        display: flex;
        align-items: center;

        & > div {
            height: 100%;
            
        }
    }
}

.markdown {
    height: 100%;


    & .mdxeditor-diff-source-wrapper {
        height: 100%;
    }

    & .mdxeditor-rich-text-editor {
        height: 100%;
        
    }

    & .mdxeditor-root-contenteditable {
        height: 100%;

        & > div {
            height: 100%;
            
        }
    }
}

.markdown-editable {
    height: 100%; 
}

.markdown-editable img {
    max-width: 100%;
}

.markdown-editable * {
    font-family: ${({ theme }) => theme.fontFamilies.rajdhani};
    color: ${({ theme }) => theme.colors.primaryText};
};

.markdown-editable a, .markdown-editable a > * {
    color: ${({ theme }) => theme.colors.info} !important;
};

.markdown-toolbar {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 2px solid ${({ theme }) => theme.colors.border};
}

.tableEngineRow {
    background: transparent;
    cursor: pointer;


    &:hover {
       background: rgba(156, 202, 76, 0.7)!important;
    }
}

.react-contextmenu {
background-color: #fff;
background-clip: padding-box;
border: 1px solid rgba(0,0,0,.15);
border-radius: .25rem;
color: #373a3c;
font-size: 16px;
margin: 2px 0 0;
min-width: 160px;
outline: none;
opacity: 0;
padding: 5px 0;
pointer-events: none;
text-align: left;
transition: opacity 250ms ease !important;
}

.react-contextmenu.react-contextmenu--visible {
    opacity: 1;
    pointer-events: auto;
    z-index: 9999;
}


.react-responsive-modal-root {
    z-index: 1003;
}

.react-contextmenu-item {
    background: 0 0;
    border: 0;
    color: #373a3c;
	cursor: pointer;
    font-weight: 400;
    line-height: 1.5;
    padding: 3px 20px;
    text-align: inherit;
    white-space: nowrap;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
    color: #fff;
    background-color: #20a0ff;
    border-color: #20a0ff;
    text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
    background-color: transparent;
    border-color: rgba(0,0,0,.15);
    color: #878a8c;
}

.react-contextmenu-item--divider {
    border-bottom: 1px solid rgba(0,0,0,.15);
    cursor: inherit;
    margin-bottom: 3px;
    padding: 2px 0;
}
.react-contextmenu-item--divider:hover {
    background-color: transparent;
    border-color: rgba(0,0,0,.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
	padding: 0;
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item {
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item:after {
    content: "▶";
    display: inline-block;
    position: absolute;
    right: 7px;
}

.example-multiple-targets::after {
    content: attr(data-count);
    display: block;
}

.draggableFile:hover {
    div {
        background-color: #eff1f9 !important;
    }
}   

.selectedFile {
    div {
        background-color: #c7e2ff !important;
    }
}

.potentialDrop {
     .test {
        background-color: #abd3ff !important;
    }
}

.consoleFullScreen {
    height: 100%;
}
`;

export const HiddenField = styled.input`
    display: none;
`;
