import styled from "styled-components";

export const AdminWrapper = styled.div`
    position: fixed;
    bottom: 8px;
    right: 10px;
    z-index: 9999999;
    border-radius: 6px;
    display: flex;
    height: 64px;
    justify-content: center;
    align-items: center;
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    background-color: ${({ theme }) => theme.colors.admin};
    width: 98px;
    cursor: pointer;
    &:hover {
        background-color: ${({ theme }) => theme.colors.adminHover};
    }
`;

export const MenuContainer = styled.div`
    position: absolute;
    bottom: 60px;
    right: 0;
    background-color: #f9f9f9;
    padding: 15px;
    border: 1px solid #ccc;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
    z-index: 1;
    min-width: 220px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;

    label {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        cursor: pointer;
        font-weight: 500;
    }

    input {
        margin-right: 8px;
    }

    &:before {
        content: "";
        position: absolute;
        bottom: -8px;
        right: 15px;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid #f9f9f9;
    }
`;
