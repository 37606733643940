import { InternalLink } from "csrvui";
import styled from "styled-components";
import { VisualLinkMode } from "../../types";

export const StyledContainer = styled.div<{ $mode: VisualLinkMode }>`
    background-color: ${({ theme }) => theme.colors.primaryDark};
    border: 2px solid ${({ theme }) => theme.colors.primaryDark};
    height: 100vh;
    transition: all 0.2s;
    max-height: 100vh;
    overflow-y: auto;
    z-index: 1002;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    grid-column: 1 / 2;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 86px;

    @media (min-width: 1000px) {
        position: relative;
    }

    &::-webkit-scrollbar {
        width: 2px !important;
    }
`;

export const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;

// subsidebar will be positioned fixed to the Right od StyledContainer

export const StyledLogoWrapper = styled.div`
    width: 42px;
    height: 42px;
    margin: 0 auto;
    margin-bottom: 42px;
    position: relative;

    &::after {
        content: "";
        position: absolute;
        bottom: -24px;
        left: 50%;
        transform: translateX(-50%);
        width: 60px;
        height: 1px;
        background-color: ${({ theme }) => theme.colors.white};
    }
`;

export const StyledList = styled.ul`
    margin: 0;
    padding: 0;
    list-style-type: none;
    gap: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    height: 100%;
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 10px;
    height: 100%;
`;

export const MenuItem = styled.li<{ active: boolean }>`
    position: relative;

    &:hover {
        &::after {
            display: ${({ active }) => (active ? "none" : "block")};
            content: "";
            position: absolute;
            top: 50%;
            left: -32px;
            width: 10px;
            height: 50%;
            transform: translateY(-50%);
            border-radius: 50px;
            background-color: white;
            opacity: 0.8;
        }
    }

    &::before {
        display: ${({ active }) => (active ? "block" : "none")};
        content: "";
        position: absolute;
        opacity: 0.8;
        top: 0;
        left: -28px;
        width: 10px;
        height: 48px;
        border-radius: 50px;
        background-color: white;
    }
`;

export const SidebarLink = styled(InternalLink)`
    padding: 0;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.sidebarLinkBackground};
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: 42px;
    margin-bottom: 12px;

    @media (min-width: 1902px) {
        width: 42px;
        height: 42px;
    }
`;

export const StyledSpace = styled.div`
    width: 42px;
    height: 42px;
    border-radius: 8px;
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.sidebarLinkBackground};
`;
