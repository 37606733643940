import { Infer, any, array, boolean, date, instance, nullable, number, object, record, string } from "superstruct";
import { Money } from "ts-money";
import { CountryTaxRate, UserID } from "../../shared/types";
import { Coupon } from "../Server/types";

export enum BillingProductType {
    GAME_SERVER = "GAME_SERVER",
    EXTRA_STORAGE = "EXTRA_STORAGE",
    EXTRA_CPU = "EXTRA_CPU",
    EXTRA_GAME = "EXTRA_GAME",
}

export const BillingService = object({
    service_id: string(),
    created_at: date(),
    product: string(),
    type: string(),
    wallet_id: string(),
    coupons: array(Coupon),
    parameters: record(string(), any()),
    expires: nullable(date()),
    never_expiring: boolean(),
    cost: instance(Money),
    gross_cost: instance(Money),
    auto_renew: boolean(),
    parent_id: nullable(string()),
    billing_period: string(),
    owner: UserID,
    country_tax_rate: CountryTaxRate,
});

export type BillingService = Infer<typeof BillingService>;

export interface StorageProductParameters {
    storage: number;
}

export const GameServerProductParameters = object({
    allowed_packages: array(string()),
    cpu_limit: number(),
    game: object({
        allowed_engines: array(string()),
        blacklisted_packages: array(string()),
        engine: string(),
        max_players: number(),
        sleep: boolean(),
    }),
    java: object({
        java: string(),
        version: string(),
        xms: number(),
        xmx: number(),
    }),
    memory: object({
        daemon: number(),
        pod: number(),
    }),
    secondary_domains: number(),
    storage: number(),
});

export type GameServerProductParameters = Infer<typeof GameServerProductParameters>;

export const BillingProductPrice = object({
    cluster_id: string(),
    price: instance(Money),
    period: string(),
});

export type BillingProductPrice = Infer<typeof BillingProductPrice>;

export const BillingProduct = object({
    id: string(),
    type: string(),
    parameters: record(string(), any()),
    depends_on_products: array(string()),
    available: boolean(),
    visible: boolean(),
    availability: record(string(), boolean()),
    prices: nullable(
        array(
            object({
                cluster_id: string(),
                price: instance(Money),
                gross_price: instance(Money),
                period: string(),
                country_tax_rate: CountryTaxRate,
            })
        )
    ),
});

export type BillingProduct = Infer<typeof BillingProduct>;

export interface BillingProductsRequest {
    name: string;
    user_parameters: {
        units: number;
    };
}

export const BillingServiceCostResponse = object({
    services: array(BillingService),
    cost: instance(Money),
});
export type BillingServiceCostResponse = Infer<typeof BillingServiceCostResponse>;
