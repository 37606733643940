import { createContext, useContext } from "react";
import { VisualLinkMode } from "../types";

type SidebarContent = {
    sidebarMode: VisualLinkMode;
    toggleSidebar: () => void;
    hideSidebar: () => void;
    setSubsidebar: (subsidebar: string | null) => void;
    subsidebar: string | null;
};

export const SidebarContext = createContext<SidebarContent>({
    sidebarMode: VisualLinkMode.HIDE,
    toggleSidebar: () => {
        return;
    },
    hideSidebar: () => {
        return;
    },
    subsidebar: null,
    setSubsidebar: () => {
        return;
    },
});

export default () => useContext(SidebarContext);
