import i18next, { t } from "i18next";
import { ErrorCode, InstanceStatus, ScreenTitle } from "./rootTypes";
import { TransactionAction, TransactionStatus } from "./shared/types";

export enum HttpStatus {
    OK = 200,
    Created = 201,
    Accepted = 202,
    NoContent = 204,
    MovedPermanently = 301,
    BadRequest = 400,
    Unauthorized = 401,
    Forbidden = 403,
    NotFound = 404,
    InternalServerError = 500,
    BadGateway = 502,
    PartialContent = 206,
}

export enum PaymentType {
    PayByWallet = "pay_by_wallet",
    Payu = "payu",
    Paypal = "paypal",
    PayLater = "pay_later",
    Blik = "blik",
}

export enum FeatureFlags {
    CSRV_EVENT = "csrv_konkurs_11_2024",
}

export enum Rating {
    NOT_RECOMMEND = "not_recommend",
    RECOMMEND = "recommend",
}

export enum Category {
    PLUGIN = "category:plugin",
    APP = "category:app",
    ENGINE = "category:engine",
    MOD = "category:mod",
    MAP = "category:map",
    CONFIG = "category:config",
    RESOURCEPACK = "category:resourcepack",
    SERVERPACK = "category:serverpack",
    CSRV_KONKURS = "csrv_konkurs_11_2024",
}

enum MarketplaceGame {
    MINECRAFT = "game:minecraft",
}

export const modloaderFabricApiTypes = ["fabric-api"];
export const modloaderForgeApiTypes = ["forge-api"];
export const pluginloaderApiTypes = ["spigot-api", "bukkit-api", "paper-api"];
export const modloadersApiTypes = [...modloaderFabricApiTypes, ...modloaderForgeApiTypes];

export const ScreensTitles: { [key in ScreenTitle]: string } = {
    [ScreenTitle.CREATE_OFFER]: t("routes.create_offer"),
    [ScreenTitle.CREATE_SERVER]: t("routes.create_server"),
    [ScreenTitle.DOMAIN_SETTINGS]: t("routes.domain_settings"),
    [ScreenTitle.FILES]: t("routes.files"),
    [ScreenTitle.FORGOT_PASSWORD]: t("routes.forgot_password"),
    [ScreenTitle.EDIT_PROJECT]: t("routes.edit_project"),
    [ScreenTitle.HOME]: t("routes.home"),
    [ScreenTitle.ITEMSHOP]: t("routes.itemshop"),
    [ScreenTitle.LOGIN]: t("routes.login"),
    [ScreenTitle.LOGS]: t("routes.logs"),
    [ScreenTitle.LOGS_DETAILS]: t("routes.logs_details"),
    [ScreenTitle.MAP_PREVIEW]: t("routes.map_preview"),
    [ScreenTitle.MESSAGES]: t("routes.messages"),
    [ScreenTitle.MODULE_SETTINGS]: t("routes.module_settings"),
    [ScreenTitle.NOTIFICATIONS]: t("routes.notifications"),
    [ScreenTitle.NOTIFICATION_DETAILS]: t("routes.notification_details"),
    [ScreenTitle.NOT_FOUND]: t("routes.not_found"),
    [ScreenTitle.OFFERS]: t("routes.offers"),
    [ScreenTitle.PANEL]: t("routes.panel"),
    [ScreenTitle.CRAFTUM]: t("routes.craftum"),
    [ScreenTitle.PAYMENTS_SUCCESS]: t("routes.payments_success"),
    [ScreenTitle.PLUGINS]: t("routes.plugins"),
    [ScreenTitle.PROFILE]: t("routes.profile"),
    [ScreenTitle.RECHARGE_WALLET]: t("routes.recharge_wallet"),
    [ScreenTitle.REGISTER]: t("routes.register"),
    [ScreenTitle.RENEW_SERVER]: t("routes.renew_server"),
    [ScreenTitle.SERVERS_LIST]: t("routes.servers_list"),
    [ScreenTitle.SETTINGS]: t("routes.settings"),
    [ScreenTitle.UNAUTHORIZED]: t("routes.unauthorized"),
    [ScreenTitle.VOUCHERS]: t("routes.vouchers"),
    [ScreenTitle.ACCOUNT]: t("routes.account"),
    [ScreenTitle.ACCOUNT_EVENTS]: t("routes.account_events"),
    [ScreenTitle.CHANGE_EMAIL]: t("routes.change_email"),
    [ScreenTitle.PAYMENTS]: t("routes.payments"),
    [ScreenTitle.PARENTS]: t("routes.parents"),
    [ScreenTitle.CONTACT]: t("routes.contact"),
    [ScreenTitle.PLAYERS_MANAGEMENT]: t("routes.players_management"),
    [ScreenTitle.CONFIRM_CHANGE_PASSWORD]: t("routes.confirm_change_password"),
    [ScreenTitle.CONFIRM_EMAIL]: t("routes.confirm_email"),
    [ScreenTitle.SERVER_EVENTS]: t("routes.server_events"),
    [ScreenTitle.EVENT_DETAILS]: t("routes.event_details"),
    [ScreenTitle.BACKUPS]: t("routes.backups"),
    [ScreenTitle.PAYU_SUCCESS]: t("routes.payu_success"),
    [ScreenTitle.PAYPAL_SUCCESS]: t("routes.paypal_success"),
    [ScreenTitle.PAYPAL_CANCEL]: t("routes.paypal_cancel"),
    [ScreenTitle.BASIC_SETTINGS]: t("routes.basic_settings"),
    [ScreenTitle.MC_WORLD_MANAGER]: t("routes.mc_world_manager"),
    [ScreenTitle.MARKETPLACE]: t("routes.marketplace"),
    [ScreenTitle.CREATE_PROJECT]: t("routes.create_project"),
    [ScreenTitle.USER_PROJECTS]: t("routes.user_projects"),
    [ScreenTitle.PROEJCT_RELEASE_UPLOADER]: t("routes.project_release_uploader"),
    [ScreenTitle.PROJECT_DETAILS]: t("routes.project_details"),
    [ScreenTitle.COUPON_USE]: t("routes.coupon_use"),
    [ScreenTitle.UNAUTHORIZED_SERVER]: t("routes.unauthorized_server"),
    [ScreenTitle.PRIVACY_POLICY]: t("routes.privacy_policy"),
    [ScreenTitle.ACCOUNT_SETTINGS]: t("routes.account_settings"),
    [ScreenTitle.USER_ADMIN_VIEW]: t("routes.user_admin_view"),
    [ScreenTitle.CREATE_CRAFTUM]: t("routes.create_craftum"),
    [ScreenTitle.CRAFTUM_PAYMENT]: t("routes.craftum_payment"),
};

export const ErrorsMessages: { [key in ErrorCode]: string } = {
    [ErrorCode.USER_WITH_EMAIL_ALREADY_EXISTS]: t("errors.user_with_email_already_exists"),
    [ErrorCode.INVALID_USER_PASSWORD]: t("errors.invalid_user_password"),
    [ErrorCode.USER_NOT_FOUND]: t("errors.user_not_found"),
    [ErrorCode.NOT_RECOGNIZED]: t("errors.not_recognized"),
    [ErrorCode.WRONG_PASSWORD]: t("errors.wrong_password"),
    [ErrorCode.NOT_AUTHORIZED]: t("errors.not_authorized"),
    [ErrorCode.INVALID_VOUCHER_FOR_BILLING_PERIOD]: t("errors.invalid_voucher_for_billing_period"),
    [ErrorCode.INVALID_VOUCHER_FOR_PRODUCT]: t("errors.invalid_voucher_for_product"),
    [ErrorCode.NOTIFICATION_CODE_NOT_FOUND]: t("errors.notification_code_not_found"),
    [ErrorCode.NOT_PROJECT_MEMBER]: t("errors.not_project_member"),
    [ErrorCode.UNKNOWN_FILE_NAME]: t("errors.unknown_file_name"),
    [ErrorCode.INVALID_FILE]: t("errors.invalid_file"),
    [ErrorCode.USER_DOES_NOT_EXIST]: t("errors.user_does_not_exist"),
    [ErrorCode.WRONG_PASSWORD_OR_USERNAME]: t("errors.wrong_password_or_username"),
    [ErrorCode.PLAYER_NOT_FOUND]: t("errors.player_not_found"),
    [ErrorCode.BACKUP_DELETED]: t("errors.backup_deleted"),
    [ErrorCode.DOMAIN_RESERVED]: t("errors.domain_reserved"),
    [ErrorCode.HAS_NOT_POD]: t("errors.has_not_pod"),
    [ErrorCode.PROJECT_ALREADY_EXISTS]: t("errors.project_already_exists"),
    [ErrorCode.MARKET_PROJECT_BAD_SLUG]: t("errors.market_project_bad_slug"),
    [ErrorCode.TOO_MANY_MANUAL_BACKUPS]: t("errors.too_many_manual_backups"),
    [ErrorCode.SERVER_HAS_RUNNING_BACKUP]: t("errors.server_has_running_backup"),
    [ErrorCode.TRANSACTION_IS_NOT_NEW]: t("errors.transaction_is_not_new"),
    [ErrorCode.UNAUTHORIZED]: t("errors.unauthorized"),
    [ErrorCode.USER_CODE_EXPIRED]: t("errors.user_code_expired"),
    [ErrorCode.SERVER_IS_NOT_RUNNING]: t("errors.server_is_not_running"),
    [ErrorCode.TEMPLATE_NOT_FOUND]: t("errors.template_not_found"),
    [ErrorCode.SERVER_IS_EXPIRED]: t("errors.server_is_expired"),
    [ErrorCode.SERVER_IS_NOT_STOPPED]: t("errors.server_is_not_stopped"),
    [ErrorCode.SERVER_HAS_NOT_GAME_PROCESS]: t("errors.server_has_not_game_process"),
    [ErrorCode.SERVER_IS_RUNNING]: t("errors.server_is_running"),
    [ErrorCode.NO_BACKUP_REPOSITORY_BACKUP]: t("errors.no_backup_repository_backup"),
    [ErrorCode.NO_SERVER_BACKUP_REPO_FOUND]: t("errors.no_server_backup_repo_found"),
    [ErrorCode.NO_SERVER_BACKUP_FOUND]: t("errors.no_server_backup_found"),
    [ErrorCode.ONLY_MANUAL_BACKUPS_CAN_BE_DELETED]: t("errors.only_manual_backups_can_be_deleted"),
    [ErrorCode.NO_BACKUP_REPOSITORY_UPDATED]: t("errors.no_backup_repository_updated"),
    [ErrorCode.NO_SERVER_REPO_FOUND]: t("errors.no_server_repo_found"),
    [ErrorCode.CLUSTER_IS_DISABLED]: t("errors.cluster_is_disabled"),
    [ErrorCode.CLUSTER_DOES_NOT_SUPPORT_MODULE]: t("errors.cluster_does_not_support_module"),
    [ErrorCode.CLUSTER_NOT_FOUND]: t("errors.cluster_not_found"),
    [ErrorCode.DOMAIN_INVALID_SUBDOMAIN]: t("errors.domain_invalid_subdomain"),
    [ErrorCode.TOO_MANY_FREE_DOMAINS]: t("errors.too_many_free_domains"),
    [ErrorCode.DOMAIN_NOT_FOUND]: t("errors.domain_not_found"),
    [ErrorCode.PDNS_RECORD_NOT_FOUND]: t("errors.pdns_record_not_found"),
    [ErrorCode.ZONE_ID_NOT_FOUND]: t("errors.zone_id_not_found"),
    [ErrorCode.PROCESS_CANT_BE_CANCELLED]: t("errors.process_cant_be_cancelled"),
    [ErrorCode.PROCESS_NOT_FOUND]: t("errors.process_not_found"),
    [ErrorCode.PRODUCT_HAS_NO_PRICE_FOR_SELECTED_CURRENCY]: t("errors.product_has_no_price_for_selected_currency"),
    [ErrorCode.MODULE_HAS_NO_PRICE]: t("errors.module_has_no_price"),
    [ErrorCode.SERVER_IS_DISABLED]: t("errors.server_is_disabled"),
    [ErrorCode.BILLING_SERVICE_ALREADY_HAS_COUPON]: t("errors.server_has_already_this_coupon"),
    [ErrorCode.VOUCHER_NOT_FOR_SERVICE]: t("errors.voucher_not_for_service"),
    [ErrorCode.MODULE_IS_NOT_SUPPORTED]: t("errors.module_is_not_supported"),
    [ErrorCode.MODULES_NOT_IMPLEMENTING_GIVEN_INTERFACE]: t("errors.modules_not_implementing_given_interface"),
    [ErrorCode.GAME_PROCESS_ALREADY_STARTED]: t("errors.game_process_already_started"),
    [ErrorCode.GAME_SETUP_IS_INVALID]: t("errors.game_setup_is_invalid"),
    [ErrorCode.SERVER_NOT_FOUND]: t("errors.server_not_found"),
    [ErrorCode.FILE_HAS_BEEN_MODIFIED_SINCE]: t("errors.file_has_been_modified_since"),
    [ErrorCode.UNSUPPORTED_PAYMENT_METHOD]: t("errors.unsupported_payment_method"),
    [ErrorCode.TRANSACTION_NOT_FOUND]: t("errors.transaction_not_found"),
    [ErrorCode.VOUCHER_ALREADY_USED]: t("errors.voucher_already_used"),
    [ErrorCode.VOUCHER_EXPIRED]: t("errors.voucher_expired"),
    [ErrorCode.VOUCHER_NOT_FOUND]: t("errors.voucher_not_found"),
    [ErrorCode.VOUCHER_HAS_NOT_SUPPORTING_CURRENCY]: t("errors.voucher_has_not_supporting_currency"),
    [ErrorCode.WALLET_HAS_OTHER_CURRENCY]: t("errors.wallet_has_other_currency"),
    [ErrorCode.USER_HAS_TOO_MANY_WALLETS]: t("errors.user_has_too_many_wallets"),
    [ErrorCode.WALLET_HAS_NOT_FUNDS]: t("errors.wallet_has_not_funds"),
    [ErrorCode.WALLET_NOT_FOUND]: t("errors.wallet_not_found"),
    [ErrorCode.PRODUCT_NOT_AVAILABLE]: t("errors.product_not_available"),
    [ErrorCode.MODULE_HAS_DEPENDENCIES]: t("errors.module_has_dependencies"),
    [ErrorCode.PRODUCT_NOT_FOUND]: t("errors.product_not_found"),
    [ErrorCode.MODULE_PARAMETER_NOT_ALLOWED]: t("errors.module_parameter_not_allowed"),
    [ErrorCode.INSTANCE_NOT_FOUND]: t("errors.instance_not_found"),
    [ErrorCode.FILE_DOES_NOT_EXISTS]: t("errors.file_does_not_exist"),
    [ErrorCode.VOUCHER_NOT_FOR_BILLING]: t("errors.voucher_not_for_billing"),
    [ErrorCode.VOUCHER_NOT_FOR_CLUSTER]: t("errors.voucher_not_for_cluster"),
    [ErrorCode.VOUCHER_NOT_FOR_PRODUCT]: t("errors.voucher_not_for_product"),
    [ErrorCode.VOUCHER_NOT_FOR_CURRENT_CONFIG]: t("errors.voucher_not_for_current_config"),
    [ErrorCode.INSUFFICIENT_PERMISSIONS]: t("errors.insufficient_permissions"),
    [ErrorCode.VAILED_TO_ACQUIRE_LOCK]: t("errors.failed_to_acquire_lock"),
    [ErrorCode.PROJECT_NOT_FOUND]: t("errors.project_not_found"),
    [ErrorCode.DISC_QUOTA_EXCEEDING]: t("errors.disc_quota_exceeding"),
    [ErrorCode.EMAIL_ALREADY_VERIFIED]: t("errors.email_already_verified"),
};

export const InstanceStatusLabels = {
    [InstanceStatus.IRL_DEEP_SLEEP]: t("instances.deep_sleep"),
    [InstanceStatus.IRL_HIBERNATED]: t("instances.hibernated"),
    [InstanceStatus.IRL_IDLE]: t("instances.idle"),
    [InstanceStatus.IRL_LIGHT_SLEEP]: t("instances.light_sleep"),
    [InstanceStatus.IRL_NEW]: t("instances.new"),
    [InstanceStatus.IRL_RUNNING]: t("instances.running"),
    [InstanceStatus.IRL_TERMINATED]: t("instances.terminated"),
};

export const ReviewRateLabels = {
    [Rating.NOT_RECOMMEND]: t("common.not_recommend"),
    [Rating.RECOMMEND]: t("common.recommend"),
};

export const MarketplaceTranslatedLabels = {
    [Category.PLUGIN]: t("marketplace.plugin"),
    [Category.APP]: t("marketplace.app"),
    [Category.ENGINE]: t("marketplace.engine"),
    [Category.MOD]: t("marketplace.mod"),
    [Category.MAP]: t("marketplace.map"),
    [Category.CONFIG]: t("marketplace.config"),
    [Category.CSRV_KONKURS]: t("marketplace.join_event_category"),
    [Category.RESOURCEPACK]: t("marketplace.resourcepack"),
    [Category.SERVERPACK]: t("marketplace.serverpack"),

    [MarketplaceGame.MINECRAFT]: t("marketplace.minecraft"),
};

export enum EventLogCodes {
    USER_LOGIN = "USER_LOGIN",
    USER_CHANGE_EMAIL = "USER_CHANGE_EMAIL",
    USER_CONFIRM_EMAIL = "USER_CONFIRM_EMAIL",
    USER_CHANGE_PASSWORD = "USER_CHANGE_PASSWORD",
    USER_SOCIAL_CONNECT = "USER_SOCIAL_CONNECT",
    USER_RATED_APP = "USER_RATED_APP",

    UPLOADED_FILES = "UPLOADED_FILES",
    UPLOADED_FILES_FROM_URL = "UPLOADED_FILES_FROM_URL",
    EXTRACTED_ARCHIVE = "EXTRACTED_ARCHIVE",

    GAME_CRASHED = "GAME_CRASHED",
    GAME_STARTED = "GAME_STARTED",
    GAME_STOPPED = "GAME_STOPPED",
    GAME_CRASHED_TOO_MANY_TIMES = "GAME_CRASHED_TOO_MANY_TIMES",

    SERVER_POD_CREATED = "SERVER_POD_CREATED",
    SERVER_PVC_CREATED = "SERVER_PVC_CREATED",
    SERVER_POD_DELETED = "SERVER_POD_DELETED",
    SERVER_POD_UNSCHEDULED = "SERVER_POD_UNSCHEDULED",

    SERVER_RENEWED = "SERVER_RENEWED",
    SERVER_NAME_CHANGED = "SERVER_NAME_CHANGED",
    SERVER_FILE_CHECKSUM_FAILED = "SERVER_FILE_CHECKSUM_FAILED",
    SERVER_OVERVIEWER_GENERATED_MAP = "SERVER_OVERVIEWER_GENERATED_MAP",
    SERVER_OVERVIEWER_FAILED_DURING_GENERATION = "SERVER_OVERVIEWER_FAILED_DURING_GENERATION",
    SERVER_EXPIRED = "SERVER_EXPIRED",

    SERVER_FORMAT = "SERVER_FORMAT",
    SERVER_BACKUP = "SERVER_BACKUP",
    SERVER_RESTORE_BACKUP = "SERVER_RESTORE_BACKUP",
    SERVER_INSTALL_PACKAGE = "SERVER_INSTALL_PACKAGE",
    SERVER_UNINSTALL_PACKAGE = "SERVER_UNINSTALL_PACKAGE",
    SERVER_BACKUP_DELETION = "SERVER_BACKUP_DELETION",
    SERVER_MIGRATION = "SERVER_MIGRATION",
    SERVER_POD_CRASHED = "SERVER_POD_CRASHED",
    SERVER_PREPARE = "SERVER_PREPARE",
    SERVER_PACKAGE_CREATE = "SERVER_PACKAGE_CREATE",
    SERVER_INVALID_GAME_CONFIGURATION = "SERVER_INVALID_GAME_CONFIGURATION",
    SERVER_CHANGE_ENGINE = "SERVER_CHANGE_ENGINE",
}

export const ServerEventLogCodeTranslations = {
    [EventLogCodes.GAME_CRASHED]: t("logs.game_crashed"),
    [EventLogCodes.GAME_STARTED]: t("logs.game_started"),
    [EventLogCodes.GAME_STOPPED]: t("logs.game_stopped"),
    [EventLogCodes.GAME_CRASHED_TOO_MANY_TIMES]: t("logs.game_crashed_too_many_times"),

    [EventLogCodes.SERVER_POD_CREATED]: t("logs.server_instance_created"),
    [EventLogCodes.SERVER_PVC_CREATED]: t("logs.server_pvc_created"),
    [EventLogCodes.SERVER_MIGRATION]: t("logs.server_migration"),
    [EventLogCodes.EXTRACTED_ARCHIVE]: t("logs.extracted_archive"),
    [EventLogCodes.SERVER_POD_DELETED]: t("logs.server_instance_deleted"),
    [EventLogCodes.SERVER_POD_UNSCHEDULED]: t("logs.server_instance_unscheduled"),
    [EventLogCodes.SERVER_EXPIRED]: t("logs.server_expired"),
    [EventLogCodes.SERVER_PREPARE]: t("logs.server_prepare"),

    [EventLogCodes.SERVER_NAME_CHANGED]: t("logs.server_name_changed"),
    [EventLogCodes.SERVER_OVERVIEWER_GENERATED_MAP]: t("logs.server_overviewer_generated_map"),
    [EventLogCodes.SERVER_OVERVIEWER_FAILED_DURING_GENERATION]: t("logs.server_overviewer_failed_during_generation"),
    [EventLogCodes.SERVER_FILE_CHECKSUM_FAILED]: t("logs.server_file_checksum_failed"),

    [EventLogCodes.SERVER_FORMAT]: t("logs.server_format"),
    [EventLogCodes.SERVER_BACKUP]: t("logs.server_backup"),
    [EventLogCodes.SERVER_RESTORE_BACKUP]: t("logs.server_restore_backup"),
    [EventLogCodes.SERVER_INSTALL_PACKAGE]: t("logs.server_install_package"),
    [EventLogCodes.SERVER_UNINSTALL_PACKAGE]: t("logs.server_uninstall_package"),
    [EventLogCodes.SERVER_BACKUP_DELETION]: t("logs.server_backup_deletion"),
    [EventLogCodes.SERVER_RENEWED]: t("logs.server_renewed"),

    [EventLogCodes.UPLOADED_FILES]: t("logs.uploaded_files"),
    [EventLogCodes.UPLOADED_FILES_FROM_URL]: t("logs.uploaded_files_from_url"),
    [EventLogCodes.SERVER_POD_CRASHED]: t("logs.server_pod_crashed"),
    [EventLogCodes.SERVER_PACKAGE_CREATE]: t("logs.server_package_create"),
    [EventLogCodes.SERVER_INVALID_GAME_CONFIGURATION]: t("logs.server_invalid_game_configuration"),
    [EventLogCodes.SERVER_CHANGE_ENGINE]: t("logs.server_change_engine"),
};

export const UserEventLogCodeTranslations = {
    [EventLogCodes.USER_LOGIN]: t("logs.user_login"),
    [EventLogCodes.USER_CHANGE_EMAIL]: t("logs.user_change_email"),
    [EventLogCodes.USER_CONFIRM_EMAIL]: t("logs.user_confirm_email"),
    [EventLogCodes.USER_CHANGE_PASSWORD]: t("logs.user_change_password"),
    [EventLogCodes.USER_SOCIAL_CONNECT]: t("logs.user_social_connect"),
    [EventLogCodes.USER_RATED_APP]: t("logs.user_rated_app"),
};

export enum TicketStatus {
    NEW = "new",
    PENDING = "pending",
    OPEN = "open",
}

export const TicketStatusLabels = {
    [TicketStatus.NEW]: t("tickets.new"),
    [TicketStatus.PENDING]: t("tickets.pending"),
    [TicketStatus.OPEN]: t("tickets.open"),
};

export const TransactionStatusLabels = {
    [TransactionStatus.CANCELLED]: t("transactions.cancelled"),
    [TransactionStatus.FAILED]: t("transactions.failed"),
    [TransactionStatus.DRAFT]: t("transactions.draft"),
    [TransactionStatus.COMPLETED]: t("transactions.completed"),
    [TransactionStatus.PROCESSING]: t("transactions.processing"),
    [TransactionStatus.READY_TO_CAPTURE]: t("transactions.ready_to_capture"),
    [TransactionStatus.NEW]: t("transactions.new"),
    [TransactionStatus.ADMIN_ACTION]: t("transactions.admin_action"),
};

export const TransactionActionLabels = {
    [TransactionAction.RENEW_SERVICE]: t("transactions.renew_service"),
    [TransactionAction.DEPOSIT]: t("transactions.deposit"),
    [TransactionAction.CRAFTUM]: t("transactions.craftum"),
};

i18next.on("languageChanged", () => {
    ErrorsMessages[ErrorCode.USER_WITH_EMAIL_ALREADY_EXISTS] = t("errors.user_with_email_already_exists");
    ErrorsMessages[ErrorCode.INVALID_USER_PASSWORD] = t("errors.invalid_user_password");
    ErrorsMessages[ErrorCode.USER_NOT_FOUND] = t("errors.user_not_found");
    ErrorsMessages[ErrorCode.NOT_RECOGNIZED] = t("errors.not_recognized");
    ErrorsMessages[ErrorCode.WRONG_PASSWORD] = t("errors.wrong_password");
    ErrorsMessages[ErrorCode.NOT_AUTHORIZED] = t("errors.not_authorized");
    ErrorsMessages[ErrorCode.UNKNOWN_FILE_NAME] = t("errors.unknown_file_name");
    ErrorsMessages[ErrorCode.INVALID_FILE] = t("errors.invalid_file");
    ErrorsMessages[ErrorCode.PLAYER_NOT_FOUND] = t("errors.player_not_found");
    ErrorsMessages[ErrorCode.FILE_DOES_NOT_EXISTS] = t("errors.file_does_not_exist");
    ErrorsMessages[ErrorCode.BACKUP_DELETED] = t("errors.backup_deleted");
    ErrorsMessages[ErrorCode.DOMAIN_RESERVED] = t("errors.domain_reserved");
    ErrorsMessages[ErrorCode.HAS_NOT_POD] = t("errors.has_not_pod");
    ErrorsMessages[ErrorCode.TOO_MANY_MANUAL_BACKUPS] = t("errors.too_many_manual_backups");
    ErrorsMessages[ErrorCode.SERVER_HAS_RUNNING_BACKUP] = t("errors.server_has_running_backup");
    ErrorsMessages[ErrorCode.TRANSACTION_IS_NOT_NEW] = t("errors.transaction_is_not_new");
    ErrorsMessages[ErrorCode.UNAUTHORIZED] = t("errors.unauthorized");
    ErrorsMessages[ErrorCode.USER_CODE_EXPIRED] = t("errors.user_code_expired");
    ErrorsMessages[ErrorCode.SERVER_IS_NOT_RUNNING] = t("errors.server_is_not_running");
    ErrorsMessages[ErrorCode.TEMPLATE_NOT_FOUND] = t("errors.template_not_found");
    ErrorsMessages[ErrorCode.SERVER_IS_EXPIRED] = t("errors.server_is_expired");
    ErrorsMessages[ErrorCode.SERVER_IS_NOT_STOPPED] = t("errors.server_is_not_stopped");
    ErrorsMessages[ErrorCode.SERVER_HAS_NOT_GAME_PROCESS] = t("errors.server_has_not_game_process");
    ErrorsMessages[ErrorCode.SERVER_IS_RUNNING] = t("errors.server_is_running");
    ErrorsMessages[ErrorCode.NO_BACKUP_REPOSITORY_BACKUP] = t("errors.no_backup_repository_backup");
    ErrorsMessages[ErrorCode.NO_SERVER_BACKUP_REPO_FOUND] = t("errors.no_server_backup_repo_found");
    ErrorsMessages[ErrorCode.NO_SERVER_BACKUP_FOUND] = t("errors.no_server_backup_found");
    ErrorsMessages[ErrorCode.VOUCHER_NOT_FOR_BILLING] = t("errors.voucher_not_for_billing");
    ErrorsMessages[ErrorCode.VOUCHER_NOT_FOR_CLUSTER] = t("errors.voucher_not_for_cluster");
    ErrorsMessages[ErrorCode.VOUCHER_NOT_FOR_PRODUCT] = t("errors.voucher_not_for_product");
    ErrorsMessages[ErrorCode.VOUCHER_NOT_FOR_CURRENT_CONFIG] = t("errors.voucher_not_for_current_config");
    ErrorsMessages[ErrorCode.VOUCHER_NOT_FOR_SERVICE] = t("errors.voucher_not_for_service");
    ErrorsMessages[ErrorCode.INSUFFICIENT_PERMISSIONS] = t("errors.insufficient_permissions");
    ErrorsMessages[ErrorCode.VAILED_TO_ACQUIRE_LOCK] = t("errors.failed_to_acquire_lock");
    ErrorsMessages[ErrorCode.PROJECT_NOT_FOUND] = t("errors.project_not_found");
    ErrorsMessages[ErrorCode.DISC_QUOTA_EXCEEDING] = t("errors.disc_quota_exceeding");
    ErrorsMessages[ErrorCode.EMAIL_ALREADY_VERIFIED] = t("errors.email_already_verified");

    ScreensTitles[ScreenTitle.CREATE_OFFER] = t("routes.create_offer");
    ScreensTitles[ScreenTitle.CRAFTUM] = t("routes.craftum");
    ScreensTitles[ScreenTitle.CREATE_SERVER] = t("routes.create_server");
    ScreensTitles[ScreenTitle.DOMAIN_SETTINGS] = t("routes.domain_settings");
    ScreensTitles[ScreenTitle.FILES] = t("routes.files");
    ScreensTitles[ScreenTitle.FORGOT_PASSWORD] = t("routes.forgot_password");
    ScreensTitles[ScreenTitle.HOME] = t("routes.home");
    ScreensTitles[ScreenTitle.ITEMSHOP] = t("routes.itemshop");
    ScreensTitles[ScreenTitle.LOGIN] = t("routes.login");
    ScreensTitles[ScreenTitle.LOGS] = t("routes.logs");
    ScreensTitles[ScreenTitle.LOGS_DETAILS] = t("routes.logs_details");
    ScreensTitles[ScreenTitle.MAP_PREVIEW] = t("routes.map_preview");
    ScreensTitles[ScreenTitle.MESSAGES] = t("routes.messages");
    ScreensTitles[ScreenTitle.MODULE_SETTINGS] = t("routes.module_settings");
    ScreensTitles[ScreenTitle.NOTIFICATIONS] = t("routes.notifications");
    ScreensTitles[ScreenTitle.NOTIFICATION_DETAILS] = t("routes.notification_details");
    ScreensTitles[ScreenTitle.NOT_FOUND] = t("routes.not_found");
    ScreensTitles[ScreenTitle.OFFERS] = t("routes.offers");
    ScreensTitles[ScreenTitle.PANEL] = t("routes.panel");
    ScreensTitles[ScreenTitle.PAYMENTS_SUCCESS] = t("routes.payments_success");
    ScreensTitles[ScreenTitle.PLUGINS] = t("routes.plugins");
    ScreensTitles[ScreenTitle.PROFILE] = t("routes.profile");
    ScreensTitles[ScreenTitle.RECHARGE_WALLET] = t("routes.recharge_wallet");
    ScreensTitles[ScreenTitle.REGISTER] = t("routes.register");
    ScreensTitles[ScreenTitle.RENEW_SERVER] = t("routes.renew_server");
    ScreensTitles[ScreenTitle.SERVERS_LIST] = t("routes.servers_list");
    ScreensTitles[ScreenTitle.SETTINGS] = t("routes.settings");
    ScreensTitles[ScreenTitle.UNAUTHORIZED] = t("routes.unauthorized");
    ScreensTitles[ScreenTitle.VOUCHERS] = t("routes.vouchers");
    ScreensTitles[ScreenTitle.CONTACT] = t("routes.contact");
    ScreensTitles[ScreenTitle.PLAYERS_MANAGEMENT] = t("routes.players_management");
    ScreensTitles[ScreenTitle.ACCOUNT_EVENTS] = t("routes.account_events");
    ScreensTitles[ScreenTitle.CONFIRM_CHANGE_PASSWORD] = t("routes.confirm_change_password");
    ScreensTitles[ScreenTitle.CONFIRM_EMAIL] = t("routes.confirm_email");
    ScreensTitles[ScreenTitle.SERVER_EVENTS] = t("routes.server_events");
    ScreensTitles[ScreenTitle.EVENT_DETAILS] = t("routes.event_details");
    ScreensTitles[ScreenTitle.BACKUPS] = t("routes.backups");
    ScreensTitles[ScreenTitle.PAYU_SUCCESS] = t("routes.payu_success");
    ScreensTitles[ScreenTitle.BASIC_SETTINGS] = t("routes.basic_settings");
    ScreensTitles[ScreenTitle.MC_WORLD_MANAGER] = t("routes.mc_world_manager");
    ScreensTitles[ScreenTitle.MARKETPLACE] = t("routes.marketplace");
    ScreensTitles[ScreenTitle.CREATE_PROJECT] = t("routes.create_project");
    ScreensTitles[ScreenTitle.USER_PROJECTS] = t("routes.user_projects");
    ScreensTitles[ScreenTitle.PROEJCT_RELEASE_UPLOADER] = t("routes.project_release_uploader");
    ScreensTitles[ScreenTitle.PROJECT_DETAILS] = t("routes.project_details");
    ScreensTitles[ScreenTitle.COUPON_USE] = t("routes.coupon_use");
    ScreensTitles[ScreenTitle.ACCOUNT_SETTINGS] = t("routes.account_settings");
    ScreensTitles[ScreenTitle.USER_ADMIN_VIEW] = t("routes.user_admin_view");
    ScreensTitles[ScreenTitle.CREATE_CRAFTUM] = t("routes.create_craftum");
    ScreensTitles[ScreenTitle.CRAFTUM_PAYMENT] = t("routes.craftum_payment");
    InstanceStatusLabels[InstanceStatus.IRL_DEEP_SLEEP] = t("instances.deep_sleep");
    InstanceStatusLabels[InstanceStatus.IRL_HIBERNATED] = t("instances.hibernated");
    InstanceStatusLabels[InstanceStatus.IRL_IDLE] = t("instances.idle");
    InstanceStatusLabels[InstanceStatus.IRL_LIGHT_SLEEP] = t("instances.light_sleep");
    InstanceStatusLabels[InstanceStatus.IRL_NEW] = t("instances.new");
    InstanceStatusLabels[InstanceStatus.IRL_RUNNING] = t("instances.running");
    InstanceStatusLabels[InstanceStatus.IRL_TERMINATED] = t("instances.terminated");

    ServerEventLogCodeTranslations[EventLogCodes.GAME_CRASHED] = t("logs.game_crashed");
    ServerEventLogCodeTranslations[EventLogCodes.GAME_STARTED] = t("logs.game_started");
    ServerEventLogCodeTranslations[EventLogCodes.GAME_STOPPED] = t("logs.game_stopped");
    ServerEventLogCodeTranslations[EventLogCodes.GAME_CRASHED_TOO_MANY_TIMES] = t("logs.game_crashed_too_many_times");
    ServerEventLogCodeTranslations[EventLogCodes.SERVER_POD_CREATED] = t("logs.server_instance_created");
    ServerEventLogCodeTranslations[EventLogCodes.SERVER_NAME_CHANGED] = t("logs.server_name_changed");
    ServerEventLogCodeTranslations[EventLogCodes.SERVER_FILE_CHECKSUM_FAILED] = t("logs.server_file_checksum_failed");
    ServerEventLogCodeTranslations[EventLogCodes.SERVER_RENEWED] = t("logs.server_renewed");
    ServerEventLogCodeTranslations[EventLogCodes.SERVER_POD_CRASHED] = t("logs.server_pod_crashed");
    ServerEventLogCodeTranslations[EventLogCodes.SERVER_PACKAGE_CREATE] = t("logs.server_package_create");

    UserEventLogCodeTranslations[EventLogCodes.USER_LOGIN] = t("logs.user_login");
    UserEventLogCodeTranslations[EventLogCodes.USER_CHANGE_EMAIL] = t("logs.user_change_email");
    UserEventLogCodeTranslations[EventLogCodes.USER_CONFIRM_EMAIL] = t("logs.user_confirm_email");
    UserEventLogCodeTranslations[EventLogCodes.USER_CHANGE_PASSWORD] = t("logs.user_change_password");
    UserEventLogCodeTranslations[EventLogCodes.USER_SOCIAL_CONNECT] = t("logs.user_social_connect");
    UserEventLogCodeTranslations[EventLogCodes.USER_RATED_APP] = t("logs.user_rated_app");

    TicketStatusLabels[TicketStatus.NEW] = t("tickets.new");
    TicketStatusLabels[TicketStatus.PENDING] = t("tickets.pending");
    TicketStatusLabels[TicketStatus.OPEN] = t("tickets.open");

    TransactionStatusLabels[TransactionStatus.CANCELLED] = t("transactions.cancelled");
    TransactionStatusLabels[TransactionStatus.FAILED] = t("transactions.failed");
    TransactionStatusLabels[TransactionStatus.DRAFT] = t("transactions.draft");
    TransactionStatusLabels[TransactionStatus.COMPLETED] = t("transactions.completed");
    TransactionStatusLabels[TransactionStatus.PROCESSING] = t("transactions.processing");
    TransactionStatusLabels[TransactionStatus.READY_TO_CAPTURE] = t("transactions.ready_to_capture");
    TransactionStatusLabels[TransactionStatus.NEW] = t("transactions.new");

    TransactionActionLabels[TransactionAction.RENEW_SERVICE] = t("transactions.renew_service");
    TransactionActionLabels[TransactionAction.DEPOSIT] = t("transactions.deposit");
    TransactionActionLabels[TransactionAction.CRAFTUM] = t("transactions.craftum");
});

export const minecraftMobs = [
    "Bat",
    "Bear",
    "Blaze",
    "Chicken",
    "Cow",
    "Creeper",
    "Enderman",
    "Endermite",
    "Evoker",
    "Ghast",
    "Guardian",
    "Horse",
    "Llama",
    "Mule",
    "Ocelot",
    "Parrot",
    "Pig",
    "Pigman",
    "Rabbit",
    "Sheep",
    "Shulker",
    "Silverfish",
    "Skeleton",
    "Slime",
    "Spider",
    "Squid",
    "Witch",
    "Wither",
    "Wolf",
    "Zombie",
];

export const childFriendlyAdjectives = [
    "happy",
    "playful",
    "creative",
    "friendly",
    "kind",
    "smart",
    "brave",
    "curious",
    "polite",
    "helpful",
    "joyful",
    "gentle",
    "cheerful",
    "loving",
    "imaginative",
    "sincere",
    "innocent",
    "patient",
    "respectful",
    "amiable",
    "optimistic",
    "honest",
    "generous",
    "cooperative",
    "warm-hearted",
    "tolerant",
    "bright",
    "thoughtful",
    "fun-loving",
    "enthusiastic",
    "compassionate",
    "courteous",
    "inspiring",
    "wholesome",
    "benevolent",
    "loyal",
    "exuberant",
    "peaceful",
    "gentle",
    "adventurous",
    "grateful",
    "caring",
    "uplifting",
    "radiant",
    "delightful",
    "playful",
    "harmonious",
    "sensitive",
    "modest",
    "gracious",
    "humorous",
    "perceptive",
    "forgiving",
    "reliable",
    "sweet",
    "nurturing",
    "appreciative",
    "spirited",
    "resourceful",
    "sympathetic",
    "thoughtful",
    "amusing",
    "easygoing",
    "innocent",
    "gentle",
    "modest",
    "considerate",
    "optimistic",
    "prudent",
    "imaginative",
    "understanding",
    "calm",
    "patient",
    "pensive",
    "tender",
    "affectionate",
    "welcoming",
    "upbeat",
    "resilient",
    "courteous",
    "graceful",
    "humble",
    "polite",
    "trustworthy",
    "sincere",
    "compassionate",
    "grateful",
    "radiant",
    "kind-hearted",
    "dependable",
    "forgiving",
    "bright",
    "lovable",
    "caring",
    "wholesome",
    "reliable",
    "thoughtful",
    "charming",
    "joyful",
    "optimistic",
    "uplifting",
    "sensitive",
    "amiable",
];

export const fileManagerBlacklist = ["?", ".kloceg", ".craftserve", ".pending"];

export const SEND_CONSOLE_COMMAND_CUSTOM_EVENT = "csrv_console_send_command";

export enum BillingPeriods {
    MONTH = "720h",
    THREE_MONTHS = "2160h",
    YEAR = "8760h",
}

export enum BillingPeriodsName {
    MONTH = "month",
    THREE_MONTHS = "3-month",
    YEAR = "year",
}

export const countries = [
    { label: "Poland", value: "PL" },
    { label: "Afghanistan", value: "AF" },
    { label: "Albania", value: "AL" },
    { label: "Algeria", value: "DZ" },
    { label: "American Samoa", value: "AS" },
    { label: "AndorrA", value: "AD" },
    { label: "Angola", value: "AO" },
    { label: "Anguilla", value: "AI" },
    { label: "Antarctica", value: "AQ" },
    { label: "Antigua and Barbuda", value: "AG" },
    { label: "Argentina", value: "AR" },
    { label: "Armenia", value: "AM" },
    { label: "Aruba", value: "AW" },
    { label: "Australia", value: "AU" },
    { label: "Austria", value: "AT" },
    { label: "Azerbaijan", value: "AZ" },
    { label: "Bahamas", value: "BS" },
    { label: "Bahrain", value: "BH" },
    { label: "Bangladesh", value: "BD" },
    { label: "Barbados", value: "BB" },
    { label: "Belarus", value: "BY" },
    { label: "Belgium", value: "BE" },
    { label: "Belize", value: "BZ" },
    { label: "Benin", value: "BJ" },
    { label: "Bermuda", value: "BM" },
    { label: "Bhutan", value: "BT" },
    { label: "Bolivia", value: "BO" },
    { label: "Bosnia and Herzegovina", value: "BA" },
    { label: "Botswana", value: "BW" },
    { label: "Bouvet Island", value: "BV" },
    { label: "Brazil", value: "BR" },
    { label: "British Indian Ocean Territory", value: "IO" },
    { label: "Brunei Darussalam", value: "BN" },
    { label: "Bulgaria", value: "BG" },
    { label: "Burkina Faso", value: "BF" },
    { label: "Burundi", value: "BI" },
    { label: "Cambodia", value: "KH" },
    { label: "Cameroon", value: "CM" },
    { label: "Canada", value: "CA" },
    { label: "Cape Verde", value: "CV" },
    { label: "Cayman Islands", value: "KY" },
    { label: "Central African Republic", value: "CF" },
    { label: "Chad", value: "TD" },
    { label: "Chile", value: "CL" },
    { label: "China", value: "CN" },
    { label: "Christmas Island", value: "CX" },
    { label: "Cocos (Keeling) Islands", value: "CC" },
    { label: "Colombia", value: "CO" },
    { label: "Comoros", value: "KM" },
    { label: "Congo", value: "CG" },
    { label: "Congo, The Democratic Republic of the", value: "CD" },
    { label: "Cook Islands", value: "CK" },
    { label: "Costa Rica", value: "CR" },
    { label: "Cote D'Ivoire", value: "CI" },
    { label: "Croatia", value: "HR" },
    { label: "Cuba", value: "CU" },
    { label: "Cyprus", value: "CY" },
    { label: "Czech Republic", value: "CZ" },
    { label: "Denmark", value: "DK" },
    { label: "Djibouti", value: "DJ" },
    { label: "Dominica", value: "DM" },
    { label: "Dominican Republic", value: "DO" },
    { label: "Ecuador", value: "EC" },
    { label: "Egypt", value: "EG" },
    { label: "El Salvador", value: "SV" },
    { label: "Equatorial Guinea", value: "GQ" },
    { label: "Eritrea", value: "ER" },
    { label: "Estonia", value: "EE" },
    { label: "Ethiopia", value: "ET" },
    { label: "Falkland Islands (Malvinas)", value: "FK" },
    { label: "Faroe Islands", value: "FO" },
    { label: "Fiji", value: "FJ" },
    { label: "Finland", value: "FI" },
    { label: "France", value: "FR" },
    { label: "French Guiana", value: "GF" },
    { label: "French Polynesia", value: "PF" },
    { label: "French Southern Territories", value: "TF" },
    { label: "Gabon", value: "GA" },
    { label: "Gambia", value: "GM" },
    { label: "Georgia", value: "GE" },
    { label: "Germany", value: "DE" },
    { label: "Ghana", value: "GH" },
    { label: "Gibraltar", value: "GI" },
    { label: "Greece", value: "GR" },
    { label: "Greenland", value: "GL" },
    { label: "Grenada", value: "GD" },
    { label: "Guadeloupe", value: "GP" },
    { label: "Guam", value: "GU" },
    { label: "Guatemala", value: "GT" },
    { label: "Guernsey", value: "GG" },
    { label: "Guinea", value: "GN" },
    { label: "Guinea-Bissau", value: "GW" },
    { label: "Guyana", value: "GY" },
    { label: "Haiti", value: "HT" },
    { label: "Heard Island and Mcdonald Islands", value: "HM" },
    { label: "Holy See (Vatican City State)", value: "VA" },
    { label: "Honduras", value: "HN" },
    { label: "Hong Kong", value: "HK" },
    { label: "Hungary", value: "HU" },
    { label: "Iceland", value: "IS" },
    { label: "India", value: "IN" },
    { label: "Indonesia", value: "ID" },
    { label: "Iran, Islamic Republic Of", value: "IR" },
    { label: "Iraq", value: "IQ" },
    { label: "Ireland", value: "IE" },
    { label: "Isle of Man", value: "IM" },
    { label: "Israel", value: "IL" },
    { label: "Italy", value: "IT" },
    { label: "Jamaica", value: "JM" },
    { label: "Japan", value: "JP" },
    { label: "Jersey", value: "JE" },
    { label: "Jordan", value: "JO" },
    { label: "Kazakhstan", value: "KZ" },
    { label: "Kenya", value: "KE" },
    { label: "Kiribati", value: "KI" },
    { label: "Korea, Democratic People's Republic of", value: "KP" },
    { label: "Korea, Republic of", value: "KR" },
    { label: "Kuwait", value: "KW" },
    { label: "Kyrgyzstan", value: "KG" },
    { label: "Lao People's Democratic Republic", value: "LA" },
    { label: "Latvia", value: "LV" },
    { label: "Lebanon", value: "LB" },
    { label: "Lesotho", value: "LS" },
    { label: "Liberia", value: "LR" },
    { label: "Libyan Arab Jamahiriya", value: "LY" },
    { label: "Liechtenstein", value: "LI" },
    { label: "Lithuania", value: "LT" },
    { label: "Luxembourg", value: "LU" },
    { label: "Macao", value: "MO" },
    { label: "Macedonia, The Former Yugoslav Republic of", value: "MK" },
    { label: "Madagascar", value: "MG" },
    { label: "Malawi", value: "MW" },
    { label: "Malaysia", value: "MY" },
    { label: "Maldives", value: "MV" },
    { label: "Mali", value: "ML" },
    { label: "Malta", value: "MT" },
    { label: "Marshall Islands", value: "MH" },
    { label: "Martinique", value: "MQ" },
    { label: "Mauritania", value: "MR" },
    { label: "Mauritius", value: "MU" },
    { label: "Mayotte", value: "YT" },
    { label: "Mexico", value: "MX" },
    { label: "Micronesia, Federated States of", value: "FM" },
    { label: "Moldova, Republic of", value: "MD" },
    { label: "Monaco", value: "MC" },
    { label: "Mongolia", value: "MN" },
    { label: "Montenegro", value: "ME" },
    { label: "Montserrat", value: "MS" },
    { label: "Morocco", value: "MA" },
    { label: "Mozambique", value: "MZ" },
    { label: "Myanmar", value: "MM" },
    { label: "Namibia", value: "NA" },
    { label: "Nauru", value: "NR" },
    { label: "Nepal", value: "NP" },
    { label: "Netherlands", value: "NL" },
    { label: "Netherlands Antilles", value: "AN" },
    { label: "New Caledonia", value: "NC" },
    { label: "New Zealand", value: "NZ" },
    { label: "Nicaragua", value: "NI" },
    { label: "Niger", value: "NE" },
    { label: "Nigeria", value: "NG" },
    { label: "Niue", value: "NU" },
    { label: "Norfolk Island", value: "NF" },
    { label: "Northern Mariana Islands", value: "MP" },
    { label: "Norway", value: "NO" },
    { label: "Oman", value: "OM" },
    { label: "Pakistan", value: "PK" },
    { label: "Palau", value: "PW" },
    { label: "Palestinian Territory, Occupied", value: "PS" },
    { label: "Panama", value: "PA" },
    { label: "Papua New Guinea", value: "PG" },
    { label: "Paraguay", value: "PY" },
    { label: "Peru", value: "PE" },
    { label: "Philippines", value: "PH" },
    { label: "Pitcairn", value: "PN" },
    { label: "Portugal", value: "PT" },
    { label: "Puerto Rico", value: "PR" },
    { label: "Qatar", value: "QA" },
    { label: "Reunion", value: "RE" },
    { label: "Romania", value: "RO" },
    { label: "Russian Federation", value: "RU" },
    { label: "RWANDA", value: "RW" },
    { label: "Saint Helena", value: "SH" },
    { label: "Saint Kitts and Nevis", value: "KN" },
    { label: "Saint Lucia", value: "LC" },
    { label: "Saint Pierre and Miquelon", value: "PM" },
    { label: "Saint Vincent and the Grenadines", value: "VC" },
    { label: "Samoa", value: "WS" },
    { label: "San Marino", value: "SM" },
    { label: "Sao Tome and Principe", value: "ST" },
    { label: "Saudi Arabia", value: "SA" },
    { label: "Senegal", value: "SN" },
    { label: "Serbia", value: "RS" },
    { label: "Seychelles", value: "SC" },
    { label: "Sierra Leone", value: "SL" },
    { label: "Singapore", value: "SG" },
    { label: "Slovakia", value: "SK" },
    { label: "Slovenia", value: "SI" },
    { label: "Solomon Islands", value: "SB" },
    { label: "Somalia", value: "SO" },
    { label: "South Africa", value: "ZA" },
    { label: "South Georgia and the South Sandwich Islands", value: "GS" },
    { label: "Spain", value: "ES" },
    { label: "Sri Lanka", value: "LK" },
    { label: "Sudan", value: "SD" },
    { label: "Suriname", value: "SR" },
    { label: "Svalbard and Jan Mayen", value: "SJ" },
    { label: "Swaziland", value: "SZ" },
    { label: "Sweden", value: "SE" },
    { label: "Switzerland", value: "CH" },
    { label: "Syrian Arab Republic", value: "SY" },
    { label: "Taiwan, Province of China", value: "TW" },
    { label: "Tajikistan", value: "TJ" },
    { label: "Tanzania, United Republic of", value: "TZ" },
    { label: "Thailand", value: "TH" },
    { label: "Timor-Leste", value: "TL" },
    { label: "Togo", value: "TG" },
    { label: "Tokelau", value: "TK" },
    { label: "Tonga", value: "TO" },
    { label: "Trinidad and Tobago", value: "TT" },
    { label: "Tunisia", value: "TN" },
    { label: "Turkey", value: "TR" },
    { label: "Turkmenistan", value: "TM" },
    { label: "Turks and Caicos Islands", value: "TC" },
    { label: "Tuvalu", value: "TV" },
    { label: "Uganda", value: "UG" },
    { label: "Ukraine", value: "UA" },
    { label: "United Arab Emirates", value: "AE" },
    { label: "United Kingdom", value: "GB" },
    { label: "United States", value: "US" },
    { label: "United States Minor Outlying Islands", value: "UM" },
    { label: "Uruguay", value: "UY" },
    { label: "Uzbekistan", value: "UZ" },
    { label: "Vanuatu", value: "VU" },
    { label: "Venezuela", value: "VE" },
    { label: "Viet Nam", value: "VN" },
    { label: "Virgin Islands, British", value: "VG" },
    { label: "Virgin Islands, U.S.", value: "VI" },
    { label: "Wallis and Futuna", value: "WF" },
    { label: "Western Sahara", value: "EH" },
    { label: "Yemen", value: "YE" },
    { label: "Zambia", value: "ZM" },
    { label: "Zimbabwe", value: "ZW" },
];

export let translatedKeys: Record<string, string> = {
    Backup: t("settings_account.create_ticket.topics.Backup"),
    Oferta: t("settings_account.create_ticket.topics.Oferta"),
    "Problem z serwerem": t("settings_account.create_ticket.topics.Problem_z_serwerem"),
    "Problem ze stroną": t("settings_account.create_ticket.topics.Problem_ze_stroną"),
    Płatności: t("settings_account.create_ticket.topics.Płatności"),
    Sponsoring: t("settings_account.create_ticket.topics.Sponsoring"),
    Inne: t("settings_account.create_ticket.topics.Inne"),
};

i18next.on("languageChanged", () => {
    translatedKeys = {
        Backup: t("settings_account.create_ticket.topics.Backup"),
        Oferta: t("settings_account.create_ticket.topics.Oferta"),
        "Problem z serwerem": t("settings_account.create_ticket.topics.Problem_z_serwerem"),
        "Problem ze stroną": t("settings_account.create_ticket.topics.Problem_ze_stroną"),
        Płatności: t("settings_account.create_ticket.topics.Płatności"),
        Sponsoring: t("settings_account.create_ticket.topics.Sponsoring"),
        Inne: t("settings_account.create_ticket.topics.Inne"),
    };
});
