import styled from "styled-components";

interface MaxWidthContainerProps {
    maxWidth?: string;
}

const MaxWidthContainer = styled.div<MaxWidthContainerProps>`
    max-width: ${({ maxWidth = "1920px" }) => maxWidth};
    margin: 0 auto;
    width: 100%;
    min-height: 100vh;
`;

const OutOfPanelWidthContainer = styled.div<MaxWidthContainerProps>`
    max-width: ${({ maxWidth = "1920px" }) => maxWidth};
    margin: 0 auto;
    width: 100%;
`;

export { MaxWidthContainer, OutOfPanelWidthContainer };
