import { Struct, validate } from "superstruct";
import CustomError from "../CustomError";
import transformData from "./transformData";

export async function transformToStruct<T>(data: T, structure: Struct<T>, url?: string) {
    const transformedData = transformData(data, structure);
    const [err] = validate(transformedData, structure);
    if (err) {
        console.debug({
            message: `${url} validation error`,
            structure,
            data,
            err,
        });
    }
    return transformedData;
}

export async function customQuery({
    url,
    method,
    body,
    expectedStatus = 200,
}: {
    url: string;
    method: string;
    body?: object | string | null;
    expectedStatus?: number;
}) {
    const response = await fetch(url, {
        method: method,
        credentials: "include",
        body: body ? JSON.stringify(body) : undefined,
        headers: {
            "Content-Type": "application/json",
        },
    });

    if (response.status !== expectedStatus) {
        const result = await response.json();
        return {
            error: new CustomError(result.data.message, result.data.event_id),
        };
    }

    return {
        data: void 0,
    };
}

export async function customDataQuery({
    url,
    method,
    body,
    expectedStatus = 200,
    structure,
}: {
    url: string;
    method: string;
    body?: object | string | null;
    expectedStatus?: number;
    structure: Struct<unknown>;
}) {
    try {
        const response = await fetch(url, {
            method: method,
            credentials: "include",
            body: body ? JSON.stringify(body) : undefined,
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (response.status !== expectedStatus) {
            const result = await response.json();
            return {
                error: new CustomError(result.data.message, result.data.event_id),
            };
        }

        const json = await response.json();
        const data = transformData(json, structure);

        const [err] = validate(data, structure);
        if (err) {
            console.error({
                url,
                error: err,
                data,
                structure,
                err,
            });
        }
        return {
            data,
        };
    } catch (e) {
        return {
            error: new CustomError("Something went wrong", "unknown"),
        };
    }
}

export async function customFormDataQuery({
    url,
    method,
    body,
    expectedStatus = 200,
    structure,
}: {
    url: string;
    method: string;
    body?: BodyInit | string | null;
    expectedStatus?: number;
    structure: Struct<unknown>;
}) {
    const response = await fetch(url, {
        method: method,
        credentials: "include",
        body: body || undefined,
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });

    if (response.status !== expectedStatus) {
        const result = await response.json();
        return {
            error: new CustomError(result.data.message, result.data.event_id),
        };
    }

    const json = await response.json();
    const data = transformData(json, structure);

    const [err] = validate(data, structure);
    if (err) {
        console.error({
            url,
            error: err,
            data,
            structure,
            err,
        });
    }
    return {
        data,
    };
}
