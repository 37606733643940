import { Infer, array, boolean, date, literal, nullable, number, object, optional, string, union } from "superstruct";
import CustomError from "./CustomError";

export enum VisualLinkMode {
    SHOW = "show",
    HIDE = "hide",
}

export type UserID = string;
export const UserID = string();
export type WalletID = string;
export type Email = string;
export type Language = string;
export type ServerID = string;
export const ServerID = string();
export type ProcessID = string;
export type NotificationID = string;
export type DomainID = string;
export type VoucherUseID = string;
export type EventID = string;
export type ProjectReleaseId = string;
export const ProjectReleaseType = string();
export type ProjectReleaseType = Infer<typeof ProjectReleaseType>;

export type DurationString = string;

export type CreatedAt = Date;
export type DestroyedAt = Date;
export type Newsletter = Date;
export type Expires = Date | null;

export enum StatusCodes {
    SUCCESSFUL_PAYMENT = "SUCCESSFUL_PAYMENT",
    FAILED_PAYMENT = "FAILED_PAYMENT",
}
export enum FetchingStatus {
    INIT = "init",
    LOADING = "loading",
    SUCCESSED = "succeeded",
    FAILED = "failed",
}

export enum Capability {
    CanManageCurrentServer = "server.manage",
    CanCreateMarketplaceProject = "marketplace.project-create",
    IsAdmin = "admin",
    CanManageServer = "server",
    CanManageServersAsAdmin = "admin.server",
    CanManageServerParameters = "admin.server.parameters",
    CanTopupAsAdmin = "admin.payments.topup",
    CanBrowseMarketplaceProjects = "marketplace.project-browse",
    CanEditMarketplaceProject = "marketplace.project-manage.edit",
    CanReadMarketplaceProject = "marketplace.project-manage.read",
    CanCreateProjectWithoutPrefix = "marketplace.project-create-without-prefix",
}

export interface TrackedRoute {
    route: string;
    event: EventAction;
}
interface ApiErrorData {
    error: string;
    event_id?: string;
}

export interface ApiError {
    data: ApiErrorData;
    status: number;
}

export type ApiResponse<DataType> = DataType | ApiErrorData;

export interface FetchDataStore<DataType> {
    data: DataType;
    status: FetchingStatus;
    error: string | null;
}

export interface FiltersType {
    [key: string]: {
        value: string;
        format: "string" | "date";
    };
}

export const EventLog = object({
    id: string(),
    level: number(),
    resource_id: string(),
    resource_type: string(),
    created_at: date(),
    action: string(),
    success: boolean(),
});

export type EventLog = Infer<typeof EventLog>;

export const EventsResponse = object({
    data: array(EventLog),
    count: number(),
});

export type EventsResponse = Infer<typeof EventsResponse>;

export type WalletLogId = string;

export const WalletLogAdminDetails = object({
    admin_id: string(),
    cause: string(),
    server_expires: nullable(date()),
    duration: nullable(string()),
});

export type WalletLogAdminDetails = Infer<typeof WalletLogAdminDetails>;

export const WalletLogUserDetails = object({
    transaction_id: string(),
    server_expires: optional(date()),
});

export type WalletLogUserDetails = Infer<typeof WalletLogUserDetails>;

export const WalletLogVoucherDetails = object({
    user_id: string(),
    voucher_id: string(),
});

export type WalletLogVoucherDetails = Infer<typeof WalletLogVoucherDetails>;

export const WalletLog = object({
    id: string(),
    wallet_id: string(),
    pair_id: string(),
    ts: date(),
    amount: number(),
    currency: string(),
    cause: string(),
    details: object(),
    completed: nullable(date()),
});

export type WalletLog = Infer<typeof WalletLog>;

export const PaymentHistory = object({
    wallet_log: WalletLog,
    provider: string(),
    transaction_id: nullable(string()),
    status: nullable(string()),
});

export type PaymentHistory = Infer<typeof PaymentHistory>;

export const EventDetails = object({
    id: string(),
    level: number(),
    resource_id: string(),
    resource_type: string(),
    created_at: date(),
    action: string(),
    data: union([
        object({
            last_logs: string(),
            error: optional(string()),
        }),
        object({
            file: string(),
            error: optional(string()),
        }),
        object({
            cause: string(),
            stdout: string(),
            charge: boolean(),
            duration: string(),
            is_admin: boolean(),
            user_id: string(),
            wallet_log_id: string(),
            error: optional(string()),
        }),
        object({
            crash_count: string(),
            error: optional(string()),
        }),
        object({
            transaction_id: string(),
            error: optional(string()),
        }),
        object({
            traceId: string(),
            error: optional(string()),
        }),
        object({
            package: string(),
            error: optional(string()),
        }),
    ]),
    success: boolean(),
});

export type EventDetails = Infer<typeof EventDetails>;

export enum EventAction {
    CREATE = "create",
    UPDATE = "update",
    DELETE = "delete",
    FORMAT = "format",
    LOGIN = "login",
    CREATE_MARKET_PROJECT = "create_market_project",
    LOGOUT = "logout",
    NAVIGATE_TO_MARKET_PROJECT_FORM = "navigate_to_market_project_form",
    MARKETPLACE_SEARCH = "marketplace_search",
    LIKE = "like",
    MARKETPLACE_CARD_CLICK = "marketplace_card_click",
    REGISTER = "register",
    FAST_REGISTER_BEGIN = "fast_register_begin",
    FAST_REGISTER_COMPLETE = "fast_register_complete",
    FORGOT_PASSWORD = "forgot_password",
    RESET_PASSWORD_BEGIN = "reset_password_begin",
    RESET_PASSWORD_COMPLETE = "reset_password_COMPLETE",
    CHANGE_PASSWORD = "change_password",
    UNINSTALL_PACKAGE = "uninstall_package",
    CHANGE_EMAIL = "change_email",
    CHANGE_LANGUAGE = "change_language",
    CREATE_NEW_RELEASE = "create_new_release",
    CHANGE_CURRENCY = "change_currency",
    CHANGE_THEME = "change_theme",
    CREATE_SERVER_PREVIOUS_STEP = "create_server_previous_step",
    PAYU_PAYMENT = "payu_payment",
    BLIK_PAYMENT = "blik_payment",
    SELECT_ENGINE_FROM_ENGINES_LIST = "select_engine_from_engines_list",
    PAYPAL_PAYMENT = "paypal_payment",
    INSTALL_PROJECT_INSTALLER = "install_project_installer",
    MANUAL_STOP_SERVER = "manual_stop_server",

    MANUAL_START_SERVER = "manual_start_server",
    RECHARGE_WALLET = "recharge_wallet",
    CRAFTUM_PAYMENT = "craftum_payment",
    CHANGE_DOMAIN = "change_domain",
    ADD_VOUCHER_FROM_PAY_FOR_SERVER = "add_voucher_from_pay_for_server",
    ADD_VOUCHER_FROM_PAYMENTS_SCREEN = "add_voucher_from_payments_screen",
    ADD_VOUCHER_IN_SERVER_CREATOR = "add_voucher_in_server_creator",
    BAN_PLAYER = "ban_player",
    ADD_TO_WHITELIST = "add_to_whitelist",
    ADD_TO_OP = "add_to_op",
    CHOOSE_PLAN = "choose_plan",
    REDEEM_FREE_SERVER = "redeem_free_server",
    CHANGE_BILLING_PERIOD = "change_billing_period",
    PAY_BY_WALLET_PAYMENT = "pay_by_wallet_payment",
    UPDATE_INVOICE_DATA = "update_invoice_data",
    UPDATE_TRANSACTION = "update_transaction",
    GET_TRANSACTION = "get_transaction",
    CREATE_MANUAL_BACKUP = "create_manual_backup",
    DELETE_MANUAL_BACKUP = "delete_manual_backup",
    DOWNLOAD_MANUAL_BACKUP = "download_manual_backup",
    RESTORE_MANUAL_BACKUP = "restore_manual_backup",
    SERVER_CREATION_INTERRUPTED = "server_creation_interrupted",
    CREATE_SERVER = "create_server",
    VISIT_ROUTE = "visit_route",
    INSTALL_RELEASE = "install_release",
    INSTALL_NEWEST_VERSION_CLICKED = "install_newest_version_clicked",
}

export enum UserPermissions {
    ADMIN = "admin",
    SERVER = "server",
    SERVERS_MANAGE = "server.manage",
    SERVER_READ = "server.manage.read",
    MARKETPLACE_SERVER_PACKAGES = "server.packages",
    CRAFTUM = "user.craftum",
    PAYPAL = "user.payments.paypal",
    PAYU = "user.payments.payu",
    BLIK = "user.payments.blik",
    OFFERS = "user.offers",
    USERS_READ = "users.read",
}

type ExpressionType =
    | "Compound"
    | "Identifier"
    | "MemberExpression"
    | "Literal"
    | "ThisExpression"
    | "CallExpression"
    | "UnaryExpression"
    | "BinaryExpression"
    | "LogicalExpression"
    | "ConditionalExpression"
    | "ArrayExpression";

export interface Expression {
    type: ExpressionType;
}

export interface Literal extends Expression {
    type: "Literal";
    value: boolean | number | string;
    raw: string;
}
export interface BinaryExpression extends Expression {
    type: "BinaryExpression";
    operator: string;
    left: Literal | BinaryExpression;
    right: Literal | BinaryExpression;
}

export interface BaseQueryError {
    status: number;
    data: CustomError;
}

const TargetStruct = object({
    app: string(),
    record_name: string(),
    record_type: string(),
    dns_content: string(),
    mode: union([string(), literal(null)]),
});

export const CheckAvailabilityDomainResponseStruct = object({
    id: number(),
    server_id: string(),
    user_id: string(),
    kind: string(),
    fqdn: string(),
    sub_domain: string(),
    zone_name: string(),
    external_ns: union([string(), literal(null)]),
    targets: array(TargetStruct),
    updated_at: string(),
    created_at: string(),
    expires: union([string(), literal(null)]),
});

export type CheckAvailabilityDomainResponse = Infer<typeof CheckAvailabilityDomainResponseStruct>;

export const CountryTaxRate = object({
    vat_rate: number(),
    country_code: string(),
});

export type CountryTaxRate = Infer<typeof CountryTaxRate>;

export enum TransactionStatus {
    DRAFT = "DRAFT",
    NEW = "NEW",
    PROCESSING = "PROCESSING",
    READY_TO_CAPTURE = "READY_TO_CAPTURE",
    COMPLETED = "COMPLETED",
    FAILED = "FAILED",
    CANCELLED = "CANCELLED",
    ADMIN_ACTION = "ADMIN_ACTION",
}

export const StructTransactionStatus = union([
    literal(TransactionStatus.DRAFT),
    literal(TransactionStatus.NEW),
    literal(TransactionStatus.PROCESSING),
    literal(TransactionStatus.READY_TO_CAPTURE),
    literal(TransactionStatus.COMPLETED),
    literal(TransactionStatus.FAILED),
    literal(TransactionStatus.CANCELLED),
]);

export enum TransactionAction {
    RENEW_SERVICE = "RENEW_SERVICE",
    DEPOSIT = "DEPOSIT",
    CRAFTUM = "CRAFTUM",
    RENEW_SERVER = "RENEW_SERVER",
    VOUCHER_USE = "VOUCHER_USE",
}

export const StructTransactionAction = union([
    literal(TransactionAction.RENEW_SERVICE),
    literal(TransactionAction.DEPOSIT),
    literal(TransactionAction.CRAFTUM),
]);

export const Transaction = object({
    id: string(),
    status: StructTransactionStatus,
    action: StructTransactionAction,
    invoiceid: nullable(number()),
    ts: date(),
    amount: number(),
    currency: string(),
    wallet_id: string(),
});

export type Transaction = Infer<typeof Transaction>;

export const TransactionDetails = object({
    status: StructTransactionStatus,
});

export type TransactionDetails = Infer<typeof TransactionDetails>;
