export interface Option {
    readonly value: string;
    readonly label: string;
}

export interface GroupedOption {
    readonly label: string;
    readonly options: readonly Option[];
}

export enum PackageLabels {
    TYPE = "craftserve.com/type",
    MOD_LOADER = "craftserve.com/mod_loader",
    GAME = "craftserve.com/game",
    MINECRAFT_JAVA_VERSION = "craftserve.com/minecraft-java/version",
    JAVA_VERSION = "craftserve.com/java/version",
    PACKAGE_TIME = "craftserve.com/package/time",
    RELEASE_TIME = "craftserve.com/release/time",
}
