import { useEffect, useState } from "react";

const SHOW_HIDDEN_DIRECTORIES_KEY = "show-hidden-directories";

export const getHiddenDirectoriesState = () => JSON.parse(localStorage.getItem(SHOW_HIDDEN_DIRECTORIES_KEY) || "false");
const setHiddenDirectoriesState = (show: boolean) =>
    localStorage.setItem(SHOW_HIDDEN_DIRECTORIES_KEY, JSON.stringify(show));

const useHiddenDirectoriesState = () => {
    const [showHiddenDirectories, setShowHiddenDirectories] = useState(getHiddenDirectoriesState());

    const toggleHiddenDirectories = () => {
        const newShowHiddenDirectoriesState = !showHiddenDirectories;
        setHiddenDirectoriesState(newShowHiddenDirectoriesState);
        setShowHiddenDirectories(newShowHiddenDirectoriesState);
        window.location.reload();
    };

    useEffect(() => {
        const handleStorageChange = () => {
            const newShowHiddenDirectoriesState = getHiddenDirectoriesState();
            if (newShowHiddenDirectoriesState !== showHiddenDirectories) {
                setShowHiddenDirectories(newShowHiddenDirectoriesState);
            }
        };

        window.addEventListener("storage", handleStorageChange);

        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, [showHiddenDirectories]);

    return [showHiddenDirectories, toggleHiddenDirectories] as const;
};

export default useHiddenDirectoriesState;
