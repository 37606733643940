import { Button, Image, InternalLink, Paragraph, Space, Title, Tooltip } from "csrvui";
import { FastAverageColor } from "fast-average-color";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { showMenu, hideMenu } from "react-contextmenu";
import { useTranslation } from "react-i18next";
import { FaExclamationCircle } from "react-icons/fa";
import { IoSettingsOutline } from "react-icons/io5";
import { MdVerified } from "react-icons/md";
import { CSSProperties } from "styled-components";
import Chip from "../../../../shared/components/Chip";
import ContextMenu from "../../../../shared/components/ContextMenu";
import ContextMenuTrigger from "../../../../shared/components/ContextMenuTrigger";
import useTheme from "../../../../shared/hooks/useCustomTheme";
import { MarketplaceProjectWithLabels, MarketProject } from "../../types";
import { Info, MarketplaceSearchItemWrapper } from "./style";
import { Link, useParams } from "react-router-dom";
import { CurrentServerContext } from "../../../../shared/components/CurrentServerProvider";
import { showApiError } from "../../../../shared/helpers/showToast";
import { useDeletePackagesMutation } from "../../../Server/api";
import ConfirmUninstallModal from "../ConfirmUninstallModal";
import { PackageLabels } from "../Marketplace/types";
import useProjectAssets from "../../hooks/useProjectAssets";

interface Props {
    data: MarketProject | MarketplaceProjectWithLabels;
    disabled?: boolean;
    to?: string;
    disableUninstallText?: string;
    isUserAdminView?: boolean;
    installed?: boolean;
    installedAlert?: boolean;
    tags?: string[];
    actions?: { label: string; onClick: (e: globalThis.MouseEvent) => void; value: string }[];
}

const MarketplaceSearchItem: React.FC<Props> = ({
    data,
    disabled,
    isUserAdminView = false,
    installed,
    disableUninstallText,
    installedAlert,
    tags,
    to,
    actions = [],
}) => {
    const { t } = useTranslation();
    const [bgColor, setBgColor] = useState<string>("#ffffff");
    const theme = useTheme();
    const { server, refreshInstalledPackages } = useContext(CurrentServerContext);
    const [deletePackages] = useDeletePackagesMutation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { name, project_members, short_description, slug, verified_at, version, hash } = data;
    const { icon } = useProjectAssets(data);
    const isEngine = "packageLabels" in data && data.packageLabels[PackageLabels.TYPE] === "MOD_LOADER";

    const { serverIdentity } = useParams();

    const projectOwner = useMemo(
        () => project_members && project_members.find((member) => member.role === "owner"),
        [project_members]
    );

    const onUninstall = useCallback(async (delete_data: boolean) => {
        try {
            await deletePackages({ serverId: server.id, packageName: `${slug}:${hash}`, delete_data }).unwrap();

            refreshInstalledPackages();
        } catch (e) {
            showApiError(e);
            console.error(e);
        }
    }, []);

    useEffect(() => {
        const fac = new FastAverageColor();
        fac.getColorAsync(icon || `https://placehold.co/100x100?text=${name}`)
            .then((color) => {
                const colorWithOpacity = color.hex + "80";

                setBgColor(colorWithOpacity);
            })
            .catch((e) => {
                console.error(e);
            });
    }, []);

    const itemStyle: CSSProperties = isUserAdminView
        ? {
              background: `linear-gradient(90deg, ${bgColor} 0%, #ffffff 100%)`,
              boxShadow: "0px 3px 6px #00000029",
              borderRadius: "10px",
              padding: "10px",
              margin: "10px 10px",
              textDecoration: "none",
          }
        : {
              cursor: "pointer",
              background: `linear-gradient(90deg, ${bgColor} 0%, #ffffff 100%)`,
              position: "relative",
              borderRadius: "10px",
              height: installed ? "113px" : "fit-content",
              textDecoration: "none",
          };

    return (
        <>
            <Link to={to || slug} style={itemStyle}>
                <Space $padding={16} $direction="horizontal" $align="start" $wrap={false}>
                    <MarketplaceSearchItemWrapper>
                        <Image src={icon || `https://placehold.co/100x100?text=${name}`} alt={name} />
                    </MarketplaceSearchItemWrapper>
                    <Space $padding={0} $gap={0} style={{ display: "flex", alignItems: "stretch" }}>
                        <Space $direction="horizontal" $padding={0}>
                            <Title $level={4}>{name}</Title>
                            {verified_at && (
                                <Tooltip
                                    $position="bottom"
                                    $variant="dark"
                                    style={{ maxWidth: "140px" }}
                                    text={t("marketplace.verifed")}
                                >
                                    <MdVerified style={{ color: theme.colors.success }} />
                                </Tooltip>
                            )}
                        </Space>
                        <Paragraph
                            style={{
                                width: "100%",
                                overflow: "hidden",
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: projectOwner && projectOwner.username !== "" ? 1 : 2, // This limits to 2 lines before the ellipsis appears
                                textOverflow: "ellipsis",
                                minHeight: "18px",
                                maxWidth: "230px",
                            }}
                        >
                            {short_description}
                        </Paragraph>
                        {version && (
                            <Space $direction="horizontal" $padding={0} $gap={0}>
                                <Paragraph
                                    style={{
                                        whiteSpace: "nowrap",
                                        maxWidth: "210px",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    }}
                                >
                                    {t("common.version")}:
                                </Paragraph>
                                <Paragraph
                                    style={{
                                        whiteSpace: "nowrap",
                                        maxWidth: "210px",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        fontWeight: "bold",
                                        marginLeft: "4px",
                                    }}
                                >
                                    {version}
                                </Paragraph>
                            </Space>
                        )}
                        {tags && (
                            <Space
                                style={{ marginTop: "auto" }}
                                $direction="horizontal"
                                $align="start"
                                $gap={3}
                                $padding={0}
                            >
                                {tags.map((tag) => (
                                    <Chip fontSize="14px" key={tag}>
                                        {tag}
                                    </Chip>
                                ))}
                            </Space>
                        )}
                        {projectOwner && projectOwner.username !== "" && (
                            <Paragraph
                                style={{
                                    whiteSpace: "nowrap",
                                    paddingTop: 2,
                                    margin: "auto 0 0 0 ",
                                    maxWidth: "230px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                }}
                            >
                                {t("marketplace.cootributors")}: {projectOwner.username}
                            </Paragraph>
                        )}
                    </Space>
                    {disabled && (
                        <Info>
                            <Tooltip text={t("marketplace.incompatible_with_server")}>!</Tooltip>
                        </Info>
                    )}
                    {installed && (
                        <Space style={{ position: "absolute", bottom: "-20px", right: 0 }}>
                            <ContextMenuTrigger id={name + slug}>
                                {!isEngine ? (
                                    <Button
                                        disabled={isEngine}
                                        $sizeVariant="small"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();

                                            const x = e.clientX;
                                            const y = e.clientY;

                                            hideMenu();

                                            showMenu({
                                                position: { x, y },
                                                id: name + slug,
                                            });
                                        }}
                                        $variant="dark"
                                        style={{
                                            padding: "4px 0 0 0",
                                            height: "32px",
                                            width: "32px",
                                            display: "flex",
                                            position: "relative",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            borderRadius: "50%",
                                            alignSelf: "flex-end",
                                            marginLeft: "auto",
                                        }}
                                    >
                                        {installedAlert && (
                                            <Space
                                                style={{ position: "absolute", top: "-30px", left: "0px", padding: 0 }}
                                            >
                                                <FaExclamationCircle size={20} color={theme.colors.danger} />
                                            </Space>
                                        )}
                                        <IoSettingsOutline size={22} />
                                    </Button>
                                ) : (
                                    <Tooltip text={disableUninstallText} $position="left">
                                        <InternalLink
                                            to={`/s/${serverIdentity}/settings#engines`}
                                            $sizeVariant="small"
                                            style={{
                                                padding: "4px 0 0 0",
                                                height: "32px",
                                                width: "32px",
                                                backgroundColor: theme.colors.dark,
                                                color: theme.colors.white,
                                                display: "flex",
                                                position: "relative",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                borderRadius: "50%",
                                                alignSelf: "flex-end",
                                                marginLeft: "auto",
                                            }}
                                        >
                                            {installedAlert && (
                                                <Space
                                                    style={{
                                                        position: "absolute",
                                                        top: "-30px",
                                                        left: "0px",
                                                        padding: 0,
                                                    }}
                                                >
                                                    <FaExclamationCircle size={20} color={theme.colors.danger} />
                                                </Space>
                                            )}
                                            <Space
                                                style={{
                                                    marginBottom: "5px",
                                                }}
                                                $padding={0}
                                            >
                                                <IoSettingsOutline size={22} />
                                            </Space>
                                        </InternalLink>
                                    </Tooltip>
                                )}
                            </ContextMenuTrigger>

                            <ContextMenu
                                id={name + slug}
                                actions={[
                                    ...actions,
                                    {
                                        label: t("marketplace.confirm_uninstall.uninstall"),
                                        onClick: (e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            setIsModalOpen(true);
                                        },
                                        value: "delete",
                                    },
                                ]}
                            />
                        </Space>
                    )}
                </Space>
            </Link>
            {onUninstall && installed && (
                <ConfirmUninstallModal
                    handleClose={() => setIsModalOpen(false)}
                    isOpen={isModalOpen}
                    name={slug}
                    onUninstall={(shouldDeleteFiles: boolean) => onUninstall(shouldDeleteFiles)}
                />
            )}
        </>
    );
};

export default MarketplaceSearchItem;
