import {
    Infer,
    any,
    array,
    assign,
    boolean,
    date,
    instance,
    integer,
    nullable,
    number,
    object,
    optional,
    record,
    string,
} from "superstruct";
import { Money } from "ts-money";
import { PaymentType } from "../../constants";
import { Expires, FetchDataStore, ServerID, UserID, WalletLog } from "../../shared/types";
import { BillingsTypes } from "../Billings";
import { InstallProjectPayload, ProjectRelease } from "../Marketplace/types";
import { FilesTypes } from "./modules/Files";

export interface ServerStats {
    tcp_conns: number;
    cpu_limit: number;
    cpu_used: number;
    memory_current: number;
    memory_limit: number | undefined;
    time: number;
}

export interface InstallPackagePayload extends InstallProjectPayload {
    package: string;
}

export interface ChangeEngineFormValues {
    selectAll: boolean;
    backup: boolean;
    clearData: boolean;
    keepFiles: boolean;
    keepFilesPaths: string[];
}

export interface ProcessFileData {
    files: string[];
    path: string;
    size: number;
    user_id: UserID;
}

export interface CurrentServerStateFragment {
    target: FetchDataStore<Server | null>;
    quota: FetchDataStore<ServerQuota>;
    paymentsHistory: FetchDataStore<WalletLog[]>;
    ststs: ServerStats[];
    consoleLogs: string[];
    files: FilesTypes.Paths;
}

export type SettlementMethod = "monthly" | "3-month";
export type ServerType = "grass" | "diamond";
export type ServerFeatures = { value: string; available: boolean }[];
export type ServerDifficultyLevel = "survival" | "creative" | "adventure";
export type ServerGameMode = "peaceful" | "easy" | "normal" | "hard";
export type ServerEngine = "paper" | "vanilla" | "fabric" | "forge";
export type MapOptionsType = {
    mapType: "standard" | "flat" | "largeBiomes";
    nether: boolean;
    pvp: boolean;
    animals: boolean;
    commandBlocks: boolean;
    npcsVillages: boolean;
    flying: boolean;
    npc: boolean;
    monsters: boolean;
};

export type ClusterID = string;

export const Coupon = object({
    name: string(),
    expression: string(),
    add_hours_on_renewal: optional(string()),
    end_time: nullable(date()),
    uses_left: nullable(integer()),
    details: object({
        voucher_id: string(),
    }),
});

export type Coupon = Infer<typeof Coupon>;

export const UserCapabilities = object({
    user_id: string(),
    resource_id: nullable(string()),
    resource_type: string(),
    capabilities: record(string(), any()),
});

export type UserCapabilities = Infer<typeof UserCapabilities>;

export const DomainTarget = object({
    app: string(),
    record_name: string(),
    record_type: string(),
    dns_content: string(),
    mode: nullable(string()),
});

export type DomainTarget = Infer<typeof DomainTarget>;

export const Domain = object({
    id: number(),
    server_id: string(),
    user_id: string(),
    kind: string(),
    fqdn: string(),
    sub_domain: nullable(string()),
    zone_name: string(),
    external_ns: nullable(string()),
    dpl_uid: nullable(string()),
    dpl_request_id: nullable(integer()),
    targets: array(DomainTarget),
    updated_at: date(),
    created_at: date(),
    expires: nullable(date()),
});

export type Domain = Infer<typeof Domain>;

export const NodePort = object({
    port: number(),
    type: string(),
    cluster_id: string(),
    target_port: number(),
    service_id: string(),
    name: string(),
    protocol: string(),
    created_by: nullable(string()),
    updated_at: date(),
});

export type NodePort = Infer<typeof NodePort>;

export const ReleasePortPayload = object({ name: string() });

export type ReleasePortPayload = Infer<typeof ReleasePortPayload>;

export const GameServerProductParameters = object({
    allowed_packages: optional(array(string())),
    cleanup_after: optional(string()),
    cpu: optional(
        object({
            daemon: number(),
            limit: string(),
            request: string(),
        })
    ),
    game: optional(
        object({
            allowed_engines: array(string()),
            blacklisted_packages: array(string()),
            engine: string(),
            max_players: optional(number()),
            sleep: boolean(),
            antibot: boolean(),
            game: string(),
            autoinstall: optional(boolean()),
        })
    ),

    memory: optional(
        object({
            daemon: number(),
            game_limit: string(),
            game_request: string(),
            pod_limit: string(),
            pod_request: string(),
        })
    ),
    secondary_domains: optional(number()),
    image: optional(string()),
    storage: optional(number()),
});

export const PortsParameter = object({
    name: string(),
    container_port: number(),
    protocol: string(),
});

export type PortsParameter = Infer<typeof PortsParameter>;

export type GameServerProductParameters = Infer<typeof GameServerProductParameters>;

export const Server = object({
    id: string(),
    port: nullable(integer()),
    cluster_id: string(),
    persistent_volume_claim: nullable(string()),
    cluster_ports: array(NodePort),
    apps_token_secret: string(),
    daemon_secret: string(),
    autostart: boolean(),
    restic_repo_id: nullable(string()),
    deleted_at: nullable(date()),
    cleanup_date: nullable(date()),
    last_check: nullable(date()),
    hidden: boolean(),
    testing: boolean(),
    referrer: nullable(string()),
    sponsored: boolean(),
    users_capabilities: array(UserCapabilities),
    domains: array(Domain),
    parameters: Object(GameServerProductParameters),
});

export type Server = Infer<typeof Server>;

export const PublicServer = object({
    id: string(),
    cluster_id: string(),
    persistent_volume: nullable(string()),
    cluster_ports: array(NodePort),
    cleanup_date: nullable(date()),
    autostart: boolean(),
    disabled: boolean(),
    hidden: boolean(),
    testing: boolean(),
    referrer: nullable(string()),
    sponsored: boolean(),
    auto_renew: boolean(),
    active: boolean(),
    user_capabilities: record(string(), any()),
    parameters: GameServerProductParameters,
    domains: array(Domain),
    deleted_at: nullable(date()),
    pod: nullable(string()),
    last_node: nullable(string()),
    can_migrate: boolean(),
});

export type PublicServer = Infer<typeof PublicServer>;

export const ServerWithParameters = assign(
    PublicServer,
    object({
        active: boolean(),
        parameters: record(string(), any()),
    })
);

export type ServerWithParameters = Infer<typeof ServerWithParameters>;

export const Cluster = object({
    id: string(),
    visible: boolean(),
    disabled: boolean(),
    host: string(),
    zone_name: string(),
    metadata: object({
        labels: record(string(), string()),
    }),
});

export type Cluster = Infer<typeof Cluster>;

export const ServerQuota = object({
    type: integer(),
    bsize: integer(),
    blocks: integer(),
    bfree: integer(),
    bavail: integer(),
    files: integer(),
    ffree: integer(),
    flags: integer(),
});

export type ServerQuota = Infer<typeof ServerQuota>;
export interface MinecraftUserParameters {
    engine: string;
}

export const VoucherCost = object({
    cost: instance(Money),
    services: any(),
});

export interface RequestedBillingServices {
    name: string;
    user_parameters: object | null;
}

export interface DomainPayload {
    subdomain: string;
    zone_name: string;
}

export interface CreateServerPayload {
    game: string;
    cluster_id: ClusterID;
    requested_billing_services: RequestedBillingServices[];
    billing_period: string;
    currency: string;
    domain: DomainPayload;
    vouchers: string[];
    terms: boolean;
    testing_server: boolean;
}

export interface PayForServerPayload {
    serverId: ServerID;
    vouchers: string[];
}

export interface CreateServerFormValues {
    billing_period: string;
    cluster: string;
    game?: string;
    engine?: string;
    vouchersProfits: VoucherProfit[];
    product: BillingsTypes.BillingProduct;
    additionalProducts: BillingsTypes.BillingProduct[];
    paymentMethod: PaymentType;
    eulaAccepted: boolean;
    regulationsAccepted: boolean;
    subdomain: string;
    zone_name: string;
}

export interface CreateCraftumFormValues {
    product: BillingsTypes.BillingProduct;
    regulationsAccepted: boolean;
    csrv_server_id: string;
}

export enum VoucherType {
    ADD_DAYS = "ADD_DAYS",
    ADD_FUNDS = "ADD_FUNDS",
    DISCOUNT = "DISCOUNT",
}

export enum InstallEngineStatus {
    IDLE = "IDLE",
    INSTALLING = "INSTALLING",
    ERROR = "ERROR",
    COMPLETE = "COMPLETE",
}

export interface VoucherAddFundsData {
    values: {
        [currency: string]: Money;
    };
}

export interface VoucherAddDaysData {
    value: string;
}

export interface VoucherCreateServerData {
    billing_period: string;
    cluster_id: string;
    products: {
        product_id: string;
        value: string;
        user_parameters: MinecraftUserParameters;
    }[];
}

export const VoucherTarget = object({
    product_id: string(),
    billing_period: string(),
    cluster_id: string(),
    user_parameters: record(string(), any()),
});

export type VoucherTarget = Infer<typeof VoucherTarget>;

export const ChangeEnginePayload = object({
    engine: string(),
    keep_files: array(string()),
});

export type ChangeEnginePayload = Infer<typeof ChangeEnginePayload>;

export const VoucherAction = object({
    targets: optional(array(VoucherTarget)),
    add_hours: optional(string()),
    new_cost: optional(instance(Money)),
    coupon: optional(Coupon),
    wallet_tx: optional(record(string(), instance(Money))),
});
export type VoucherAction = Infer<typeof VoucherAction>;

export type VoucherCost = Infer<typeof VoucherCost>;

export const Voucher = object({
    id: string(),
    created_at: date(),
    expires: nullable(date()),
    last_use_id: nullable(integer()),
    group_id: nullable(string()),
    receivers: optional(array(string())),
    referrer: optional(string()),
    type: string(),
    data: array(VoucherAction),
});
export type Voucher = Infer<typeof Voucher>;

export interface PublicVoucher {
    id: string;
    created_at: Date;
    expires: Expires;
    group_id: string;
    last_used_id: string | null;
    type: VoucherType;
    data: Coupon[] | VoucherAddDaysData | VoucherAddFundsData | VoucherCreateServerData;
}

// export const PublicVoucher = object({
//     id: string(),
//     created_at: date(),
//     expires: nullable(date()),
//     group_id: string(),
//     last_used_id: string(),
//     type: string(),
//     data: union(PriceModifier),
// });

// export type PublicVoucher = Infer<typeof PublicVoucher>;

export interface CreateServerFromVoucherPayload {
    name: string;
    voucher_id: string;
    currency: string;
    domain: {
        subdomain: string;
        zone_name: string;
    };
}

export const VoucherUse = object({
    id: string(),
    ts: date(),
    user_id: string(),
    voucher_id: string(),
    details: object(),
});
export type VoucherUse = Infer<typeof VoucherUse>;

export const VoucherUseWithVoucher = object({
    id: string(),
    ts: date(),
    user_id: string(),
    voucher_id: string(),
    details: object(),
    voucher: Voucher,
});
export type VoucherUseWithVoucher = Infer<typeof VoucherUseWithVoucher>;

export const AllocatePortPayload = object({
    name: string(),
    protocol: string(),
});

export type AllocatePortPayload = Infer<typeof AllocatePortPayload>;

export const AllocatePortResponse = assign(AllocatePortPayload, object({ port: number() }));

export type AllocatePortResponse = Infer<typeof AllocatePortResponse>;

export interface VoucherProfit {
    id: string;
    data: VoucherAction[];
    error?: string;
}

export interface Log {
    location: string;
    type: string;
    description: string;
    createdAt: Date;
}

export interface LogDetails {
    id: number;
    level: number;
    resource_id: string;
    resource_type: string;
    created_at: Date;
    action: string;
    data: string;
    success: boolean;
}

export interface BenchmarkStat {
    memory: number;
    cpu_used: number;
    cpu_limit: number;
    time: number;
    memory_limit: number;
}

export interface AddVoucherPayload {
    voucher: string;
}

export interface SetSecondaryDomainPayload {
    subdomain: string;
    zone_name: string;
}

export interface CheckAvailabilityDomainPayload {
    subdomain: string;
    zone_name: string;
}

export interface InstalledEngine {
    name: string;
    version: string;
    labels: Record<string, string>;
    depends: [];
    downloadfiles: [];
    manifest: {
        name: string;
        version: string;
    };
    fileshash: string;
    provides: [];
    subdir: string;
    tags: string[];
}

export const Engine = object({
    name: string(),
    subdir: string(),
    version: string(),
    tags: array(string()),
    ManifestHash: string(),
    manifestVersion: string(),
    provides: array(string()),
    labels: object({
        BUILD_TIME: string(),
        GAME: string(),
        JAVA_VERSION: string(),
        MINECRAFT_JAVA_VERSION: string(),
        MOD_LOADER: string(),
        TYPE: string(),
    }),
    dependencies: array(
        object({
            name: string(),
            tag: string(),
        })
    ),
    filesHash: string(),
    downloadFiles: array(
        object({
            name: string(),
            url: string(),
            hash: string(),
        })
    ),
    latest_release: nullable(ProjectRelease),
});

export type Engine = Infer<typeof Engine>;

export const PublicMarketProjectMedia = object({
    name: string(),
    url: string(),
});

export type PublicMarketProjectMedia = Infer<typeof PublicMarketProjectMedia>;

export interface EngineWithMarketplaceData extends Engine {
    media: PublicMarketProjectMedia[];
    priority: number;
    short_description: string;
}

export const MinecraftEnginesMap = array(Engine);
export type MinecraftEnginesMap = Infer<typeof MinecraftEnginesMap>;

const TotalPrice = object({
    cluster_id: string(),
    price: instance(Money),
    period: string(),
});
type TotalPrice = Infer<typeof TotalPrice>;

export const Offer = object({
    id: string(),
    total_price: array(TotalPrice),
});

export type Offer = Infer<typeof Offer>;

export interface MinecraftServerProperties {
    "enable-jmx-monitoring": boolean;
    "rcon.port": number;
    "level-seed": string;
    gamemode: ServerDifficultyLevel;
    "enable-command-block": boolean;
    "enable-query": boolean;
    "generator-settings": object;
    "enforce-secure-profile": boolean;
    "level-name": string;
    motd: string;
    "query.port": number;
    pvp: boolean;
    "generate-structures": boolean;
    "max-chained-neighbor-updates": number;
    difficulty: "peaceful" | "easy" | "normal" | "hard";
    "network-compression-threshold": number;
    "max-tick-time": number;
    "require-resource-pack": boolean;
    "use-native-transport": boolean;
    "max-players": number;
    "online-mode": boolean;
    "enable-status": boolean;
    "allow-flight": boolean;
    "broadcast-rcon-to-ops": boolean;
    "view-distance": number;
    "server-name": string;
    "server-ip": string;
    "resource-pack-prompt": string;
    "allow-nether": boolean;
    "server-port": number;
    "enable-rcon": boolean;
    "sync-chunk-writes": boolean;
    "op-permission-level": number;
    "prevent-proxy-connections": boolean;
    "hide-online-players": boolean;
    "resource-pack": string;
    "entity-broadcast-range-percentage": number;
    "simulation-distance": number;
    "rcon.password": string;
    "player-idle-timeout": number;
    debug: boolean;
    "force-gamemode": boolean;
    "rate-limit": number;
    hardcore: boolean;
    "white-list": boolean;
    "broadcast-console-to-ops": boolean;
    "spawn-npcs": boolean;
    "previews-chat": boolean;
    "spawn-animals": boolean;
    "function-permission-level": 2;
    "level-type": "minecraft:normal" | string;
    "text-filtering-config": string;
    "spawn-monsters": boolean;
    "enforce-whitelist": boolean;
    "spawn-protection": number;
    "resource-pack-sha1": string;
    "max-world-size": 29999984;
}

export type SetFieldValueType = (field: string, value: unknown, shouldValidate?: boolean | undefined) => void;

export const GameVersions = object({
    id: string(),
    type: string(),
    url: string(),
    time: string(),
    releaseTime: string(),
});

export type GameVersions = Infer<typeof GameVersions>;

export const SearchGamesResponse = record(
    string(),
    object({
        latest: object({
            release: string(),
            snapshot: string(),
        }),
        versions: array(GameVersions),
    })
);

export type SearchGamesResponse = Infer<typeof SearchGamesResponse>;

export const CreateServerResponse = object({
    server: Server,
});

export type CreateServerResponse = Infer<typeof CreateServerResponse>;

export type UserTicketsResponse = Array<{
    id: number;
    url: string;
    subject: string;
    updated_at: string;
    status: string;
    created_at: string;
}>;

export interface MigratePayload {
    serverId: ServerID;
    cause: string;
    fallbackBackupId?: string | null;
    // admin
    force?: boolean;
    target?: string;
}

export interface MinecraftDaemonData {
    data: MinecraftDaemonMessage[];
    stats?: ServerStats[];
    bytes: number;
    totalBytes?: number;
}

export const MinecraftDaemonMessage = object({
    time: string(),
    log: string(),
});

export type MinecraftDaemonMessage = Infer<typeof MinecraftDaemonMessage>;

export const ConsoleHistoryResponse = object({
    msg: string(),
    bytes: number(),
    stream: string(),
});

export type ConsoleHistoryResponse = Infer<typeof ConsoleHistoryResponse>;

export const ReadConsoleFragmentResponse = object({
    offset: number(),
    length: number(),
    totalBytes: number(),
    data: string(),
});

export type PointerToastType = "success" | "error" | "loading" | "blank" | "custom";

export interface PointerToast {
    id: string;
    text: string;
    type: PointerToastType;
}

export const GenerateRemotefsTokenResponse = object({
    token: string(),
});

export type GenerateRemotefsTokenResponse = Infer<typeof GenerateRemotefsTokenResponse>;
export type ReadConsoleFragmentResponse = Infer<typeof ReadConsoleFragmentResponse>;

export const GetConsoleFragmentResponse = object({
    offset: integer(),
    length: integer(),
    totalBytes: integer(),
    data: string(),
});

export type GetConsoleFragmentResponse = Infer<typeof GetConsoleFragmentResponse>;
