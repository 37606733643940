import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useSearchProjectReleaseByUserQuery } from "../../modules/Marketplace/api";
import UserProjects from "../../modules/Marketplace/components/UserProjects";
import { selectCurrentUser } from "../../modules/User/slices/authSlice";
import Layout from "../../shared/components/Layout";
import LayoutSpace from "../../shared/components/LayoutSpace";
import { inIframe } from "../../shared/helpers/iframe";

const UserProjectsScreen = () => {
    const user = useSelector(selectCurrentUser);

    const { data, isLoading } = useSearchProjectReleaseByUserQuery(user ? user.id : "", {
        skip: !user,
    });
    const withLayout = useMemo(() => {
        return !inIframe();
    }, []);

    if (!data) {
        return <div>loading</div>;
    }

    if (withLayout) {
        return (
            <Layout>
                <LayoutSpace>
                    <UserProjects projects={data} projectsLoading={isLoading} />
                </LayoutSpace>
            </Layout>
        );
    }

    return <UserProjects projects={data} projectsLoading={isLoading} />;
};

export default UserProjectsScreen;
