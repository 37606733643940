import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    box-sizing: border-box;
    grid-column: 3 / 4;
    z-index: 2;

    @media (min-width: 1000px) {
        min-height: 100vh;
        overflow-y: scroll;
    }
`;
