import Layout from "../../shared/components/Layout";
import LayoutSpace from "../../shared/components/LayoutSpace";
import AdminSearch from "../../modules/Admin/components/AdminSearch";
import { Space } from "csrvui";

const AdminScreen = () => {
    return (
        <Layout>
            <LayoutSpace>
                <Space $padding={0} $fullWidth $align="center" style={{ minHeight: "70vh" }}>
                    <AdminSearch />
                </Space>
            </LayoutSpace>
        </Layout>
    );
};

export default AdminScreen;
