import styled from "styled-components";

export const MarketplaceSearchItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 79px;
    height: 79px;
    border-radius: 8px;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: 0.3s;
    aspect-ratio: 1/1;
    margin: auto 0;
    img {
        width: 100%;
        height: 100%;
    }

    &:hover {
        transform: scale(1.05);
    }
`;

export const Info = styled.div`
    content: "";
    position: absolute;
    top: 5px;
    right: 5px;
    color: ${({ theme }) => theme.colors.danger};
    font-size: 20px;
    width: 24px;
    height: 24px;
    border: 1px solid ${({ theme }) => theme.colors.danger};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
`;
