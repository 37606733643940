import { Button, InternalLink, Paragraph, Space } from "csrvui";

import { GrStatusCriticalSmall } from "react-icons/gr";
import { SearchResultWrapper } from "../../styles";
import { Domain } from "../../../Server/types";
import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import MonacoEditor from "react-monaco-editor";
import { isBefore } from "date-fns";
import useTheme from "../../../../shared/hooks/useCustomTheme";

interface Props {
    domain: Domain;
}

const DomainResult = ({ domain }: Props) => {
    const { t } = useTranslation();
    const [showMore, setShowMore] = useState(false);
    const isDomainActive = useMemo(
        () => (domain.expires ? isBefore(new Date(), new Date(domain.expires)) : true),
        [domain]
    );
    const theme = useTheme();

    return (
        <SearchResultWrapper style={{ flexDirection: "column" }}>
            <Space $padding={0} $direction="horizontal" $fullWidth>
                <Space $padding={0} $align="space-between" $oppositeAlign="center" $direction="horizontal">
                    <GrStatusCriticalSmall
                        color={isDomainActive ? theme.colors.success : theme.colors.danger}
                        size={42}
                    />
                    <Space $gap={0} $padding={0} $align="start">
                        <Paragraph style={{ fontSize: "20px", fontWeight: 500 }}>{domain.fqdn}</Paragraph>
                        <Paragraph
                            style={{
                                width: "100%",
                                textAlign: "start",
                                color: isDomainActive ? theme.colors.success : theme.colors.danger,
                                fontWeight: 500,
                            }}
                        >
                            {/* {t("dates.simple", { date: new Date(domain.created_at) })} */}
                            {isDomainActive ? t("common.active") : t("common.inactive")}
                        </Paragraph>
                    </Space>
                </Space>
                <Space $padding={0} $direction="horizontal">
                    <InternalLink to={`/s/${domain.server_id}`}>Przejdź do serwera</InternalLink>
                    <Button onClick={() => setShowMore(!showMore)} $variant="dark" $sizeVariant="small">
                        Pokaż szczegóły
                    </Button>
                </Space>
            </Space>
            {showMore && (
                <Space
                    $padding={0}
                    style={{
                        minHeight: "400px",
                        width: "100%",
                        borderRadius: "8px",
                        overflow: "hidden",
                        marginTop: "10px",
                    }}
                >
                    <MonacoEditor
                        theme={"vs-light"}
                        language="json"
                        options={{
                            readOnly: true,
                            wordWrap: "on",
                        }}
                        height={400}
                        value={JSON.stringify(domain, null, 2)}
                    />
                </Space>
            )}
        </SearchResultWrapper>
    );
};

export default DomainResult;
