import { configureStore } from "@reduxjs/toolkit";
import { BillingsApi } from "./modules/Billings";
import { craftumsApi } from "./modules/Craftums/api";
import { marketplaceApi } from "./modules/Marketplace/api";
import { PaymentsApi } from "./modules/Payments";
import { ServerApi } from "./modules/Server";
import { clusterApi } from "./modules/Server/clusterApi";
import { BackupsApi } from "./modules/Server/modules/Backups";
import { FilesApi } from "./modules/Server/modules/Files";
import { api as walletsApi } from "./modules/Server/modules/Wallets/api";
import { ServicesApi } from "./modules/Services";
import { UserApi } from "./modules/User";
import { supportApi } from "./modules/User/api";
import { adminApi } from "./modules/Admin/api";
import rootReducer from "./rootReducer";

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }).concat(
            BillingsApi.api.middleware,
            ServerApi.api.middleware,
            ServerApi.eventLogsApi.middleware,
            ServerApi.domainsApi.middleware,
            clusterApi.middleware,
            PaymentsApi.paymentsApi.middleware,
            UserApi.api.middleware,
            FilesApi.api.middleware,
            ServicesApi.api.middleware,
            BackupsApi.api.middleware,
            marketplaceApi.middleware,
            supportApi.middleware,
            walletsApi.middleware,
            craftumsApi.middleware,
            adminApi.middleware,
            ServerApi.packagesApi.middleware
        ),
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
