import { useEffect, useState } from "react";
import useDebounce from "../../../shared/hooks/useDebounce";
import { useSearchMutation } from "../api";
import { AdminTypes } from "..";
import { showApiError } from "../../../shared/helpers/showToast";

const useAdminSearch = () => {
    const [searchData] = useSearchMutation();
    const [searchQuery, setSearchQuery] = useState("");
    const [results, setResults] = useState<AdminTypes.AdminSearchResponse | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const debounceValue = useDebounce(searchQuery, 1000);
    const [isError, setIsError] = useState<boolean>(false);

    const handleSearch = (value: string) => {
        if (value !== "") {
            setIsLoading(true);
            setSearchQuery(value);
        }
    };

    const search = async () => {
        if (debounceValue === "") return;
        setIsError(false);
        try {
            const results = await searchData({ q: debounceValue }).unwrap();
            setResults(results);
        } catch (err) {
            setIsError(true);
            console.error(err);
            setResults(null);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        search();
    }, [debounceValue]);

    return {
        handleSearch,
        isLoading,
        results,
        isError,
    };
};

export default useAdminSearch;
