import { createContext, useContext } from "react";

type GRPCProcessesContextType = {
    isConnected: boolean;
    connect: () => void;
    disconnect: () => void;
};

export const GRPCProcessesContext = createContext<GRPCProcessesContextType>({
    isConnected: false,
    connect: () => {
        return;
    },
    disconnect: () => {
        return;
    },
});

export default () => useContext(GRPCProcessesContext);
