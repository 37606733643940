import { useCallback, useContext, useMemo } from "react";
import { useLazyServerByIdQuery } from "../../modules/Server/api";

import { ProcessState } from "csrvfs";
import CsrvProcess, { CsrvProcessType } from "csrvprocess";
import { ProcessMessage } from "csrvprocess/dist/types";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { BillingService } from "../../modules/Billings/types";
import { getCurrentService } from "../../modules/Server/slices/currentServerSlice";
import { ProcessType } from "../../modules/User/constants";
import useUserData from "../../modules/User/hooks/useUserData";
import { onProcessesChange } from "../../modules/User/slices/processesSlice";
import { HostUrl } from "../../rootTypes";
import { CurrentServerContext } from "../components/CurrentServerProvider";
import debug from "../helpers/debug";
import { setUILocalOption } from "../helpers/localStorage";

export default function () {
    const service = useAppSelector(getCurrentService);
    const { refreshCurrentServerData } = useContext(CurrentServerContext);
    const dispatch = useAppDispatch();
    const [trigger] = useLazyServerByIdQuery();
    const csrvProcess = useMemo(() => new CsrvProcess(HostUrl), []);
    const { user } = useUserData();

    const connectToProcesses = useCallback(async () => {
        console.debug("GRPC processes ", {
            service,
            user,
        });

        const servicesIds: string[] = [];
        // it means that user tries to connect to server that is not his own (e.x. admin or user with capability)
        if (service && !user.services.some((s: BillingService) => s.service_id === service.service_id)) {
            servicesIds.push(service.service_id);
        }

        if (servicesIds.length) {
            debug("Connect to processes for services ids: ", servicesIds.join(", "));
        } else {
            debug("Connect to processes");
        }

        try {
            csrvProcess.connectToProcesses(servicesIds, (process: ProcessMessage) => {
                debug("GRPC process received: ", process);
                setUILocalOption("processesExpanded", true);
                const event = new CustomEvent(`process-${process.type}-${process.state}`, {
                    detail: {
                        serverId: process.resourceid,
                    },
                });

                if (process.state !== ProcessState.IN_PROGRESS) {
                    const event = new CustomEvent("process-finished");
                    window.dispatchEvent(event);
                }

                if (process.type === ProcessType.SERVER_PACKAGE_CREATE && process.state === ProcessState.DONE) {
                    const event = new CustomEvent("process-package-created");
                    window.dispatchEvent(event);
                }

                if (process.type === ProcessType.SERVER_PACKAGE_CREATE && process.state === ProcessState.IN_PROGRESS) {
                    const event = new CustomEvent("process-package-in-progress", {
                        detail: {
                            id: process.processid,
                        },
                    });
                    window.dispatchEvent(event);
                }

                if (
                    process.type === ProcessType.SERVER_PACKAGE_CREATE &&
                    (process.state === ProcessState.FAILED ||
                        process.state === ProcessState.TIMEOUT ||
                        process.state === ProcessState.ABORTED)
                ) {
                    const event = new CustomEvent("process-package-failed", {
                        detail: {
                            id: process.processid,
                        },
                    });
                    window.dispatchEvent(event);
                }

                window.dispatchEvent(event);
                switch (process.type) {
                    case CsrvProcessType.STOPPING_GAME:
                        if (process.state === ProcessState.DONE) {
                            trigger(process.resourceid);
                        }

                        break;

                    case CsrvProcessType.STARTING_GAME:
                        if (process.state === ProcessState.DONE) {
                            trigger(process.resourceid);
                        }

                        break;
                }
            });
        } catch (err) {
            console.error("PROCESS ERROR MESSAGE: ", (err as Error).message);
        }

        csrvProcess.subscribeProcesses((processes) => {
            dispatch(
                onProcessesChange({
                    processes,
                })
            );
        });
    }, [csrvProcess, dispatch, refreshCurrentServerData, trigger, user, service]);

    const disconnectFromProcesses = useCallback(() => {
        debug("Disconnect GRPC processes");
        csrvProcess.unsubscribeProcesses();
    }, [csrvProcess]);

    const reconnectToProcesses = useCallback(() => {
        debug("Reconnect GRPC processes");
        connectToProcesses();
    }, [connectToProcesses]);

    return {
        connectToProcesses,
        disconnectFromProcesses,
        reconnectToProcesses,
    };
}
