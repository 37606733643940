import { toast } from "react-toastify";
import { ErrorsMessages } from "../../constants";
import { ErrorCode } from "../../rootTypes";
import CustomError from "../CustomError";

interface Settings {
    variant: "success" | "warning" | "error" | "default" | "info";
    text: string;
    desc?: string;
}

const showToast = ({ variant, text, desc }: Settings) => {
    toast(`${text}\n${desc ? desc : ""}`, {
        type: variant,
        position: toast.POSITION.TOP_LEFT,
    });
};

export const showApiError = (err: unknown) => {
    const customError = err as CustomError;
    showRequestErrorToast(customError.error, customError.event_id);
};

export const showRequestErrorToast = (message?: string, event_id?: string) => {
    showToast({
        variant: "error",
        text: message
            ? ErrorsMessages[message as ErrorCode] || ErrorsMessages[ErrorCode.NOT_RECOGNIZED]
            : ErrorsMessages[ErrorCode.NOT_RECOGNIZED],
        desc: event_id ? `(code: ${event_id})` : undefined,
    });
};

export default showToast;
