import React from "react";
import { useTranslation } from "react-i18next";
import { setLocalLanguage } from "../../helpers/localStorage";
import { Select } from "./style";

const LanguageSelect: React.FC<{ light?: boolean }> = ({ light }) => {
    const {
        t,
        i18n: { changeLanguage, language: currentLanguage },
    } = useTranslation();

    return (
        <Select
            fullWidth={false}
            countries={["GB", "PL"]}
            customLabels={{ GB: t("languages.en"), PL: t("languages.pl") }}
            selected={currentLanguage === "en" ? "GB" : "PL"}
            onSelect={(code) => {
                switch (code) {
                    case "GB":
                        changeLanguage("en");
                        setLocalLanguage("en-GB");

                        break;
                    case "PL":
                        changeLanguage("pl");
                        setLocalLanguage("pl-PL");

                        break;
                }
            }}
            light={light}
        />
    );
};

export default LanguageSelect;
