import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";

interface Props {
    data: {
        position: {
            x: number;
            y: number;
        };
        visible: boolean;
        text: string;
    };
}

const Container = styled.div`
    &::before {
        // make the triangle point to the left
        content: "";
        position: absolute;
        left: -8px;
        top: 50%;
        width: 0;
        height: 0;
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;
        border-right: 8px solid #ffffff;
        transform: translateY(-40%);
    }
`;

const SidebarTooltip: React.FC<Props> = ({
    data: {
        position: { x, y },
        visible,
        text,
    },
}) => {
    const el = useRef(document.createElement("div"));

    useEffect(() => {
        const currentEl = el.current;
        document.body.appendChild(currentEl);
        return () => {
            document.body.removeChild(currentEl);
        };
    }, []);

    return createPortal(
        <Container
            style={{
                display: visible ? "flex" : "none",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                left: x,
                top: y,
                height: 40,
                background: "#ffffff",
                color: "#193b17",
                zIndex: 999999,
                width: "max-content",
                padding: "12px 16px",
                wordWrap: "break-word",
            }}
        >
            <p>{text}</p>
        </Container>,
        el.current
    );
};

export default SidebarTooltip;
