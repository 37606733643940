import { Modal, ModalProps } from "react-responsive-modal";

const ResponsiveModal: React.FC<ModalProps> = ({ children, ...props }) => {
    return (
        <Modal
            styles={{
                overlay: {
                    backgroundColor: "rgba(0,0,0,0.5)",
                },

                modal: {
                    width: "min-content",
                },
            }}
            {...props}
        >
            {children}
        </Modal>
    );
};

export default ResponsiveModal;
