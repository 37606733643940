import { CsrvProcessType } from "csrvprocess";
import { Button, Image, Paragraph, Space, Tooltip } from "csrvui";
import { isAfter } from "date-fns";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineLogout } from "react-icons/ai";
import { CiWallet } from "react-icons/ci";
import { HiOutlineServerStack } from "react-icons/hi2";
import { IoMdNotificationsOutline } from "react-icons/io";
import { MdMoneyOffCsred } from "react-icons/md";
import { VscAccount, VscAdd, VscCalendar, VscFiles, VscHistory, VscHome, VscSettings } from "react-icons/vsc";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link, useParams } from "react-router-dom";
import MobileLogo from "../../../assets/images/csrv-logo.svg";
import { useAppSelector } from "../../../hooks";
import { MarketFilesName } from "../../../modules/Marketplace/types";
import isServicePaidFor from "../../../modules/Server/helpers/isServerPaidFor";
import { getCurrentService } from "../../../modules/Server/slices/currentServerSlice";
import { routeAlertSelector } from "../../../modules/Server/slices/routeChangeAlertSlice";
import { useLogoutMutation } from "../../../modules/User/api";
import { ProcessState } from "../../../modules/User/constants";
import { getProcesses } from "../../../modules/User/slices/processesSlice";
import { showApiError } from "../../helpers/showToast";
import useCustomTheme from "../../hooks/useCustomTheme";
import useGRPCProcessesContext from "../../hooks/useGRPCProcessesContext";
import { EventAction, VisualLinkMode } from "../../types";
import { CurrentServerContext } from "../CurrentServerProvider";
import RouteChangeAlertModal from "../RouteChangeAlertModal";
import SidebarTooltip from "../SidebarTooltip";
import { IconWrapper, MenuItem, SidebarLink, StyledContainer, StyledList, StyledLogoWrapper, Wrapper } from "./style";
import { Props } from "./types";
import useEventTracker from "../../hooks/useEventTracker";

interface MenuElement {
    path: string;
    name: string;
    leftIcon: JSX.Element;
    alwaysEnabled?: boolean;
    isApp?: boolean;
}

const Sidebar: React.FC<Props> = ({
    $mode = VisualLinkMode.HIDE,
    $disabled,
    enableAutostart,
    disableAutostartAndStop,
    // stopServer,
    autostartLoading,
    isServerRunning,
    ...props
}) => {
    const { serverIdentity } = useParams();
    const { t } = useTranslation();
    const { refreshCurrentServerData, installedApps, refreshInstalledPackages, server } =
        useContext(CurrentServerContext);
    const service = useAppSelector(getCurrentService);
    const theme = useCustomTheme();
    const location = useLocation();
    const processes = useAppSelector(getProcesses);
    const [subsidebarOpen, setSubsidebarOpen] = React.useState(false);
    const subsidebarRef = React.useRef<HTMLDivElement>(null);
    const [showChangeRouteAlertModal, setShowChangeRouteAlertModal] = useState(false);
    const [targetLocation, setTargetLocation] = useState<string | null>(null);
    const isAlertEnabled = useSelector(routeAlertSelector);
    const { trackEvent } = useEventTracker();
    const { disconnect } = useGRPCProcessesContext();

    const [tooltipData, setTooltipData] = useState({
        position: {
            x: 0,
            y: 0,
        },
        visible: false,
        text: "",
    });
    const [logout] = useLogoutMutation();

    const handleShowTooltip = useCallback((e: React.MouseEvent<HTMLElement, MouseEvent>, text: string) => {
        const rect = e.currentTarget.getBoundingClientRect();
        setTooltipData((prev) => ({
            ...prev,
            position: {
                x: rect.x + rect.width + 10,
                y: rect.y,
            },
            visible: true,
            text,
        }));
    }, []);

    const handleHideTooltip = useCallback(() => {
        setTooltipData((prev) => ({
            ...prev,
            visible: false,
        }));
    }, []);

    const preventRouteChange = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (isAlertEnabled) {
            e.preventDefault();
            const pendingLocation = e.currentTarget.getAttribute("href");
            setTargetLocation(pendingLocation);
            setShowChangeRouteAlertModal(true);
        }
    };

    const onLogout = async () => {
        try {
            await logout().unwrap();
            disconnect();
            window.location.href = "/login";
        } catch (e) {
            showApiError(e);
        }
    };

    const isLoading =
        processes.some(
            (process) =>
                (process.type === CsrvProcessType.STARTING_GAME || process.type === CsrvProcessType.STOPPING_GAME) &&
                process.state === "IN_PROGRESS" &&
                process.resourceId === server.id
        ) || autostartLoading;

    useEffect(() => {
        const listener = async () => {
            refreshCurrentServerData();
            refreshInstalledPackages();
        };

        const packagesListener = async () => {
            refreshInstalledPackages();
        };

        refreshInstalledPackages();
        window.addEventListener(`process-${CsrvProcessType.STOPPING_GAME}-${ProcessState.DONE}`, listener);
        window.addEventListener(`process-${CsrvProcessType.STARTING_GAME}-${ProcessState.DONE}`, listener);
        window.addEventListener(`process-${CsrvProcessType.PREPARING_SERVER}-${ProcessState.DONE}`, listener);
        window.addEventListener(`process-${CsrvProcessType.CREATING_SERVER_POD}-${ProcessState.DONE}`, listener);
        window.addEventListener(
            `process-${CsrvProcessType.SERVER_INSTALL_PACKAGE}-${ProcessState.DONE}`,
            packagesListener
        );
        window.addEventListener(
            `process-${CsrvProcessType.SERVER_UNINSTALL_PACKAGE}-${ProcessState.DONE}`,
            packagesListener
        );

        return () => {
            window.removeEventListener(`process-${CsrvProcessType.STOPPING_GAME}-${ProcessState.DONE}`, listener);
            window.removeEventListener(`process-${CsrvProcessType.STARTING_GAME}-${ProcessState.DONE}`, listener);
            window.removeEventListener(`process-${CsrvProcessType.PREPARING_SERVER}-${ProcessState.DONE}`, listener);
            window.removeEventListener(`process-${CsrvProcessType.CREATING_SERVER_POD}-${ProcessState.DONE}`, listener);
            window.removeEventListener(
                `process-${CsrvProcessType.SERVER_INSTALL_PACKAGE}-${ProcessState.DONE}`,
                packagesListener
            );
            window.removeEventListener(
                `process-${CsrvProcessType.SERVER_UNINSTALL_PACKAGE}-${ProcessState.DONE}`,
                packagesListener
            );
        };
    }, []);

    const elements: MenuElement[] = useMemo(
        () => [
            {
                path: `/s/${serverIdentity}`,
                name: t("sidebar.main_panel_name"),
                leftIcon: <VscHome size={30} />,
                alwaysEnabled: true,
            },
            {
                path: `/s/${serverIdentity}/payments`,
                name: t("routes.payments"),
                leftIcon: <CiWallet size={30} />,
                alwaysEnabled: true,
            },
            {
                path: `/s/${serverIdentity}/files/`,
                name: t("sidebar.files_name"),
                leftIcon: <VscFiles size={30} />,
            },
            {
                path: `/s/${serverIdentity}/backups`,
                name: t("routes.backups"),
                leftIcon: <VscHistory size={30} />,
                alwaysEnabled: true,
            },
            {
                path: `/s/${serverIdentity}/settings`,
                name: t("routes.settings"),
                leftIcon: <VscSettings size={30} />,
                alwaysEnabled: true,
            },
            {
                path: `/s/${serverIdentity}/events`,
                name: t("sidebar.logs_name"),
                leftIcon: <VscCalendar size={30} />,
            },
            ...(installedApps
                ? installedApps.data.map((app) => {
                      const projectIcon = app && app.media.find((media) => media.name.includes(MarketFilesName.ICON));
                      return {
                          path: `/s/${serverIdentity}/app/${app.name}`,
                          name: app.name,
                          leftIcon: (
                              <Image
                                  width={42}
                                  height={42}
                                  style={{
                                      borderRadius: 8,
                                  }}
                                  src={projectIcon ? projectIcon.url : `https://placehold.co/24x24?text=${app.name}`}
                              />
                          ),
                          isApp: true,
                      };
                  })
                : []),
            {
                path: `/s/${serverIdentity}/m`,
                name: t("routes.marketplace"),
                leftIcon: (
                    <Space $padding={0} $gap={0} $align="center" $oppositeAlign="center" style={{ marginLeft: 5 }}>
                        <VscAdd size={30} />
                    </Space>
                ),
            },
        ],
        [server.id, t, installedApps]
    );

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (subsidebarRef.current && !subsidebarRef.current.contains(event.target as Node)) {
                setSubsidebarOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [subsidebarOpen, subsidebarRef]);

    const activePath = useMemo(() => {
        const element = [...elements].reverse().find(({ path }) => location.pathname.startsWith(path));
        return element ? element.path : "/";
    }, [elements, location.pathname]);

    return (
        <>
            <StyledContainer $mode={$mode} {...props}>
                <div>
                    <StyledLogoWrapper>
                        <Link onClick={preventRouteChange} to="/">
                            <img width={42} src={MobileLogo} alt="Craftserve logo" />
                        </Link>
                    </StyledLogoWrapper>
                    <Wrapper>
                        <nav>
                            <Space
                                $padding={0}
                                $direction="horizontal"
                                $oppositeAlign="space-between"
                                $align="center"
                                $fullHeight
                            >
                                <StyledList>
                                    {!server ||
                                    (service.expires &&
                                        isAfter(new Date(), new Date(service.expires)) &&
                                        !service.never_expiring) ? (
                                        <Space $padding={0} style={{ marginBottom: "14px" }}>
                                            <Tooltip
                                                $position="right"
                                                text={t("errors.server_expired_go_to_payments")}
                                                $variant="default"
                                            >
                                                <MdMoneyOffCsred size={24} color="red" />
                                            </Tooltip>
                                        </Space>
                                    ) : (
                                        <Space $padding={0} $direction="vertical">
                                            <button
                                                disabled={isLoading || !isServicePaidFor(service)}
                                                onClick={async () => {
                                                    if (isServerRunning) {
                                                        trackEvent(EventAction.MANUAL_STOP_SERVER, {
                                                            label: "",
                                                        });
                                                        disableAutostartAndStop();
                                                    } else {
                                                        enableAutostart();
                                                        trackEvent(EventAction.MANUAL_START_SERVER, {
                                                            label: "",
                                                        });
                                                    }
                                                }}
                                                style={{
                                                    cursor: "pointer",
                                                    filter:
                                                        isLoading || !isServicePaidFor(service)
                                                            ? "grayscale(100%)"
                                                            : "none",
                                                    backgroundColor: theme.colors.sidebarLinkBackground,
                                                    borderRadius: 10,
                                                    marginBottom: 24,
                                                    padding: 0,
                                                    position: "relative",
                                                    width: 42,
                                                    height: 84,
                                                    marginLeft: "auto",
                                                    marginRight: "auto",
                                                    textTransform: "uppercase",
                                                    fontWeight: 300,
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "space-around",
                                                    alignItems: "center",
                                                    border: "none",
                                                }}
                                            >
                                                <Paragraph $color="#fff">on</Paragraph>
                                                <Paragraph $color="#fff">off</Paragraph>
                                                {/* create div which will shwo which option is active with animation */}
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        top: !isServerRunning ? "50%" : 0,
                                                        left: 0,
                                                        width: 42,
                                                        height: 42,
                                                        backgroundColor: theme.colors.success,
                                                        borderRadius: "10px",
                                                        opacity: 0.5,
                                                        transition: "top 0.3s ease",
                                                    }}
                                                />
                                            </button>
                                        </Space>
                                    )}
                                    {elements.slice(0, -1).map(({ path, name, leftIcon, alwaysEnabled = false }) => (
                                        <MenuItem key={name} active={activePath === path}>
                                            <SidebarLink
                                                to={path}
                                                $mode={$mode}
                                                onClick={preventRouteChange}
                                                $variant="secondary"
                                                color="white"
                                                $sizeVariant="small"
                                                $underline={false}
                                                $disabled={!alwaysEnabled && $disabled}
                                                onMouseEnter={(e) => {
                                                    const rect = e.currentTarget.getBoundingClientRect();
                                                    setTooltipData((prev) => ({
                                                        ...prev,
                                                        position: {
                                                            x: rect.x + rect.width + 10,
                                                            y: rect.y,
                                                        },
                                                        visible: true,
                                                        text: name,
                                                    }));
                                                }}
                                                onMouseLeave={() => {
                                                    setTooltipData((prev) => ({
                                                        ...prev,
                                                        visible: false,
                                                    }));
                                                }}
                                            >
                                                <IconWrapper>{leftIcon}</IconWrapper>
                                            </SidebarLink>
                                        </MenuItem>
                                    ))}
                                    {/* {isFetching && (
                                        <StyledSpace>
                                            <StyledLoaderSmall variant="white" />
                                        </StyledSpace>
                                    )} */}
                                    {elements.slice(-1).map(({ path, name, leftIcon, alwaysEnabled = false }) => (
                                        <MenuItem key={name} active={activePath === path}>
                                            <SidebarLink
                                                to={path}
                                                LeftIcon={leftIcon}
                                                $mode={$mode}
                                                onClick={preventRouteChange}
                                                $variant="secondary"
                                                $sizeVariant="small"
                                                $underline={false}
                                                $disabled={!alwaysEnabled && $disabled}
                                                style={{
                                                    padding: 0,
                                                    backgroundColor: theme.colors.sidebarLinkBackground,
                                                    borderRadius: 8,
                                                    aspectRatio: 1,
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    width: 42,
                                                    height: 42,
                                                    marginBottom: 24,
                                                }}
                                                onMouseEnter={(e) => handleShowTooltip(e, name)}
                                                onMouseLeave={handleHideTooltip}
                                            />
                                        </MenuItem>
                                    ))}
                                </StyledList>
                            </Space>
                        </nav>
                    </Wrapper>
                </div>
                <Space $padding={0} $direction="vertical" $gap={0}>
                    {/* <ButtonWithIconWrapper> */}
                    {/* {user.unread_notifications > 0 && (
                                    <ButtonIconWrapper>
                                        {user.unread_notifications < 100 ? user.unread_notifications : "99+"}
                                    </ButtonIconWrapper>
                                )} */}
                    {/* </ButtonWithIconWrapper> */}
                    <SidebarLink
                        $variant="secondary"
                        color="white"
                        $sizeVariant="small"
                        to="/account/settings"
                        LeftIcon={<VscAccount size={32} />}
                        onMouseEnter={(e) => handleShowTooltip(e, t("sidebar.account_management"))}
                        onMouseLeave={handleHideTooltip}
                        onClick={preventRouteChange}
                    />
                    <SidebarLink
                        $variant="secondary"
                        color="white"
                        $sizeVariant="small"
                        to="/notifications"
                        LeftIcon={<IoMdNotificationsOutline size={32} />}
                        onMouseEnter={(e) => handleShowTooltip(e, t("sidebar.notifications"))}
                        onMouseLeave={handleHideTooltip}
                        onClick={preventRouteChange}
                    />
                    <SidebarLink
                        $variant="secondary"
                        color="white"
                        $sizeVariant="small"
                        to="/account"
                        LeftIcon={<HiOutlineServerStack size={30} />}
                        onMouseEnter={(e) => handleShowTooltip(e, t("sidebar.services_list"))}
                        onMouseLeave={handleHideTooltip}
                        onClick={preventRouteChange}
                    />
                    <Button
                        $variant="white"
                        onClick={onLogout}
                        LeftIcon={<AiOutlineLogout />}
                        style={{ margin: 0 }}
                        onMouseEnter={(e) => handleShowTooltip(e, t("sidebar.logout"))}
                        onMouseLeave={handleHideTooltip}
                    />
                </Space>
            </StyledContainer>
            {showChangeRouteAlertModal && targetLocation && (
                <RouteChangeAlertModal
                    open={showChangeRouteAlertModal}
                    onClose={() => setShowChangeRouteAlertModal(false)}
                    onConfirm={targetLocation}
                />
            )}
            <SidebarTooltip data={tooltipData} />
        </>
    );
};

export default Sidebar;
