import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import * as Sentry from "@sentry/browser";
import { HttpStatus } from "../../constants";
import { ModpileUrl } from "../../rootTypes";
import { transformToStruct } from "../../shared/helpers/customQuery";
import { transformError } from "../../shared/helpers/errors";
import { AdminTypes } from ".";
import { AdminSearchResponse } from "./types";

export const adminApi = createApi({
    reducerPath: "admin",
    baseQuery: fetchBaseQuery({
        baseUrl: `${ModpileUrl}/api/admin`,
        prepareHeaders: (headers) => {
            const transactionId = Math.random().toString(36).substr(2, 9);
            Sentry.configureScope((scope) => {
                scope.setTag("transaction_id", transactionId);
            });
            headers.set("X-transation-ID", transactionId);

            return headers;
        },
    }),

    endpoints: (builder) => ({
        search: builder.mutation<AdminTypes.AdminSearchResponse, AdminTypes.AdminSearchOptions>({
            query: ({ q }) => {
                return {
                    url: "/search",
                    method: "GET",
                    params: { q },
                    validateStatus: (response) => response.status === HttpStatus.OK,
                };
            },
            transformResponse: (response: AdminTypes.AdminSearchResponse) => {
                return transformToStruct(response, AdminSearchResponse, "/search");
            },
            transformErrorResponse: transformError,
        }),
    }),
});

export const { useSearchMutation } = adminApi;
