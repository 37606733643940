import React from "react";
import styled from "styled-components";
import Footer from "../Footer";
import Header from "../Header";
import BetaNotice from "../BetaNotice";
import BetaAlert from "../BetaAlert";
import usePermissions from "../../hooks/usePermissions";
import AdminPanel from "../AdminPanel";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;

const Content = styled.div`
    flex: 1;
`;

interface Props {
    children: JSX.Element | JSX.Element[];
}

const Layout: React.FC<Props> = ({ children }) => {
    const { canAdminMenu } = usePermissions();

    return (
        <Container>
            <Header />
            <Content>{children}</Content>
            {canAdminMenu && <AdminPanel />}
            <Footer />
        </Container>
    );
};

export default Layout;
