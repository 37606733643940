import { Money } from "ts-money";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function transformData(data: any, structure: any) {
    if (!structure.schema) {
        return data;
    }
    const schema = structure.schema as object;
    const dates = Object.keys(schema).filter((key) => schema[key as keyof typeof schema].type === "date");
    const arrays = Object.keys(schema).filter(
        (key) =>
            schema[key as keyof typeof schema].type === "array" &&
            schema[key as keyof typeof schema].schema.type === "object"
    );
    const objects = Object.keys(schema).filter(
        (key) => schema[key as keyof typeof schema].type === "object" && schema[key as keyof typeof schema].schema
    );
    const instances = Object.keys(schema).filter((key) => schema[key as keyof typeof schema].type === "instance");
    const isMoney =
        Object.keys(data).length === 2 &&
        Object.keys(data).includes("amount") &&
        Object.keys(data).includes("currency");
    const isInstanceOfMoney = schema.type === "instance";

    if (isMoney || isInstanceOfMoney) {
        return new Money(data.amount, data.currency);
    }

    const isArray = Array.isArray(data);
    if (isArray) {
        // it's expected any cuz we don't know the type of data and we can store any type in the array
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return data.map((item: any) => transformData(item, schema));
    }

    if (dates.length > 0) {
        for (const key of dates) {
            if (data[key]) {
                data[key] = new Date(data[key]);
            } else {
                data[key] = null;
            }
        }
    }

    if (arrays.length > 0) {
        for (const key of arrays) {
            if (data[key]) {
                // it's expected any cuz we don't know the type of data and we can store any type in the array
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                data[key] = data[key].map((item: any) =>
                    transformData(item, schema[key as keyof typeof schema].schema)
                );
            }
        }
    }

    if (objects.length > 0) {
        for (const key of objects) {
            if (data[key]) {
                data[key] = transformData(data[key], schema[key as keyof typeof schema]);
            }
        }
    }

    if (instances.length > 0) {
        for (const key of instances) {
            if (data[key]) {
                data[key] = new Money(data[key].amount, data[key].currency);
            }
        }
    }

    return data;
}
