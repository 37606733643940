import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../rootStore";
import { ServerStats } from "../types";

interface ConsoleState {
    liveDataBuffor: string;
    stats: ServerStats[];
    bytes: number;
    historyBytes: number;
    totalBytes: number;
}

const slice = createSlice({
    name: "consoleLogs",
    initialState: {
        stats: [] as ServerStats[],
    } as ConsoleState,
    reducers: {
        addStats: (state, { payload }: PayloadAction<{ data: ServerStats }>) => {
            if (payload) {
                state.stats.push(payload.data);
            }
        },
    },
});

export const { addStats } = slice.actions;

export default slice.reducer;

export const getStatsLogs = (state: RootState) => state.consoleLogs.stats;
export const getTotalBytes = (state: RootState) => state.consoleLogs.totalBytes;
export const getHistoryBytes = (state: RootState) => state.consoleLogs.historyBytes;
