const languageKey = "craftserve-language";
const currencyKey = "craftserve-local-currency";
const themeKey = "craftserve-theme";
const UIOptionsKey = "craftserve-ui-options";
const debugEnabled = "craftserve-debug-enabled";
const lastNPSModalDate = "craftserve-nps-modal-date";
const BCP47Language = "craftserve-bcp47-language";

interface UILocalOptions {
    benchmarkChartType: "simple" | "advanced";
    gameStatsBarHeight: number;
    statsExpanded: boolean;
    processesExpanded?: boolean;
    hideExpired: boolean;
}

const initialUILocalOptions: UILocalOptions = {
    benchmarkChartType: "simple",
    gameStatsBarHeight: 300,
    statsExpanded: true,
    processesExpanded: false,
    hideExpired: false,
};

export const getDebugEnabled = () => (localStorage.getItem(debugEnabled) === "true" ? true : false);
export const getLocalLanguage = () => localStorage.getItem(languageKey) || "pl-PL";
export const getLocalCurrency = () => localStorage.getItem(currencyKey) || "PLN";
export const getLocalTheme = () => localStorage.getItem(themeKey);
export const getLastNPSModalDate = () => localStorage.getItem(lastNPSModalDate) || null;

export const setLastNPSModalDate = (date: Date) => localStorage.setItem(lastNPSModalDate, date.toISOString());
export const getUILocalOptions = () => {
    const data = localStorage.getItem(UIOptionsKey);
    if (data) {
        return JSON.parse(data) as UILocalOptions;
    }
    return initialUILocalOptions;
};

export const setDebugEnabled = (value: boolean) => localStorage.setItem(debugEnabled, value.toString());

export const setLocalLanguage = (symbol: string) => {
    localStorage.setItem(languageKey, symbol);
    window.dispatchEvent(new Event("storage"));
};

export const setLocalTheme = (symbol: string) => {
    localStorage.setItem(themeKey, symbol);
    window.dispatchEvent(new Event("storage"));
};

export const setLocalCurrency = (symbol: string) => {
    localStorage.setItem(currencyKey, symbol);
    window.dispatchEvent(new Event("storage"));
};

export const setUILocalOption = (option: keyof UILocalOptions, value: string | number | boolean) => {
    const data: UILocalOptions = getUILocalOptions();
    switch (option) {
        case "benchmarkChartType":
            data.benchmarkChartType = value as "simple" | "advanced";
            break;
        case "gameStatsBarHeight":
            data.gameStatsBarHeight = value as number;
            break;
        case "statsExpanded":
            data.statsExpanded = value as boolean;
            break;
        case "processesExpanded":
            data.processesExpanded = value as boolean;
            break;
        case "hideExpired":
            data.hideExpired = value as boolean;
            break;
    }

    localStorage.setItem(UIOptionsKey, JSON.stringify(data));
    window.dispatchEvent(new Event("storage"));
};
