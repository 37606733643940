import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import * as Sentry from "@sentry/browser";
import { SupportTypes } from ".";
import { HttpStatus } from "../../constants";
import { HostUrl } from "../../rootTypes";

export const api = createApi({
    reducerPath: "support",
    baseQuery: fetchBaseQuery({
        baseUrl: `${HostUrl}/api/support`,
        prepareHeaders: (headers) => {
            const transactionId = Math.random().toString(36).substr(2, 9);
            Sentry.configureScope((scope) => {
                scope.setTag("transaction_id", transactionId);
            });
            headers.set("X-transation-ID", transactionId);

            return headers;
        },
    }),
    endpoints: (builder) => ({
        sendReview: builder.mutation<void, SupportTypes.SendReviewPayload>({
            query: (review) => ({
                url: "/reviews",
                method: "POST",
                body: review,
                validateStatus: (response) => response.status === HttpStatus.Created,
            }),
        }),
    }),
});

export const { useSendReviewMutation } = api;
