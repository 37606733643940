import styled from "styled-components";

const LayoutSpace = styled.div`
    padding: 100px 18px;
    @media (min-width: 1000px) {
        padding: 120px 36px;
    }
`;

export default LayoutSpace;
