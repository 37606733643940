import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import Logo from "../../../assets/images/logo.png";
import { ScreensTitles } from "../../../constants";
import { ScreenTitle } from "../../../rootTypes";
import { Props } from "./types";

const locales = {
    en: "en_GB",
    pl: "pl_PL",
};

// "SEO": {
//         "description": "Najlepszy hosting serwerów Minecraft w Polsce. Twórz i zarządzaj swoim światem Minecraft z nami - szybki, niezawodny i bezpieczny hosting serwerów Minecraft. Zgarnij darmowe serwery Minecraft i ciesz się grą bez ograniczeń.",
//         "keywords": "najlepszy hosting minecraft, hosting serwerów minecraft, darmowe serwery minecraft, szybki hosting minecraft, niezawodny hosting minecraft, bezpieczny hosting minecraft, serwery minecraft, minecraft hosting"
//     },

const data = {
    description:
        "Najlepszy hosting serwerów Minecraft w Polsce. Twórz i zarządzaj swoim światem Minecraft z nami - szybki, niezawodny i bezpieczny hosting serwerów Minecraft. Zgarnij darmowe serwery Minecraft i ciesz się grą bez ograniczeń.",
    keywords:
        "najlepszy hosting minecraft, hosting serwerów minecraft, darmowe serwery minecraft, szybki hosting minecraft, niezawodny hosting minecraft, bezpieczny hosting minecraft, serwery minecraft, minecraft hosting",
    ogType: "website",
    ogImage: Logo,
    twitterCard: "summary_large_image",
    twitterImage: Logo,
};

const SEO: React.FC<Props> = ({ title, keywords, url, ogType, ogImage, twitterCard, twitterImage }) => {
    const {
        t,
        i18n: { language },
    } = useTranslation();

    return (
        <Helmet>
            <html lang={language} />
            <title>{title ? `${ScreensTitles[title as ScreenTitle]} | Craftserve` : "Craftserve"}</title>
            <link rel="icon" href="/static/favicon.ico" />
            <link rel="apple-touch-icon" href="/logo192.png" />
            <link rel="canonical" href={url} />
            <base href="/" />
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="theme-color" content="#234d20" />
            <meta name="description" content={data.description} />
            <meta name="keywords" content={keywords || data.keywords} />
            <meta property="og:title" content={title || "Craftserve"} />
            <meta property="og:description" content={data.description} />
            <meta property="og:keywords" content={keywords || data.keywords} />
            <meta property="og:type" content={ogType || "website"} />
            <meta property="og:image" content={ogImage || Logo} />
            <meta property="og:url" content={url || "https://craftserve.com"} />
            <meta property="og:locale" content={locales[language as keyof typeof locales]} />
            <meta property="twitter:title" content={title || "Craftserve"} />
            <meta property="twitter:card" content={twitterCard || "summary_large_image"} />
            <meta property="twitter:url" content={url || "https://craftserve.com"} />
            <meta property="twitter:description" content={data.description} />
            <meta property="twitter:image" content={twitterImage} />
        </Helmet>
    );
};

export default SEO;
