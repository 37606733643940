export enum ProcessState {
    IN_PROGRESS = "IN_PROGRESS",
    DONE = "DONE",
    ABORTED = "ABORTED",
    FAILED = "FAILED",
    TIMEOUT = "TIMEOUT",
}

export enum ProcessType {
    UPLOADING_FILES = "UPLOADING_FILES",
    UPLOADING_FILES_FROM_URL = "UPLOADING_FILES_FROM_URL",
    PREPARING_SERVER = "PREPARING_SERVER",
    STOPPING_GAME = "STOPPING_GAME",
    STARTING_GAME = "STARTING_GAME",
    CREATING_SERVER_POD = "CREATING_SERVER_POD",
    SERVER_BACKUP = "SERVER_BACKUP",
    SERVER_BACKUP_DELETION = "SERVER_BACKUP_DELETION",
    SERVER_RESTORE = "SERVER_RESTORE",
    SERVER_FORMAT = "SERVER_FORMAT",
    SERVER_INSTALL_PACKAGE = "SERVER_INSTALL_PACKAGE",
    SERVER_UNINSTALL_PACKAGE = "SERVER_UNINSTALL_PACKAGE",
    SERVER_MIGRATION = "SERVER_MIGRATION",
    SERVER_CLEANUP = "SERVER_CLEANUP",
    SERVER_PACKAGE_CREATE = "SERVER_PACKAGE_CREATE",

    TRANSACTION_STATUS = "TRANSACTION_STATUS",
    EMAIL_CHANGE_STATUS = "EMAIL_CHANGE_STATUS",
    PASSWORD_CHANGE_STATUS = "PASSWORD_CHANGE_STATUS",
    PASSWORD_RESET_STATUS = "PASSWORD_RESET_STATUS",
    SERVER_RENEW_STATUS = "SERVER_RENEW_STATUS",
    DOMAIN_SET_STATUS = "DOMAIN_SET_STATUS",
    PAY_FOR_SERVER_STATUS = "PAY_FOR_SERVER_STATUS",
    AUTHORIZATION_STATUS = "AUTHORIZATION_STATUS",
    CHANGE_SERVER_NAME_STATUS = "CHANGE_SERVER_NAME_STATUS",
    SERVER_CREATE_STATUS = "SERVER_CREATE_STATUS",
    VOUCHER_ADD_STATUS = "VOUCHER_ADD_STATUS",
    CREATING_FOLDER_STATUS = "CREATING_FOLDER",
    CREATING_FILE_STATUS = "CREATING_FILE",
    EXTRACTING_ARCHIVE_STATUS = "EXTRACTING_ARCHIVE",
    SERVER_CHANGE_ENGINE = "SERVER_CHANGE_ENGINE",

    MARKETPLACE_RELEASE_UPLOAD = "MARKETPLACE_RELEASE_UPLOAD",
}
export enum NotificationType {
    ACCOUNT_EMAIL_CONFIRM = "ACCOUNT_EMAIL_CONFIRM",
    ACCOUNT_RESET_PASSWORD = "ACCOUNT_RESET_PASSWORD",
    SERVER_EXPIRED = "SERVER_EXPIRED",
    SERVER_CREATED = "SERVER_CREATED",
    SERVER_RENEWED = "SERVER_RENEWED",
    SERVER_CRASHED = "SERVER_CRASHED",
    SERVER_TESTING_ENABLED = "SERVER_TESTING_ENABLED",
    SERVER_CLEANED_UP = "SERVER_CLEANED_UP",
    SERVER_CLEANUP_WARNING = "SERVER_CLEANUP_WARNING",
    SERVER_EXPIRES_WARNING = "SERVER_EXPIRES_WARNING",
    SERVER_PLAYER_JOINED = "SERVER_PLAYER_JOINED",
    SERVER_PLAYER_TRIED_TO_JOIN = "SERVER_PLAYER_TRIED_TO_JOIN",
}
