import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import * as Sentry from "@sentry/browser";
import { BackupsTypes } from ".";
import { HttpStatus } from "../../../../constants";
import { HostUrl } from "../../../../rootTypes";
import { transformError } from "../../../../shared/helpers/errors";
import { ServerID } from "../../../../shared/types";

export const api = createApi({
    reducerPath: "backups",
    baseQuery: fetchBaseQuery({
        baseUrl: `${HostUrl}/api/servers/`,
        prepareHeaders: (headers) => {
            const transactionId = Math.random().toString(36).substr(2, 9);
            Sentry.configureScope((scope) => {
                scope.setTag("transaction_id", transactionId);
            });
            headers.set("X-transation-ID", transactionId);

            return headers;
        },
    }),

    endpoints: (builder) => ({
        getBackups: builder.query<BackupsTypes.ServerBackup[], ServerID>({
            query: (serverId) => ({
                url: `${serverId}/backups`,
                method: "GET",
                credentials: "include",
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformResponse(baseQueryReturnValue: BackupsTypes.GetServerBackupsResponse) {
                return baseQueryReturnValue.data
                    .map((backup) => ({
                        ...backup,
                        created_at: new Date(backup.created_at),
                        deleted_at: backup.deleted_at ? new Date(backup.deleted_at) : null,
                    }))
                    .sort((a, b) => b.created_at.getTime() - a.created_at.getTime());
            },
            transformErrorResponse: transformError,
        }),
        runManualBackup: builder.mutation<void, ServerID>({
            query: (serverId) => ({
                url: `${serverId}/backups`,
                method: "POST",
                credentials: "include",
                validateStatus: (response) => response.status === HttpStatus.Created,
            }),
            transformErrorResponse: transformError,
        }),
        restoreBackup: builder.mutation<void, { serverId: ServerID; snapshotId: string }>({
            query: ({ serverId, snapshotId }) => ({
                url: `${serverId}/backups/${snapshotId}/restore`,
                method: "POST",
                credentials: "include",
                validateStatus: (response) => response.status === HttpStatus.Created,
            }),
            transformErrorResponse: transformError,
        }),
        deleteBackup: builder.mutation<void, { serverId: ServerID; snapshotId: string }>({
            query: ({ serverId, snapshotId }) => ({
                url: `${serverId}/backups/${snapshotId}`,
                method: "DELETE",
                credentials: "include",
                validateStatus: (response) => response.status === HttpStatus.Created,
            }),
            transformErrorResponse: transformError,
        }),
    }),
});

export const {
    useGetBackupsQuery,
    useLazyGetBackupsQuery,
    useRunManualBackupMutation,
    useDeleteBackupMutation,
    useRestoreBackupMutation,
} = api;
