import { combineReducers } from "redux";
import { adminApi } from "./modules/Admin/api";
import { BillingsApi } from "./modules/Billings";
import { craftumsApi } from "./modules/Craftums/api";
import { marketplaceApi } from "./modules/Marketplace/api";
import { PaymentsApi } from "./modules/Payments";
import {
    ServerApi,
    consoleLogsReducer,
    currentServerReducer,
    routeChangeAlertReducer,
    serverStatusReducer,
} from "./modules/Server";
import { clusterApi } from "./modules/Server/clusterApi";
import { BackupsApi } from "./modules/Server/modules/Backups";
import { FilesApi } from "./modules/Server/modules/Files";
import { api as walletsApi } from "./modules/Server/modules/Wallets/api";
import pointerToastsSlice from "./modules/Server/slices/pointerToastsSlice";
import { ServicesApi } from "./modules/Services";
import { UserApi, authReducer, messagesReducer, processesReducer } from "./modules/User";
import { supportApi } from "./modules/User/api";

export default combineReducers({
    [UserApi.api.reducerPath]: UserApi.api.reducer,
    [BillingsApi.api.reducerPath]: BillingsApi.api.reducer,
    [ServerApi.api.reducerPath]: ServerApi.api.reducer,
    [ServerApi.eventLogsApi.reducerPath]: ServerApi.eventLogsApi.reducer,
    [ServerApi.domainsApi.reducerPath]: ServerApi.domainsApi.reducer,
    [clusterApi.reducerPath]: clusterApi.reducer,
    [PaymentsApi.paymentsApi.reducerPath]: PaymentsApi.paymentsApi.reducer,
    [ServicesApi.api.reducerPath]: ServicesApi.api.reducer,
    [FilesApi.api.reducerPath]: FilesApi.api.reducer,
    [BackupsApi.api.reducerPath]: BackupsApi.api.reducer,
    [marketplaceApi.reducerPath]: marketplaceApi.reducer,
    [supportApi.reducerPath]: supportApi.reducer,
    [walletsApi.reducerPath]: walletsApi.reducer,
    [craftumsApi.reducerPath]: craftumsApi.reducer,
    [ServerApi.packagesApi.reducerPath]: ServerApi.packagesApi.reducer,
    [adminApi.reducerPath]: adminApi.reducer,
    auth: authReducer,
    currentData: currentServerReducer,
    serverStatus: serverStatusReducer,
    consoleLogs: consoleLogsReducer,
    messages: messagesReducer,
    processes: processesReducer,
    routeAlert: routeChangeAlertReducer,
    pointerToasts: pointerToastsSlice,
});
