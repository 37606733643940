import ReactFlagsSelect from "react-flags-select";
import styled, { css } from "styled-components";

export const Select = styled(ReactFlagsSelect)<{ light?: boolean }>`
    #rfs-btn {
        color: ${({ theme }) => theme.colors.primaryText};
    }

    &[data-testid="rfs"] {
        padding-bottom: 0;
    }

    .ReactFlagsSelect-module_label__27pw9 {
        display: none;
    }

    .ReactFlagsSelect-module_selectOptions__3LNBJ {
        width: 100%;
        background-color: ${({ theme }) => theme.colors.secondaryBackground};
    }

    .ReactFlagsSelect-module_selectBtn__19wW7 {
        border: 1px solid ${({ theme }) => theme.colors.gray};
        padding: 7px 10px 6px 10px;
        border-radius: 8px;

        &::after {
            ${({ light }) =>
                light &&
                css`
                    border-top-color: #fff;
                    border-bottom-color: #fff;
                `}
        }
    }

    .ReactFlagsSelect-module_selectOptionWithlabel__2GpmM {
        display: flex;
        justify-content: center;

        &:hover {
            background: ${({ theme }) => theme.colors.gray};
        }
    }
`;
