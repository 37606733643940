import { MenuItem, ContextMenu as ReactContextMenu } from "react-contextmenu";
import { Container } from "./style";

interface Props {
    id: string;
    actions: {
        value: string;
        label: string;
        onClick: (value: MouseEvent) => void;
    }[];
}

// rewrite ContextMenu to use
const ContextMenuWithChildren = ReactContextMenu as React.ElementType;
const MenuItemWithChildren = MenuItem as React.ElementType;

const ContextMenu: React.FC<Props> = ({ id, actions }) => {
    return (
        <Container>
            <ContextMenuWithChildren id={id}>
                {actions.map(({ value, label, onClick }) => {
                    return (
                        <MenuItemWithChildren key={value} data={{ value }} onClick={onClick}>
                            {label}
                        </MenuItemWithChildren>
                    );
                })}
            </ContextMenuWithChildren>
        </Container>
    );
};

export default ContextMenu;
