import { Button, Paragraph, Space, Title } from "csrvui";
import { isBefore, sub } from "date-fns";
import { usePostHog } from "posthog-js/react";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { getCurrentServer } from "../../../modules/Server/slices/currentServerSlice";
import { useSendReviewMutation } from "../../../modules/Support/api";
import { useEventsQuery } from "../../../modules/User/api";
import useUserData from "../../../modules/User/hooks/useUserData";
import { getLastNPSModalDate, setLastNPSModalDate } from "../../helpers/localStorage";
import ResponsiveModal from "../ResponsiveModal";

const ModalButton = styled.button<{ selected: boolean }>`
    font-size: 24px;
    width: 70px;
    height: 70px;
    background-color: rgba(35, 77, 32, 0.1);
    color: #234d20;
    border: 2px solid #234d20;
    padding: 20px;
    cursor: pointer;

    ${({ selected }) => selected && "background-color: #9cce4c;"}

    &:hover {
        background-color: rgba(156, 202, 76, 0.7);
    }

    @media (max-width: 1000px) {
        width: 36px;
        height: 36px;
        padding: 8px;
    }
`;

const StyledSpace = styled(Space)`
    @media (max-width: 1000px) {
        * {
            font-size: 1em !important;
        }
    }
`;

export default function NPSModal() {
    const lastNPSModalDate = getLastNPSModalDate();
    const [rate, setRate] = useState<number | null>(null);
    const [comment, setComment] = useState("");
    const [sendReview] = useSendReviewMutation();
    const { user } = useUserData();
    const currentServer = useSelector(getCurrentServer);
    const posthog = usePostHog();
    const spentEnoughtTimeToReview = useMemo(() => {
        if (!user) {
            return false;
        }

        // check if at least one user service is older than 3 days
        return user.services.some((service) =>
            isBefore(new Date(service.created_at), new Date(Date.now() - 3 * 24 * 60 * 60 * 1000))
        );
    }, [user]);
    const [modalOpen, setModalOpen] = useState(true);
    const eventsQueryParams = useMemo(
        () => ({
            id: user.id,
            after: "",
            action: "USER_RATED_APP",
            fromDate: sub(new Date(), { months: 12 }).toISOString(),
            toDate: new Date().toISOString(),
        }),
        [user]
    );

    const { data: userEvents, isLoading } = useEventsQuery(eventsQueryParams, {
        skip: !user,
    });

    return (
        <ResponsiveModal
            open={
                spentEnoughtTimeToReview &&
                !lastNPSModalDate &&
                modalOpen &&
                !isLoading &&
                !!userEvents &&
                userEvents.count === 0
            }
            center
            onClose={() => {
                setLastNPSModalDate(new Date());
                setModalOpen(false);
            }}
            animationDuration={0}
        >
            <StyledSpace $width="100%">
                <Title $level={2}>Jak oceniasz szansę na to, że poleciłbyś usługi Craftserve swojemu znajomemu?</Title>
                <Paragraph style={{ fontSize: 24 }}>
                    Dzięki Twojej opinii będziemy mogli dostosować nasze usługi do Twoich potrzeb. Jeśli chcesz, możesz
                    również zostawić komentarz.
                </Paragraph>
                <Space $direction="horizontal" $padding="20px 0" $wrap={false} $forceWrapOn={1000}>
                    {new Array(11).fill(0).map((_, index) => (
                        <ModalButton
                            key={index}
                            onClick={() => setRate(index)}
                            selected={rate !== null && rate === index}
                        >
                            {index}
                        </ModalButton>
                    ))}
                </Space>

                <Paragraph
                    style={{
                        padding: 0,
                        margin: 0,
                    }}
                >
                    Miejsce na komentarz:
                </Paragraph>
                <textarea
                    style={{
                        width: "100%",
                        height: 100,
                        padding: 10,
                        fontSize: 24,
                        margin: "0 0 20px 0",
                        border: "1px solid  #234d20",
                        borderRadius: 5,
                    }}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                ></textarea>
                <Button
                    $sizeVariant="big"
                    style={{
                        fontSize: 24,
                    }}
                    disabled={rate === null}
                    onClick={async () => {
                        if (!rate) {
                            return;
                        }

                        await sendReview({
                            rate,
                            comment: comment || null,
                            server_id: currentServer.id,
                            engine: currentServer.parameters.game?.engine || null,
                        });

                        posthog.capture("nps_review", {
                            rate,
                            comment: comment || null,
                            serverid: currentServer.id,
                            engine: currentServer.parameters.game?.engine || null,
                        });

                        setLastNPSModalDate(new Date());

                        setModalOpen(false);
                    }}
                >
                    Wyślij
                </Button>
            </StyledSpace>
        </ResponsiveModal>
    );
}
