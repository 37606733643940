import ReactLoadingSkeleton, { SkeletonTheme as ReactLoadingSkeletonTheme } from "react-loading-skeleton";
import useCustomTheme from "../../hooks/useCustomTheme";
import { StyledWrapper } from "./style";
import { Props } from "./types";

const Skeleton: React.FC<Props> = ({ ...props }) => {
    const theme = useCustomTheme();

    return (
        <ReactLoadingSkeletonTheme baseColor={theme.colors.lavender} highlightColor={theme.colors.border}>
            <StyledWrapper>
                <ReactLoadingSkeleton borderRadius={0} {...props} />
            </StyledWrapper>
        </ReactLoadingSkeletonTheme>
    );
};

export default Skeleton;
