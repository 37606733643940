import { skipToken } from "@reduxjs/toolkit/query";
import { Card, Space, Text } from "csrvui";
import { sub } from "date-fns";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import SuccessIcon from "../../assets/icons/success.svg";
import { useSearchProjectReleaseByUserQuery } from "../../modules/Marketplace/api";
import MarketplaceSearchItem from "../../modules/Marketplace/components/MarketplaceSearchItem";
import { api, useEventsQuery, useGetUserQuery, useServersQuery } from "../../modules/User/api";
import { hasUserCapability } from "../../modules/User/helpers/hasCapability";
import useUserData from "../../modules/User/hooks/useUserData";
import Layout from "../../shared/components/Layout";
import LayoutSpace from "../../shared/components/LayoutSpace";
import PanelLoader from "../../shared/components/PanelLoader";
import ServicesListItem from "../../shared/components/ServicesListItem";
import TicketsTable from "../../shared/components/TicketsTable";
import UserLogsTable from "../../shared/components/UserLogsTable";
import { getUILocalOptions } from "../../shared/helpers/localStorage";
import { UserPermissions } from "../../shared/types";
import { Grid, SmallerGrid } from "./style";

const UserAdminViewScreen: React.FC = () => {
    const { t } = useTranslation();
    const { userId } = useParams<{ userId: string }>();
    const [hideExpired] = useState(getUILocalOptions().hideExpired);

    const { data: user, isLoading } = useGetUserQuery(userId ?? skipToken, {
        skip: userId == null,
    });

    const { data: servers } = useServersQuery(userId ?? skipToken, {
        skip: !userId,
    });

    const { data: projects } = useSearchProjectReleaseByUserQuery(userId ?? skipToken, {
        skip: !userId,
    });

    const { data: tickets, isError } = api.useGetUserTicketsQuery(userId ?? skipToken, {
        skip: !userId,
    });

    const eventsQueryParams = useMemo(
        () => ({
            id: userId ?? "",
            after: "",
            action: "",
            fromDate: sub(new Date(), { months: 1 }).toISOString(),
            toDate: new Date().toISOString(),
        }),
        [userId]
    );

    const { data: events } = useEventsQuery(eventsQueryParams, {
        skip: !userId,
    });

    // przypisanie naszego admin usera by czytac jego permisje czy ma dostep.
    const { user: adminUser } = useUserData();

    return (
        <Layout>
            <LayoutSpace>
                <Space style={{ display: "flex", alignItems: "center" }}>
                    <Space
                        $padding={0}
                        $direction="horizontal"
                        $gap={36}
                        $fullWidth
                        style={{ maxWidth: "1024px", margin: "0 auto" }}
                    >
                        <PanelLoader inProp={isLoading} text={t("loading.data")} />
                        {!user && <Card title="Brak takiego użytkownika" />}
                        {user && !hasUserCapability(adminUser, UserPermissions.USERS_READ) && (
                            <Card title="Brak wymaganych permisji" />
                        )}
                        {user && hasUserCapability(adminUser, UserPermissions.USERS_READ) && (
                            <>
                                <Card
                                    title={`${t("routes.profile")} ${user.email}`}
                                    titleStyle={{ textTransform: "uppercase" }}
                                />
                                <Grid>
                                    <Card title={t("user_admin_view.service_list")}>
                                        <Space>
                                            {user && servers && (
                                                <>
                                                    <Space $padding={0} $gap={6}>
                                                        {[...servers]

                                                            .sort((a, b) => {
                                                                if (a.active && !b.active) {
                                                                    return -1;
                                                                }

                                                                if (!a.active && b.active) {
                                                                    return 1;
                                                                }

                                                                return 0;
                                                            })
                                                            .map((server) => {
                                                                if (!server) {
                                                                    return null;
                                                                }
                                                                const { id } = server;
                                                                const service = user.services.find(
                                                                    (service) => service.service_id === id
                                                                );
                                                                if (!service) {
                                                                    return null;
                                                                }

                                                                if (hideExpired && !service.expires) {
                                                                    return null;
                                                                }

                                                                return (
                                                                    <ServicesListItem
                                                                        service={service}
                                                                        server={server}
                                                                        key={server.id}
                                                                        craftums={[]}
                                                                        minimalView={true}
                                                                    />
                                                                );
                                                            })}
                                                    </Space>
                                                </>
                                            )}
                                        </Space>
                                    </Card>

                                    <Card title={t("user_admin_view.additional_information")}>
                                        <Space>
                                            {user?.newsletter ? (
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <img
                                                        width={24}
                                                        src={SuccessIcon}
                                                        alt=""
                                                        style={{ marginRight: "6px" }}
                                                    />
                                                    <Text style={{ marginRight: "6px" }}>
                                                        {t("user_admin_view.newsletter_confirmed")}
                                                    </Text>
                                                    <Text>{new Date(user.newsletter).toLocaleDateString("pl-PL")}</Text>
                                                </div>
                                            ) : null}

                                            {/* {adminUser.user_capabilities.map((capability) => (
                                                <div
                                                    key={capability.resource_id}
                                                    style={{ display: "flex", alignItems: "center" }}
                                                >
                                                    <img
                                                        width={24}
                                                        src={SuccessIcon}
                                                        alt=""
                                                        style={{ marginRight: "6px" }}
                                                    />
                                                    <Text>{capability.resource_id || "admin"}</Text>
                                                    {Object.entries(capability.capabilities).map(([key, value]) => (
                                                        <Text key={key} style={{ marginRight: "6px" }}>
                                                            {key}: {value.toString()}
                                                        </Text>
                                                    ))}
                                                </div>
                                            ))} */}

                                            {!user?.newsletter && adminUser.user_capabilities.length === 0 && (
                                                <Text>{t("user_admin_view.no_added_information")}</Text>
                                            )}
                                        </Space>
                                    </Card>

                                    <Card title={t("user_admin_view.created_packs")}>
                                        {projects ? (
                                            <SmallerGrid itemCount={projects.length}>
                                                {projects.map((item) => (
                                                    <MarketplaceSearchItem
                                                        data={item}
                                                        key={`searched-${item.name}`}
                                                        to={`/m/${item.slug}`}
                                                        disabled={false}
                                                        isUserAdminView={true}
                                                    />
                                                ))}
                                            </SmallerGrid>
                                        ) : (
                                            <Text>{t("user_admin_view.no_projects")}</Text>
                                        )}
                                    </Card>

                                    <Card title={t("settings_account.active_tickets_zendesk")}>
                                        <Space $gap={10}>
                                            <Space $padding="0.5rem 0" $overflowX="scroll" $height="300px">
                                                <TicketsTable
                                                    tickets={tickets}
                                                    isLoading={isLoading}
                                                    isError={isError}
                                                    isUserView={true}
                                                />
                                            </Space>
                                        </Space>
                                    </Card>

                                    <Card title={t("user_admin_view.account_logs")}>
                                        <Space $gap={10}>
                                            <Space $padding="0.5rem 0" $overflowX="scroll" $height="300px">
                                                <UserLogsTable
                                                    events={events}
                                                    isLoading={isLoading}
                                                    isError={isError}
                                                />
                                            </Space>
                                        </Space>
                                    </Card>
                                </Grid>
                            </>
                        )}
                    </Space>
                </Space>
            </LayoutSpace>
        </Layout>
    );
};

export default UserAdminViewScreen;
