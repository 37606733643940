import { differenceInDays, formatDistance, formatDuration } from "date-fns";
import dateFNSFormat from "date-fns/format";
import { enUS, pl as plPL } from "date-fns/locale";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { getLocalLanguage } from "../shared/helpers/localStorage";
import en from "./en.json";
import pl from "./pl.json";

const resources = { en, pl };

const getLocale = (language) => {
    switch (language) {
        case "en-GB":
            return enUS;
        case "pl-PL":
            return plPL;
        default:
            return plPL;
    }
};

const storedLang = getLocalLanguage();

// eslint-disable-next-line import/no-named-as-default-member
i18next
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng: storedLang || "en-GB",
        resources,
        keySeparator: false,
        nsSeparator: ".",
        interpolation: {
            format: function (value, fmt, lng) {
                if (!value || value === "" || value === undefined || value === null) {
                    return "";
                }

                const [type, mask] = fmt.split("|");
                switch (type) {
                    case "date":
                        return dateFNSFormat(value, mask, { locale: getLocale(lng) });
                    case "duration":
                        if (mask === "days_hours") {
                            return formatDuration(value, {
                                locale: getLocale(lng),
                                format: ["years", "months", "days"],
                            });
                        }
                        return formatDuration(value, { locale: getLocale(lng) });
                    case "distance":
                        return formatDistance(value, Date.now(), {
                            locale: getLocale(lng),
                        });
                    case "days":
                        const difference = differenceInDays(value, Date.now());
                        if (difference > 1) {
                            return `${difference} dni`;
                        }
                        return "1 dzień";
                    default:
                        return value;
                }
            },
        },
        storedLang,
    });
export default i18next;
