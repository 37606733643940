import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetServerByDomainMutation } from "../../modules/Server/api";
import { CheckAvailabilityDomainPayload } from "../../modules/Server/types";
import { ZoneName } from "../../rootTypes";

const useCurrentServerIdentitites = (): { serverIdentity: string | undefined; serverId: string | undefined } => {
    const { serverIdentity } = useParams<{ serverIdentity: string }>();
    const [serverId, setServerId] = useState<string | undefined>(undefined);
    const [getServerByDomain] = useGetServerByDomainMutation();

    useEffect(() => {
        const checkParam = async () => {
            if (!serverIdentity) {
                return;
            }

            try {
                const payload: CheckAvailabilityDomainPayload = {
                    subdomain: serverIdentity,
                    zone_name: ZoneName,
                };
                const response = await getServerByDomain(payload).unwrap();
                if (response && response.server_id) {
                    setServerId(response.server_id);
                } else {
                    throw new Error("Server ID not found in response");
                }
            } catch (err) {
                console.error("Error caught:", err);
                setServerId(serverIdentity);
            }
        };

        checkParam();
    }, [serverIdentity, getServerByDomain]);

    return { serverIdentity, serverId };
};

export default useCurrentServerIdentitites;
