import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import * as Sentry from "@sentry/browser";
import { PaymentsTypes } from ".";
import { HttpStatus } from "../../constants";
import { HostUrl } from "../../rootTypes";
import { transformToStruct } from "../../shared/helpers/customQuery";
import { transformError } from "../../shared/helpers/errors";
import { CompletePaymentResponse } from "./types";

export const paymentsApi = createApi({
    reducerPath: "payments",
    baseQuery: fetchBaseQuery({
        baseUrl: `${HostUrl}/api/payments`,
        prepareHeaders: (headers) => {
            const transactionId = Math.random().toString(36).substr(2, 9);
            Sentry.configureScope((scope) => {
                scope.setTag("transaction_id", transactionId);
            });
            headers.set("X-transation-ID", transactionId);

            return headers;
        },
    }),
    endpoints: (builder) => ({
        getTransaction: builder.query<PaymentsTypes.PublicTransaction, string>({
            query: (transactionId) => ({
                url: `/transaction/${transactionId}`,
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformResponse: (response: PaymentsTypes.PublicTransaction) => {
                return transformToStruct(response, PaymentsTypes.PublicTransaction, "/transaction/:id");
            },
            transformErrorResponse: transformError,
        }),
        createTransaction: builder.mutation<PaymentsTypes.TransactionResponse, PaymentsTypes.CreateTransactionPayload>({
            query: (payload) => ({
                url: "/transaction",
                method: "POST",
                body: payload,
                validateStatus: (response) => response.status === HttpStatus.Created,
            }),
            transformResponse: (response: PaymentsTypes.TransactionResponse) => {
                return transformToStruct(response, PaymentsTypes.TransactionResponse, "/transaction");
            },
            transformErrorResponse: transformError,
        }),
        updateTransaction: builder.mutation<
            PaymentsTypes.UpdateTransactionResponse,
            {
                transaction_id: string;
                payload: PaymentsTypes.UpdateTransactionPayload;
            }
        >({
            query: ({ transaction_id, payload }) => ({
                url: `/transaction/${transaction_id}`,
                method: "PUT",
                body: payload,
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformResponse: (response: PaymentsTypes.UpdateTransactionResponse) => {
                return transformToStruct(response, PaymentsTypes.TransactionResponse, "/transaction/:id");
            },
            transformErrorResponse: transformError,
        }),
        completePaypal: builder.mutation<CompletePaymentResponse, string>({
            query: (orderId) => ({
                url: "/paypal/complete",
                method: "POST",
                body: { order_id: orderId },
                validateStatus: (response) => response.status === HttpStatus.Created,
            }),
            transformErrorResponse: transformError,
        }),
    }),
});

export const {
    useCreateTransactionMutation,
    useUpdateTransactionMutation,
    useCompletePaypalMutation,
    useLazyGetTransactionQuery,
} = paymentsApi;
