import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Process } from "csrvprocess/dist/types";
import { RootState } from "../../../rootStore";
import { ProcessID } from "../../../shared/types";

const deletedProcesses: ProcessID[] = [];

const slice = createSlice({
    name: "processes",
    initialState: [] as Process[],
    reducers: {
        deleteProcess: (state, { payload: { processId } }: PayloadAction<{ processId: ProcessID }>) => {
            deletedProcesses.push(processId);

            const index = state.findIndex((p) => p.id === processId);
            if (index !== -1) {
                state.splice(index, 1); // Usuń proces ze stanu
            }
        },
        onProcessesChange: (state, { payload: { processes } }: PayloadAction<{ processes: Process[] }>) => {
            const filteredProcesses = processes.filter((process) => !deletedProcesses.includes(process.id));

            return filteredProcesses;
        },
    },
});

export const { deleteProcess, onProcessesChange } = slice.actions;

export default slice.reducer;

export const getProcesses = (state: RootState) => state.processes;
