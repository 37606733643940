import { Theme } from "../../theme";
import { TransactionStatus } from "../types";

const getTransactionStatusColor = (status: TransactionStatus, theme: Theme) => {
    switch (status) {
        case TransactionStatus.CANCELLED:
            return theme.colors.danger;
        case TransactionStatus.NEW:
            return theme.colors.warning;
        case TransactionStatus.COMPLETED:
            return theme.colors.success;
        case TransactionStatus.ADMIN_ACTION:
            return theme.colors.admin;
        default:
            return theme.colors.gray;
    }
};

export default getTransactionStatusColor;
