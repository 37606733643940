import styled from "styled-components";

export const SearchResultWrapper = styled.div`
    background-color: ${({ theme }) => theme.colors.primaryBackground};
    border: 1px solid ${({ theme }) => theme.colors.lavender};
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    width: 100%;
`;

export const List = styled.ul`
    margin-top: 10px;
    list-style: none;
    padding: 0;
`;

export const ListItem = styled.li`
    display: flex;
    gap: 10px;
    align-items: center;
    text-transform: uppercase;
`;
