import styled from "styled-components";

export const StyledFooter = styled.footer`
    background-color: ${({ theme }) => theme.colors.primary};
    bottom: 0;

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
`;

export const SubFooter = styled.div`
    background-color: ${({ theme }) => theme.colors.primary};

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
`;
