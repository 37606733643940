import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../rootStore";
import { UserPermissions } from "../../../shared/types";
import { hasUserCapability } from "../helpers/hasCapability";
import { AuthReducer, PublicUser } from "../types";

const slice = createSlice({
    name: "auth",
    initialState: { user: null, verified: false, error: null, isAdmin: false } as AuthReducer,
    reducers: {
        setAuth: (state, { payload: { user, error } }: PayloadAction<AuthReducer>) => {
            state.verified = true;
            if (user) {
                state.user = user;
                state.error = null;
                state.isAdmin = hasUserCapability(user, UserPermissions.SERVERS_MANAGE);
            } else {
                state.error = error;
                state.user = null;
                state.isAdmin = false;
            }
        },
    },
});

export const { setAuth } = slice.actions;

export default slice.reducer;

export const selectCurrentUser = (state: RootState) => state.auth.user as PublicUser;
export const wasUserVerified = (state: RootState) => state.auth.verified;
export const isUserLoggedIn = (state: RootState) => state.auth.verified && !!state.auth.user;
export const isUserAdmin = (state: RootState) => state.auth.isAdmin;

export const userPermissions = createSelector(
    (state: RootState) => state.auth.user,
    (user) => (user ? user.user_capabilities : [])
);
