import { Important, Space } from "csrvui";
import React, { useEffect } from "react";
import { AiOutlineLoading } from "react-icons/ai";
import { BiCheck } from "react-icons/bi";
import { IoMdClose } from "react-icons/io";
import styled from "styled-components";
import useTheme from "../../../../../../../../../../shared/hooks/useCustomTheme";
import { PointerToast, PointerToastType } from "../../../../../../../../types";

const layerStyles: React.CSSProperties = {
    position: "fixed",
    pointerEvents: "none",
    zIndex: 100,
    left: 0,
    top: 0,
    fontWeight: 500,
};

type Offset = {
    x: number;
    y: number;
};

const getItemStyles = (offset: Offset | null) => {
    if (!offset) {
        return {
            display: "none",
        };
    }

    const { x, y } = offset;
    const transform = `translate(${x + 5}px, ${y + 10}px)`;
    return {
        transform,
        WebkitTransform: transform,
    };
};

const RotatingElement = styled.div`
    display: inline-block;
    animation: spin 2s linear infinite;
    color: #333;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

const PointerInfo: React.FC<{
    info: PointerToast[];
}> = ({ info }) => {
    const [offset, setOffset] = React.useState<Offset | null>(null);
    const theme = useTheme();

    useEffect(() => {
        const handleMouseMove = (event: MouseEvent) => {
            setOffset({ x: event.clientX, y: event.clientY });
        };

        window.addEventListener("mousemove", handleMouseMove);

        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
        };
    }, []);

    const getIcon = (type: PointerToastType) => {
        switch (type) {
            case "loading":
                return (
                    <RotatingElement>
                        <AiOutlineLoading size={24} />
                    </RotatingElement>
                );
            case "success":
                return (
                    <div>
                        <BiCheck size={24} color={theme.colors.success} />
                    </div>
                );
            case "error":
                return (
                    <div>
                        <IoMdClose size={24} color={theme.colors.danger} />
                    </div>
                );
        }
    };

    if (info.length === 0) {
        return;
    }

    return (
        <div style={layerStyles}>
            <div style={getItemStyles(offset)}>
                <div
                    style={{
                        padding: "16px",
                        display: "flex",
                        flexDirection: "column",
                        gap: 5,
                    }}
                >
                    {info.map((item, index) => (
                        <Space
                            $padding="10px 20px"
                            key={item.id}
                            $direction="horizontal"
                            $oppositeAlign="center"
                            style={{
                                backgroundColor: "#fff",
                            }}
                        >
                            {getIcon(item.type)}
                            <Important $level={4}>{item.text}</Important>
                        </Space>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PointerInfo;
