import { Infer, array, date, nullable, number, object, string } from "superstruct";
import { ServerID } from "../../../../shared/types";

export interface GetServerBackupsRequestPayload {
    serverId: ServerID;
}

export interface GetServerBackupsResponse {
    data: ServerBackup[];
}

export const ServerBackupDetails = object({
    dirs_new: number(),
    files_new: number(),
    data_added: number(),
    data_blobs: number(),
    tree_blobs: number(),
    snapshot_id: string(),
    dirs_changed: number(),
    message_type: string(),
    files_changed: number(),
    total_duration: number(),
    dirs_unmodified: number(),
    files_unmodified: number(),
    total_bytes_processed: number(),
    total_files_processed: number(),
});

export type ServerBackupDetails = Infer<typeof ServerBackupDetails>;

export const ServerBackup = object({
    id: string(),
    repo_id: string(),
    service_id: string(),
    created_at: date(),
    deleted_at: nullable(date()),
    tags: array(string()),
    details: ServerBackupDetails,
});
export type ServerBackup = Infer<typeof ServerBackup>;

export interface State {
    data: ServerBackup[];
}
