import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Money } from "ts-money";
import { ServerTypes } from "..";
import { RootState } from "../../../rootStore";
import { BillingsTypes } from "../../Billings";
import { MarketProject } from "../../Marketplace/types";
import { UserTypes } from "../../User";

interface CurrentServerSliceState {
    server: ServerTypes.PublicServer | null;
    service: BillingsTypes.BillingService | null;
    serviceCost: Money | null;
    wallet: UserTypes.Wallet | null;
    installedPackages: {
        data: Record<
            string,
            {
                version: string;
                hash: string;
                provides: string[];
                labels: Record<string, string>;
            }
        >;
        primaryEngine: string;
        loading: boolean;
    };
    installedApps: {
        data: MarketProject[];
        loading: boolean;
    };
    loading: boolean;
    error: string | null;
}

const currentDataSlice = createSlice({
    name: "currentData",
    initialState: {
        server: null,
        service: null,
        serviceCost: null,
        wallet: null,
        installedPackages: {
            data: {},
            primaryEngine: "",
            loading: true,
        },
        installedApps: {
            data: [],
            loading: true,
        },
        loading: true,
        error: null,
    } as CurrentServerSliceState,
    reducers: {
        setCurrentData: (
            state,
            { payload: { server, service, serviceCost, wallet, error } }: PayloadAction<CurrentServerSliceState>
        ) => {
            state.loading = false;
            if (server) {
                state.server = server;
                state.service = service;
                state.serviceCost = serviceCost;
                state.wallet = wallet;
                state.error = null;
            } else {
                state.server = null;
                state.service = null;
                state.serviceCost = null;
                state.wallet = null;
                state.error = error;
            }
        },
        setInstalledPackages: (
            state,
            {
                payload: { installed, primaryEngine },
            }: PayloadAction<{
                installed: Record<
                    string,
                    {
                        version: string;
                        hash: string;
                        provides: string[];
                        labels: Record<string, string>;
                    }
                >;
                primaryEngine: string;
            }>
        ) => {
            state.installedPackages = {
                primaryEngine,
                data: installed,
                loading: false,
            };
        },
        setInstalledApps: (state, { payload }: PayloadAction<MarketProject[]>) => {
            state.installedApps = {
                data: payload,
                loading: false,
            };
        },
        setInstalledPackagesLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.installedPackages.loading = payload;
        },
    },
});

export const { setCurrentData, setInstalledPackages, setInstalledPackagesLoading, setInstalledApps } =
    currentDataSlice.actions;

export default currentDataSlice.reducer;

export const getCurrentServer = (state: RootState) => state.currentData.server as ServerTypes.PublicServer;
export const getCurrentService = (state: RootState) => state.currentData.service as BillingsTypes.BillingService;
export const getCurrentServiceCost = (state: RootState) => state.currentData.serviceCost as Money;
export const getCurrentWallet = (state: RootState) => state.currentData.wallet as UserTypes.Wallet;
export const getPrimaryEngine = (state: RootState) => state.currentData.installedPackages.primaryEngine as string;
export const getInstalledPackages = (state: RootState) => state.currentData.installedPackages.data;
export const getInstalledApps = (state: RootState) => state.currentData.installedApps.data as MarketProject[];
export const isInstalledPackagesLoading = (state: RootState) => state.currentData.installedPackages.loading as boolean;
export const isInstalledAppsLoading = (state: RootState) => state.currentData.installedApps.loading as boolean;
