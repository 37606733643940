import * as Sentry from "@sentry/browser";
import "react-alice-carousel/lib/alice-carousel.css";
import "react-datepicker/dist/react-datepicker.css";
import ReactDOM from "react-dom/client";
import "react-loading-skeleton/dist/skeleton.css";
import "react-responsive-modal/styles.css";
import "react-toastify/dist/ReactToastify.min.css";
import "react-virtualized/styles.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

if (window.location.hostname !== "localhost" && window.location.hostname !== "craftserve.ovh") {
    Sentry.init({
        dsn: "https://24cb8aa70c0d455ea1d76259685fb6ea@glitchtip.craftserve.com/5",
        integrations: [new Sentry.BrowserTracing({})],
        tracesSampleRate: 1.0,
        environment: "production",
    });
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(<App />);

reportWebVitals();
