import { Tag } from "csrvui";
import styled, { css } from "styled-components";

export const standardUITransition = css`
    transition: all 0.45s ease;
`;

export const CheckableWrapper = styled.div<{
    $checked: boolean;
    $disabled?: boolean;
    $sizeVariant?: "normal" | "small";
}>`
    ${standardUITransition}

    text-align: center;
    cursor: pointer;
    height: fit-content;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.primaryBackground};

    ${({ $checked }) =>
        $checked &&
        css`
            background-color: #316c2d10;
            border: 1px solid ${({ theme }) => theme.colors.primary};

            label {
                color: #000;
            }
        `}

    ${({ $disabled }) =>
        $disabled &&
        css`
            cursor: not-allowed !important;
            opacity: 0.5;
        `}

    label {
        cursor: pointer;
        padding: ${({ $sizeVariant }) => ($sizeVariant === "small" ? "6px" : "10px")};
        font-size: ${({ $sizeVariant }) => ($sizeVariant === "small" ? "0.9rem" : "1rem")};
        ${({ $disabled }) =>
            $disabled &&
            css`
                cursor: not-allowed !important;
            `}
    }
`;

export const HiddenInput = styled.input`
    display: none;
`;

export const StyledTag = styled(Tag)<{ fullWidth?: boolean }>`
    background-color: ${({ theme }) => theme.colors.success};
    color: ${({ theme }) => theme.colors.secondaryText};
    font-size: 18px;
    font-weight: 500;
    width: ${({ fullWidth }) => (fullWidth ? "100%" : "fit-content !important")};
    padding: ${({ fullWidth }) => (fullWidth ? "10px 15px" : "4px 10px")};
    gap: ${({ fullWidth }) => (fullWidth ? "12px" : "5px")};

    & svg {
        color: ${({ theme }) => theme.colors.secondaryText};
    }
`;
