import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "../../../../hooks";
import PanelLoader from "../../../../shared/components/PanelLoader";
import useGRPCProcessesContext from "../../../../shared/hooks/useGRPCProcessesContext";
import useVerify from "../../hooks/useVerify";
import { wasUserVerified } from "../../slices/authSlice";
import { Props } from "./types";

const ProtectedRoute: React.FC<Props> = ({ children }) => {
    const location = useLocation();
    const { t } = useTranslation();
    const { isLoading, isLoggedIn, verifyUser } = useVerify();
    const isVerified = useAppSelector(wasUserVerified);
    const { isConnected, connect } = useGRPCProcessesContext();

    useEffect(() => {
        if (!isConnected && isLoggedIn) {
            connect();
        }
    }, [connect, isConnected, isLoggedIn]);

    useEffect(() => {
        if (!isVerified) {
            verifyUser();
        }
    }, [isVerified, verifyUser]);

    if (!isLoading && !isLoggedIn) {
        return <Navigate to="/login" replace state={{ from: `${location.pathname}${location.search}` }} />;
    }

    return (
        <>
            <PanelLoader text={t("loading.authorizing")} inProp={isLoading} />
            {isLoggedIn && children}
        </>
    );
};

export default ProtectedRoute;
