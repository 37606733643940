import { Space } from "csrvui";
import { AdminTypes } from "../..";
import ServicesListItem from "../../../../shared/components/ServicesListItem";
import UserResult from "../UserResult";
import { TransactionResult } from "../TransactionResult";
import DomainResult from "../DomainResult";
import useTheme from "../../../../shared/hooks/useCustomTheme";
import Skeleton from "../../../../shared/components/Skeleton";
import { useTranslation } from "react-i18next";

interface Props {
    results: AdminTypes.AdminSearchResponse | null;
    isLoading?: boolean;
}

const AdminSearchResults = ({ results, isLoading }: Props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    if (isLoading || !results) {
        return (
            <Space $padding={0}>
                <Space
                    style={{ borderTop: `1px solid ${theme.colors.lavender}`, marginTop: "10px" }}
                    $padding="10px 0 0 0"
                >
                    {t("admin_screen.search_results", {
                        domains: 0,
                        servers: 0,
                        transactions: 0,
                        users: 0,
                    })}
                </Space>
                <Space $padding={0} style={{ borderRadius: "8px", overflow: "hidden" }}>
                    {isLoading && <Skeleton height={70} style={{ margin: 0 }} />}
                </Space>
            </Space>
        );
    }

    const { domains, servers, transactions, users } = results;

    return (
        <Space $padding={0}>
            <Space style={{ borderTop: `1px solid ${theme.colors.lavender}`, marginTop: "10px" }} $padding="10px 0 0 0">
                {t("admin_screen.search_results", {
                    domains: domains.length,
                    servers: servers.length,
                    transactions: transactions.length,
                    users: users.length,
                })}
            </Space>
            {domains.length > 0 && (
                <Space $padding={0}>
                    {domains.map((domain) => (
                        <DomainResult domain={domain} key={domain.id} />
                    ))}
                </Space>
            )}
            {servers.length > 0 && (
                <Space $padding={0}>
                    {servers.map((server) => (
                        <ServicesListItem ignoreIsExpired server={server} key={server.id} />
                    ))}
                </Space>
            )}
            {transactions.length > 0 && (
                <Space $padding={0}>
                    {transactions.map((transaction) => (
                        <TransactionResult transaction={transaction} key={transaction.id} />
                    ))}
                </Space>
            )}
            {users.length > 0 && (
                <Space $padding={0}>
                    {users.map((user) => (
                        <UserResult user={user} key={user.id} />
                    ))}
                </Space>
            )}
        </Space>
    );
};

export default AdminSearchResults;
