import { Button, Image, Space, Title } from "csrvui";
import styled, { css } from "styled-components";
import { Props } from "./types";

export const StyledLogo = styled(Image)`
    width: 150px;
    height: auto;
    @media (min-width: ${({ theme }) => theme.mobileBreakpoint}) {
        width: 200px;
    }
`;

export const HeaderLinks = styled.div`
    display: block;
    @media screen and (max-width: ${({ theme }) => theme.mobileBreakpoint}) {
        display: none;
    }
`;

export const MobileRoutes = styled.div`
    display: none;
    @media screen and (max-width: ${({ theme }) => theme.mobileBreakpoint}) {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    gap: 5px;

    & > a {
        width: 100%;
        background-color: ${({ theme }) => theme.colors.secondary}5A;
        border-radius: 4px;
    }
`;

export const StyledLogoHeading = styled(Title)`
    margin: 0;
    margin-right: 20px;
`;

export const StyledHeader = styled.header`
    position: fixed;
    width: 100%;
    z-index: 4;
    background-color: ${({ theme }) => theme.colors.primary};
    height: auto;
`;

export const StyledHeaderReferences = styled(Space)<Props>`
    display: none;
    flex-direction: row;
    @media screen and (max-width: 1100px) {
        ${({ $isMenuOpened }) =>
            $isMenuOpened &&
            css`
                background-color: ${({ theme }) => theme.colors.primary};
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                padding: 16px 10px;
                gap: 20px;
                display: flex;
                justify-content: start;
                align-items: center;
            `}
    }
    @media screen and (min-width: 1100px) {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        gap: 20px;
        padding: 0;
        margin-left: auto;
    }
`;

export const StyledHeaderReference = styled.div`
    display: block;
    @media screen and (min-width: 1100px) {
        &.menu-toggler {
            display: none;
        }
    }
`;

export const DropdownContainer = styled.div`
    position: relative;
    display: inline-block;
`;

export const DropdownButton = styled(Button)`
    font-size: 18px;
    padding: 10px;
    border: 1px solid white;
`;

export const DropdownContent = styled.div<{ isOpen: boolean; overflowRight?: boolean }>`
    display: ${({ isOpen }) => (isOpen ? "block" : "none")};
    position: absolute;
    background-color: white;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border: 1px solid #ccc;
    border-radius: 3px;
    margin-top: 8px;

    ${({ overflowRight }) =>
        overflowRight
            ? `
        right: 0;
        left: auto;
    `
            : `
        left: 0;
        right: auto;
    `}
`;

export const DropdownItem = styled(Button)`
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    width: 100%;
    text-align: left;
    border: none;
    background: none;
    cursor: pointer;
    &:hover {
        background-color: #ddd;
    }
`;
