import {
    Infer,
    any,
    array,
    boolean,
    date,
    instance,
    nullable,
    number,
    object,
    optional,
    record,
    string,
} from "superstruct";

import { CsrvProcessMessage } from "csrvprocess";
import { Money } from "ts-money";
import { CountryTaxRate, CreatedAt, EventLog, FetchingStatus, ProcessID, UserID } from "../../shared/types";
import { BillingService } from "../Billings/types";
import { VoucherUseWithVoucher } from "../Server/types";
import { ProcessState, ProcessType } from "./constants";

export interface State {
    sessionVerified?: boolean;
    user?: PublicUser | null;
    errorMessage?: string | null;
    wallets: {
        data: Wallet[];
        status: FetchingStatus;
        error: string | null;
    };
    notifications: {
        data: {
            notifications: Notification[];
            count: number;
        };
        status: FetchingStatus;
        error: string | null;
    };
    processes: CsrvProcessMessage[];
}

export interface CheckAvailabilityUsernamePayload {
    nickname: string;
}

export const Notification = object({
    id: string(),
    users: array(string()),
    type: string(),
    status: string(),
    campaign: nullable(string()),
    version: nullable(string()),
    fields: record(string(), any()),
    receivers: array(string()),
    actions: nullable(object({})),
    action_expires: nullable(date()),
    created_at: date(),
    opened_at: nullable(date()),
});

export type Notification = Infer<typeof Notification>;
export interface AuthReducer {
    verified: boolean;
    user: PublicUser | null;
    isLoading: boolean;
    error: string | null;
    isAdmin: boolean;
}

const TaxData = object({
    name: optional(string()),
    short_name: nullable(string()),
    nip: optional(string()),
    address: optional(string()),
    postal_code: optional(string()),
    city: optional(string()),
    country: optional(string()),
    updated_at: nullable(date()),
    language: optional(string()),
    tax_id: nullable(string()),
});

export const PublicUser = object({
    id: UserID,
    username: string(),
    email: string(),
    language: string(),
    created_at: date(),
    destroyed_at: nullable(date()),
    zendesk_id: nullable(number()),
    domains_user_id: nullable(number()),
    accepted_cookie_policy: boolean(),
    gdpr_consent: boolean(),
    accepted_tos: boolean(),
    newsletter: nullable(date()),
    email_verified: nullable(date()),
    user_capabilities: record(string(), any()),
    unread_notifications: number(),
    services: array(BillingService),
    voucher_uses: array(VoucherUseWithVoucher),
    tax_data: nullable(TaxData),
});

export type PublicUser = Infer<typeof PublicUser>;

export const UserNotificationsResponse = object({
    notifications: array(Notification),
    count: number(),
});
export type UserNotificationsResponse = Infer<typeof UserNotificationsResponse>;

export const ServerEventsResponse = object({
    data: array(EventLog),
    count: number(),
});
export type ServerEventsResponse = Infer<typeof ServerEventsResponse>;
export interface LocalLoginPayload {
    email: string;
    password: string;
}

export const ServerStatusResponse = object({
    game_status: nullable(string()),
    pod_name: nullable(string()),
    hypnos_version: nullable(string()),
    pod_image: nullable(string()),
});

export type ServerStatusResponse = Infer<typeof ServerStatusResponse>;
export interface RegisterPayload {
    email: string;
    password: string;
    language: string;
    newsletter: boolean;
    currency: string;
}

export interface Log {
    id: number;
    user_id: UserID;
    created_at: CreatedAt;
    action: string;
    data: object;
    success: boolean;
}

export interface NotificationsResponse {
    notifications: Notification[];
    count: number;
    after: string | null;
}

export const Wallet = object({
    id: string(),
    currency: string(),
    balance: instance(Money),
    details: object({
        user_id: optional(string()),
    }),
    created_at: date(),
    country_tax_rate: CountryTaxRate,
});

export type Wallet = Infer<typeof Wallet>;

export const WalletServer = object({
    id: string(),
    name: string(),
});

export type WalletServer = Infer<typeof WalletServer>;

export const WalletWithServers = object({
    created_at: nullable(date()),
    wallet: Wallet,
    servers: array(WalletServer),
});

export type WalletWithServers = Infer<typeof WalletWithServers>;
export interface ChangeEmailPayload {
    email: string;
    password: string;
}
export interface InvoiceFormPayload {
    name: string;
    short_name?: string;
    address: string;
    country: string;
    city: string;
    postal_code: string;
    tax_id?: string;
    language: string;
    email: string;
}

export interface ChangePasswordPayload {
    oldPassword: string;
    newPassword: string;
}

export interface Process {
    id: ProcessID;
    type: ProcessType;
    state: ProcessState;
    label: string;
    resourceId: string;
    progress?: number;
    data?: Record<string, unknown>;
}

export interface ProcessesState {
    processes: Process[];
}

export interface EventsQueryData {
    id: string;
    action?: string;
    fromDate?: string;
    toDate?: string;
    after?: string;
}

export interface UserNotificationsQueryData {
    id: string;
    type?: string;
    after?: string;
}

export interface PackagesSearchQueryData {
    name: string;
    tag: string;
    labels: {
        [key: string]: string;
    };
}

type ProcessExecutionType = string;
type ProcessResourceType = string;

export interface CreateProcessRemotelyPayload {
    process_type: ProcessType;
    execution_type: ProcessExecutionType;
    data: Record<string, unknown>;
    remote_id?: string;
    resource_id: string;
    resource_type: ProcessResourceType;
    cancellable: boolean;
    deadline: string;
    timeout: string;
}

const FastAuthServer = object({
    cluster_id: string(),
    game: string(),
    requested_billing_services: array(object({})),
    billing_period: string(),
    currency: string(),
});

const FastAuthBeginPayload = object({
    email: string(),
    language: string(),
    server: optional(FastAuthServer),
});

export type FastAuthBeginPayload = Infer<typeof FastAuthBeginPayload>;

const FastAuthCompletePayload = object({
    code: string(),
    terms: boolean(),
    language: string(),
    currency: string(),
    newsletter: boolean(),
});

export type FastAuthCompletePayload = Infer<typeof FastAuthCompletePayload>;

const FastAuthCompleteResponse = object({
    user: PublicUser,
    server: object({
        id: string(),
        name: string(),
    }),
    voucher: string(),
});

export type FastAuthCompleteResponse = Infer<typeof FastAuthCompleteResponse>;
