import { ProcessState } from "csrvfs";
import { Process } from "csrvprocess/dist/types";
import { InternalLink, Paragraph, Space } from "csrvui";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { MdClose, MdOutlineClose, MdOutlineDone } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import LoadingIcon from "../../../../../assets/icons/loading.svg";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import useProcessAdapt from "../../../../../modules/User/hooks/useProcessAdapt";
import useUserData from "../../../../../modules/User/hooks/useUserData";
import { removeMessage } from "../../../../../modules/User/slices/messagesSlice";
import { deleteProcess, getProcesses } from "../../../../../modules/User/slices/processesSlice";
import useCurrentServerIdentitites from "../../../../hooks/useCurrentServerIdentitites";

const LoaderIcon = styled.img`
    animation: spin 1s linear infinite;

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(359deg);
        }
    }
`;

const StyledLi = styled.li<{ state: ProcessState; progress?: number }>`
    padding: 0 16px;
    margin-top: 3px;
    cursor: pointer;
    width: 300px;
    ${({ state, progress }) => {
        switch (state) {
            case ProcessState.DONE:
                return `
                    background-color: #28A74515 !important;
                    border-bottom: 4px solid #28A745;
                `;
            case ProcessState.FAILED:
            case ProcessState.ABORTED:
            case ProcessState.TIMEOUT:
                return `
                    background-color: #D60B0015 !important;
                    border-bottom: 4px solid #ff4d4f;
                `;
            case ProcessState.IN_PROGRESS:
                return `
                    background-color: #007BFF15 !important;
                    position: relative;
                    &:after {
                        content: "";
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        height: 4px;
                        width: ${progress ? progress : 0}%;
                        background-color: #1890ff;
                    }
                `;
            default:
                return "";
        }
    }};
`;

const isDoneAndHasEventLog = (process: Process) => {
    return (
        [ProcessState.DONE, ProcessState.FAILED, ProcessState.ABORTED, ProcessState.TIMEOUT].includes(
            process.state as ProcessState
        ) &&
        process.data &&
        process.data.event_log_id
    );
};

const ProcessItem: React.FC<{ process: Process }> = ({ process }) => {
    const { t } = useTranslation();
    const { label, withProgress } = useProcessAdapt(process);
    const { user } = useUserData();
    const dispatch = useAppDispatch();
    const processes = useAppSelector(getProcesses);
    const navigate = useNavigate();
    const { serverIdentity } = useCurrentServerIdentitites();

    const isProcessWithEventLog = useMemo(() => isDoneAndHasEventLog(process), [process]);

    if (!user) {
        return null;
    }

    return (
        <StyledLi
            state={process.state}
            progress={process.progress}
            onClick={() => {
                if (process.state !== ProcessState.IN_PROGRESS) {
                    if (processes.includes(process)) {
                        dispatch(
                            deleteProcess({
                                processId: process.id,
                            })
                        );
                    } else {
                        dispatch(
                            removeMessage({
                                messageId: process.id,
                            })
                        );
                    }
                }
            }}
        >
            <Space $direction="horizontal" $wrap={false} $padding="10px 0px" $gap={10} $fullWidth $align="start">
                <Space
                    $direction="horizontal"
                    $align="start"
                    $oppositeAlign="center"
                    $padding={0}
                    $fullWidth
                    $gap={15}
                    $wrap={false}
                >
                    <div style={{ position: "relative" }}>
                        {process.state === ProcessState.IN_PROGRESS ? (
                            <>
                                <LoaderIcon width={36} src={LoadingIcon} alt="" />
                                {withProgress && process.progress !== null && (
                                    <Paragraph
                                        style={{
                                            fontWeight: 600,
                                            position: "absolute",
                                            left: "50%",
                                            top: "50%",
                                            transform: "translate(-50%, -55%)",
                                        }}
                                        $level={5}
                                    >
                                        {process.progress}%
                                    </Paragraph>
                                )}
                            </>
                        ) : process.state === ProcessState.DONE ? (
                            <MdOutlineDone
                                size={36}
                                color="#28A745"
                                style={{
                                    border: "3px solid #28A745",
                                    borderRadius: "50%",
                                    padding: 4,
                                }}
                            />
                        ) : (
                            <MdOutlineClose
                                size={36}
                                color="#ff4d4f"
                                style={{
                                    border: "3px solid #ff4d4f",
                                    borderRadius: "50%",
                                    padding: 4,
                                }}
                            />
                        )}
                    </div>
                    <Space $direction="vertical" $fullWidth $padding={0} $gap={0} $align="start">
                        <Space $direction="vertical" $align="space-between" $padding={0} $fullWidth $gap={0}>
                            <Paragraph
                                $level={4}
                                style={{
                                    whiteSpace: "nowrap",
                                    fontWeight: 500,
                                }}
                            >
                                {label}
                            </Paragraph>
                            {isProcessWithEventLog ? (
                                <InternalLink
                                    $inline
                                    $sizeVariant="small"
                                    $variant="info"
                                    $underline={false}
                                    to={`/s/${serverIdentity}/events/${process.data.event_log_id}`}
                                    style={{
                                        textTransform: "uppercase",
                                    }}
                                >
                                    {t("common.show_details")}
                                </InternalLink>
                            ) : (
                                <Space $gap={2} $direction="horizontal" $padding={0}>
                                    <Paragraph
                                        $level={5}
                                        style={{
                                            display: "flex",
                                            gap: "25px",
                                        }}
                                    >
                                        Serwer
                                    </Paragraph>
                                    <Paragraph
                                        $level={5}
                                        style={{
                                            display: "flex",
                                            gap: "25px",
                                        }}
                                    >
                                        {process.resourceId}
                                    </Paragraph>
                                </Space>
                            )}
                        </Space>
                    </Space>
                </Space>
                {<MdClose size={16} />}
            </Space>
        </StyledLi>
    );
};

export default ProcessItem;
