import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../hooks";
import CustomError from "../../../shared/CustomError";
import { useRefreshMutation, useVerifyMutation } from "../api";
import { isUserAdmin, isUserLoggedIn, setAuth, wasUserVerified } from "../slices/authSlice";

export default function () {
    const [verify] = useVerifyMutation();
    const [refreshRequest] = useRefreshMutation();
    const dispatch = useDispatch();
    const isLoggedIn = useAppSelector(isUserLoggedIn);
    const verified = useAppSelector(wasUserVerified);
    const isAdmin = useAppSelector(isUserAdmin);

    const verifyUser = useCallback(async () => {
        try {
            const response = await verify().unwrap();
            dispatch(
                setAuth({
                    user: response,
                    verified: true,
                    isLoading: false,
                    error: null,
                    isAdmin,
                })
            );
        } catch (e) {
            console.error("VERIFY FAILED: ", e);
            try {
                const response = await refreshRequest().unwrap();
                dispatch(
                    setAuth({
                        user: response,
                        verified: true,
                        isLoading: false,
                        error: null,
                        isAdmin,
                    })
                );
            } catch (e) {
                dispatch(
                    setAuth({
                        user: null,
                        verified: true,
                        isLoading: false,
                        error: (e as CustomError).message,
                        isAdmin: false,
                    })
                );
            }
        }
    }, [dispatch, verify]);

    const refresh = useCallback(() => {
        verifyUser();
    }, [verifyUser]);

    return {
        verifyUser,
        isLoggedIn,
        isLoading: !verified,
        verified,
        refresh,
    };
}
