import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../rootStore";

export interface ServerStatusSlice {
    pod_name: string | null;
    game_status: string | null;
    pod_image: string | null;
    hypnos_version: string | null;
    loading: boolean;
}

const serverStatusSlice = createSlice({
    name: "serverStatus",
    initialState: {
        pod_name: null,
        game_status: null,
        pod_image: null,
        hypnos_version: null,
        loading: true,
    } as ServerStatusSlice,
    reducers: {
        setServerStatus: (state, { payload }: PayloadAction<Partial<ServerStatusSlice> | null>) => {
            if (payload !== null) {
                state.loading = false;

                let hasChanges = false;
                for (const key in payload) {
                    if (payload[key as keyof ServerStatusSlice] !== state[key as keyof ServerStatusSlice]) {
                        hasChanges = true;
                        break;
                    }
                }

                if (hasChanges) {
                    Object.assign(state, {
                        ...payload,
                        loading: false,
                    });
                }
            } else {
                state.pod_name = null;
                state.game_status = null;
                state.pod_image = null;
                state.hypnos_version = null;
            }
        },
    },
});

export const { setServerStatus } = serverStatusSlice.actions;

export default serverStatusSlice.reducer;

export const getServerStatus = (state: RootState) => state.serverStatus;
export const isServerStatusLoading = (state: RootState) => state.serverStatus.loading;
