import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import CustomError from "../CustomError";
import { ApiError } from "../types";

export const transformError = (baseQueryReturnValue: FetchBaseQueryError) => {
    console.error(baseQueryReturnValue);
    const error = baseQueryReturnValue as ApiError;
    return {
        message: error.data.error,
        error: error.data.error,
        event_id: error.data.event_id,
    } as CustomError;
};
