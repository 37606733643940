import { Infer, object, string } from "superstruct";
import { PaymentType } from "../../constants";
import { ServerID, WalletID } from "../../shared/types";

export const PublicTransaction = object({
    id: string(),
    status: string(),
    action: string(),
});

export type PublicTransaction = Infer<typeof PublicTransaction>;

export type TransactionID = string;

export const TransactionSuccessResponse = object({
    redirect_uri: string(),
});

export type TransactionSuccessResponse = Infer<typeof TransactionSuccessResponse>;

export const TransactionResponse = TransactionSuccessResponse;

export type TransactionResponse = Infer<typeof TransactionResponse>;

export interface CreateTransactionPayload {
    action: "DEPOSIT" | "RENEW_SERVICE";
    payment_dto: object;
    payment_details: object;
    data: object;
    blik?: boolean;
    payment_type: PaymentType;
    wallet_id: WalletID;
    flags?: Record<string, any>;
    service_id: ServerID | null;
}

export interface UpdateTransactionPayload {
    payment_type: PaymentType;
}

export const UpdateTransactionResponse = object({
    redirect_uri: string(),
});

export type UpdateTransactionResponse = Infer<typeof UpdateTransactionResponse>;

export interface RenewServerPayload {
    duration: string;
    charge: boolean;
    cause: string;
}

export interface CompletePaymentResponse {
    id: TransactionID;
    status: string;
    action: string;
}
