import { ExternalLink, InternalLink, Paragraph, Space, Title } from "csrvui";
import React from "react";
import { useTranslation } from "react-i18next";
import useCustomTheme from "../../hooks/useCustomTheme";
import Spacer from "../Spacer";
import { StyledFooter, SubFooter } from "./style";

const Footer: React.FC = () => {
    const { t } = useTranslation();
    const theme = useCustomTheme();
    return (
        <StyledFooter>
            <Space>
                <Space
                    $direction="horizontal"
                    $align="space-between"
                    $gap={30}
                    style={{ width: "100%", maxWidth: 1400, margin: "0 auto" }}
                >
                    <Space $padding={0} style={{ width: "100%", maxWidth: 200 }}>
                        <Title $level={3} $color={theme.colors.secondaryText}>
                            {t("common.informations")}
                        </Title>
                        <Spacer style={{ backgroundColor: "rgba(255,255,255,0.5" }} />
                        <ul>
                            <Space $padding="10px 0">
                                <li>
                                    <InternalLink
                                        $inline
                                        $variant="white"
                                        $underline={false}
                                        to="/#offers"
                                        style={{ fontSize: "16px" }}
                                    >
                                        {t("common.offer")}
                                    </InternalLink>
                                </li>
                                {/* <li>
                                    <InternalLink $inline $variant="white" $underline={false} to="/screenshots">
                                        {t("common.screenshots")}
                                    </InternalLink>
                                </li> */}
                                {/* <li>
                                    <InternalLink $inline $variant="white" $underline={false} to="/faq">
                                        FAQ
                                    </InternalLink>
                                </li> */}
                                {/* <li>
                                    <InternalLink
                                        $inline
                                        $variant="white"
                                        $underline={false}
                                        to="/parents"
                                        style={{ fontSize: "16px" }}
                                    >
                                        {t("common.for_parents")}
                                    </InternalLink>
                                </li> */}

                                <li>
                                    <InternalLink
                                        $inline
                                        $variant="white"
                                        $underline={false}
                                        to="/contact"
                                        style={{ fontSize: "16px" }}
                                    >
                                        {t("common.contact")}
                                    </InternalLink>
                                </li>
                            </Space>
                        </ul>
                    </Space>
                    <iframe
                        name="f3910901a10f91a"
                        data-testid="fb:like_box Facebook Social Plugin"
                        title="fb:like_box Facebook Social Plugin"
                        allowFullScreen
                        allow="encrypted-media"
                        style={{ border: "none", visibility: "visible", maxWidth: 300, height: 130, width: "100%" }}
                        src="https://www.facebook.com/v2.0/plugins/like_box.php?app_id=118468658291808&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df21d66078950d26%26domain%3Dcraftserve.pl%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Fcraftserve.pl%252Ff2ced4ccd8bbd8a%26relation%3Dparent.parent&amp;color_scheme=dark&amp;container_width=340&amp;header=false&amp;href=https%3A%2F%2Fwww.facebook.com%2FCraftserve&amp;locale=en_US&amp;sdk=joey&amp;show_border=false&amp;show_faces=true&amp;stream=false"
                    ></iframe>
                </Space>
            </Space>
            <SubFooter>
                <Space
                    style={{ width: "100%", maxWidth: 1340, margin: "0 auto" }}
                    $direction="horizontal"
                    $align="space-between"
                >
                    <Paragraph $color={theme.colors.white}>
                        Copyright © 2011 - {new Date().getFullYear()} Craftserve. &quot;Minecraft&quot; is a trademark
                        of Mojang AB{" "}
                    </Paragraph>
                    <ul>
                        <Space $padding={0} $direction="horizontal" $align="end">
                            <li>
                                <InternalLink
                                    $variant="secondary"
                                    $inline
                                    $underline={false}
                                    to="/privacy-policy"
                                    style={{ fontSize: "16px" }}
                                >
                                    {t("common.privacy_policy")}
                                </InternalLink>
                            </li>
                            <li>
                                <InternalLink
                                    $variant="white"
                                    $underline={false}
                                    to="/static/regulamin.pdf"
                                    target="_blank"
                                    style={{
                                        margin: 0,
                                        padding: "0 1rem",
                                        fontSize: "16px",
                                    }}
                                >
                                    {t("common.regulation")}
                                </InternalLink>
                            </li>
                            {/* <li>
                                <InternalLink $variant="white" $inline $underline={false} to="/cookies">
                                    {t("common.cookies_info")}
                                </InternalLink>
                            </li> */}
                        </Space>
                    </ul>
                </Space>
            </SubFooter>
        </StyledFooter>
    );
};

export default Footer;
