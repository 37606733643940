import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../rootStore";
import { PointerToast, PointerToastType } from "../types";

export interface PointerToastsState {
    toasts: PointerToast[];
}

const slice = createSlice({
    name: "pointerToasts",
    initialState: {
        toasts: [] as PointerToast[],
    } as PointerToastsState,
    reducers: {
        addPointerToast: (
            state,
            { payload }: PayloadAction<{ id: string; text: string; type: PointerToastType; timeout?: number }>
        ) => {
            if (payload) {
                state.toasts.push({
                    id: payload.id,
                    text: payload.text,
                    type: payload.type,
                });

                if (payload.timeout) {
                    setTimeout(() => {
                        slice.caseReducers.deletePointerToast(state, {
                            payload: payload.id,
                            type: "deletePointerToast",
                        });
                    }, payload.timeout);
                }
            }
        },
        deletePointerToast: (state, { payload }: PayloadAction<string>) => {
            state.toasts = state.toasts.filter((toast) => toast.id !== payload);
        },
    },
});

export const { addPointerToast, deletePointerToast } = slice.actions;

export default slice.reducer;

export const getPointerToasts = (state: RootState) => state.pointerToasts.toasts;
