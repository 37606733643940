import { useEffect, useState } from "react";
import { darkTheme, lightTheme } from "csrvui";
import { getLocalTheme, setLocalTheme } from "../helpers/localStorage";

enum ThemeModes {
    LIGHT = "light",
    DARK = "dark",
}

export const themes = {
    [ThemeModes.LIGHT]: lightTheme,
    [ThemeModes.DARK]: darkTheme,
};

export default function () {
    const [mode, setMode] = useState<ThemeModes>(ThemeModes.LIGHT);
    const [theme, setTheme] = useState(themes.light);

    useEffect(() => {
        setTheme(themes[mode]);
    }, [mode]);

    const switchMode = (mode: ThemeModes) => {
        setLocalTheme(mode);
        setMode(mode);
    };

    const toggleTheme = () => {
        mode === ThemeModes.LIGHT ? switchMode(ThemeModes.DARK) : switchMode(ThemeModes.LIGHT);
    };

    const compareThemeWithStorage = () => {
        const localTheme = getLocalTheme();
        if (localTheme) {
            setMode(localTheme as ThemeModes);
        }
    };

    useEffect(() => {
        const localTheme = getLocalTheme();
        localTheme && setMode(localTheme as ThemeModes);

        window.addEventListener("storage", compareThemeWithStorage);

        return () => window.removeEventListener("storage", compareThemeWithStorage);
    }, []);

    return { theme, mode, toggleTheme };
}
