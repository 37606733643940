import { CsrvFileInfo } from "csrvfs";
import CsrvWebFTP from "csrvfs/dist/CsrvRemoteFS";
import { useEffect, useState } from "react";
import * as YAML from "yaml";
import { useAppDispatch } from "../../../hooks";
import { showApiError } from "../../../shared/helpers/showToast";
import { useLazyGetProjectQuery } from "../../Marketplace/api";
import { setInstalledApps, setInstalledPackages } from "../slices/currentServerSlice";
import { PublicServer } from "../types";

export default function (webFTP: CsrvWebFTP | null, server: PublicServer) {
    const dispatch = useAppDispatch();
    const [getProject] = useLazyGetProjectQuery();
    const [loading, setLoading] = useState(false);

    const initPackages = async () => {
        if (!webFTP) {
            return;
        }

        setLoading(true);

        let installedPlugins: CsrvFileInfo[] = [];

        try {
            installedPlugins = await webFTP.ls("/.kloceg/installed/", {
                depth: "1",
            });
        } catch (e) {
            installedPlugins = [];
        }

        let installedApps: CsrvFileInfo[] = [];
        try {
            installedApps = await webFTP.ls("/.craftserve/apps/shared/", {
                depth: "1",
            });
        } catch (e) {
            installedApps = [];
        }
        const pluginsData = await Promise.all(
            installedPlugins
                .filter((file) => {
                    const fileExtension = file.name.split(".").pop();
                    return fileExtension === "yml" && file.name.split("/").length === 1;
                })
                .map(async (file) => {
                    try {
                        const data = await webFTP.read(`/.kloceg/installed/${file.name}`);
                        const text = await data.text();
                        return YAML.parse(text);
                    } catch (e) {
                        showApiError(e);
                        return null;
                    }
                })
        ).catch(() => {
            return [];
        });

        const installedMap = pluginsData.reduce(
            (map, elem) => {
                map[elem.manifest.name] = {
                    version: elem.manifest.version,
                    hash: elem["manifest-hash"],
                    provides: elem.manifest.provides,
                    labels: elem.manifest.labels,
                };

                return map;
            },
            {} as Record<
                string,
                {
                    version: string;
                    hash: string;
                    provides: string[];
                }
            >
        ) as Record<
            string,
            {
                version: string;
                hash: string;
                provides: string[];
                labels: Record<string, string>;
            }
        >;

        const primaryEngine = server.parameters.game?.engine;

        dispatch(
            setInstalledPackages({
                installed: installedMap,
                primaryEngine: primaryEngine || "",
            })
        );

        if (installedApps) {
            Promise.all(
                installedApps.map(async (appData) => {
                    const appName = appData.name;
                    const project = await getProject(appName).unwrap();
                    const projectPackage = pluginsData.find((elem) => elem.manifest.name === appName);
                    const packageTime = projectPackage["manifest-hash"];

                    return { ...project, packageTime };
                })
            )
                .then((appsWithTimes) => {
                    const appsWithValidTimes = appsWithTimes.filter((app) => app.packageTime !== null);
                    const sortedApps = appsWithValidTimes.sort(
                        (a, b) => new Date(b.packageTime).getTime() - new Date(a.packageTime).getTime()
                    );

                    dispatch(setInstalledApps(sortedApps));
                })
                .catch(() => {
                    dispatch(setInstalledApps([]));
                });
        } else {
            dispatch(setInstalledApps([]));
        }

        setLoading(false);
    };

    useEffect(() => {
        initPackages();
    }, [webFTP, server]);

    return { refresh: initPackages, loading };
}
