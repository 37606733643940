import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Process } from "csrvprocess/dist/types";
import { RootState } from "../../../rootStore";
import { ProcessID } from "../../../shared/types";

const slice = createSlice({
    name: "messages",
    initialState: [] as Process[],
    reducers: {
        addMessage: (state, { payload: { message } }: PayloadAction<{ message: Process }>) => {
            state.push(message);
        },
        removeMessage: (state, { payload: { messageId } }: PayloadAction<{ messageId: ProcessID }>) => {
            const index = state.findIndex((p) => p.id === messageId);
            state.splice(index, 1);
        },
    },
});

export const { addMessage, removeMessage } = slice.actions;

export default slice.reducer;

export const getMessages = (state: RootState) => state.messages;
