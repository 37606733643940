import posthog from "posthog-js";
import { EventAction } from "../types";

interface Data {
    label: string;
    status?: string;
}

const useEventTracker = () => {
    const umami = window.umami;

    const trackEvent = (event_name: EventAction, payload: Data) => {
        if (umami) {
            umami.track(event_name, payload);
        }

        if (window.location.hostname !== "localhost") {
            posthog.capture(event_name, payload);
        }
    };

    return { trackEvent };
};
export default useEventTracker;
