import { Paragraph, Space } from "csrvui";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { MdAttachMoney } from "react-icons/md";
import { Money } from "ts-money";
import { TransactionStatusLabels } from "../../../../constants";
import getTransactionStatusColor from "../../../../shared/helpers/getTransactionStatusColor";
import translateTransaction from "../../../../shared/helpers/translateTransaction";
import useTheme from "../../../../shared/hooks/useCustomTheme";
import useMoney from "../../../../shared/hooks/useMoney";
import { Transaction } from "../../../../shared/types";
import { SearchResultWrapper } from "../../styles";

interface Props {
    transaction: Transaction;
}

export const TransactionResult = ({ transaction }: Props) => {
    const theme = useTheme();
    const { printPrice } = useMoney();
    const { t } = useTranslation();
    const transactionStatusColor = useMemo(() => getTransactionStatusColor(transaction.status, theme), [transaction]);

    return (
        <SearchResultWrapper>
            <Space $padding={0} $align="space-between" $oppositeAlign="center" $direction="horizontal">
                <MdAttachMoney style={{ fill: transactionStatusColor }} size={36} />
                <Space $gap={0} $padding={0}>
                    <Paragraph style={{ fontSize: "20px", fontWeight: 500, width: "100%", textAlign: "start" }}>
                        {translateTransaction(transaction.action)} {transaction.invoiceid}
                    </Paragraph>
                    <Paragraph style={{ width: "100%", textAlign: "start", color: transactionStatusColor }}>
                        {TransactionStatusLabels[transaction.status]}
                    </Paragraph>
                </Space>
            </Space>

            <Space $padding={0} $gap={0} $align="end">
                <Paragraph style={{ fontSize: "20px", fontWeight: 500 }}>
                    {printPrice(new Money(transaction.amount, transaction.currency))} {transaction.currency}
                </Paragraph>
                <Paragraph>{t("dates.simple", { date: new Date(transaction.ts) })}</Paragraph>
            </Space>
        </SearchResultWrapper>
    );
};
