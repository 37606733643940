import { usePostHog } from "posthog-js/react";
import React, { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import useUserData from "../../../modules/User/hooks/useUserData";
import useEventTracker from "../../hooks/useEventTracker";
import { EventAction, TrackedRoute } from "../../types";

interface Props {
    children: React.ReactNode;
    trackedRoutes: TrackedRoute[];
}

const RouteChangeListener = ({ children, trackedRoutes }: Props) => {
    const location = useLocation();
    const { trackEvent } = useEventTracker();
    const { user } = useUserData();
    const currentRoute = trackedRoutes.find(({ route }) => location.pathname.includes(route));
    const posthog = usePostHog();
    const pathname = location.pathname;
    const searchParams = new URLSearchParams(location.search);
    const formattedPathname = pathname.replace(
        /\/(s|events|releases|notifications|wallets)\/[a-zA-Z0-9-]+/g,
        (_, p1) => `/${p1}/{id}`
    );
    

    const handleVisit = useCallback(() => {
        trackEvent(EventAction.VISIT_ROUTE, {
            label: formattedPathname,
        });
    }, [formattedPathname]);

    const track = useCallback(() => {
        const isTrackingDisabled = localStorage.getItem("disableTracking");
        if (!currentRoute || isTrackingDisabled) return;

        if (!isTrackingDisabled) {
            trackEvent(
                currentRoute.event,
                {
                    label: `${currentRoute.event} interrupted`,
                },
                true
            );
        }
    }, [currentRoute, trackEvent]);

    useEffect(() => {
        user && posthog.identify(user.id);
    }, [user]);

    useEffect(() => {
        handleVisit();

        if (!currentRoute) return;
        localStorage.removeItem("disableTracking");

        const handleBeforeUnload = () => track();

        const addListener = () => {
            window.addEventListener("beforeunload", handleBeforeUnload);
        };

        const removeListener = () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };

        addListener();

        return () => {
            track();
            removeListener();
        };
    }, [location.pathname]);

    useEffect(() => {
        if (formattedPathname && posthog) {
            let url = window.origin + formattedPathname;

            if (searchParams.toString()) {
                url = url + `?${searchParams.toString()}`;
            }
            posthog.capture("$pageview", {
                $current_url: url,
                user_id: user ? user.id : "user is not logged in",
            });
        }
    }, [formattedPathname, searchParams, posthog]);

    return <>{children}</>;
};

export default RouteChangeListener;
