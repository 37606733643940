import { PublicServer } from "../../Server/types";
import { PublicUser } from "../types";

export const hasUserCapability = (user: PublicUser, capability: string) => {
    const fields = capability.split(".");
    let value = false;
    fields.forEach((f) => {
        f = fields.slice(0, fields.indexOf(f) + 1).join(".");
        if (user.user_capabilities[f]) {
            value = user.user_capabilities[f];
        }
    });

    return value;
};

export const hasResourceUserCapability = (server: PublicServer, capability: string) => {
    const fields = capability.split(".");
    let value = false;
    fields.forEach((f) => {
        f = fields.slice(0, fields.indexOf(f) + 1).join(".");
        if (server.user_capabilities[f]) {
            value = server.user_capabilities[f];
        }
    });

    return value;
};
