import CsrvFS from "csrvfs";
import CsrvWebFTP from "csrvfs/dist/CsrvRemoteFS";
import { createContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useGetServiceByIdQuery, useGetServiceCostQuery } from "../../../modules/Billings/api";
import { BillingService } from "../../../modules/Billings/types";
import { MarketProject } from "../../../modules/Marketplace/types";
import {
    useGenerateRemotefsTokenMutation,
    useLazyServerStatusQuery,
    usePingServerMutation,
    useServerByIdQuery,
} from "../../../modules/Server/api";
import useServerPackages from "../../../modules/Server/hooks/useServerPackages";
import { useWalletByIdQuery } from "../../../modules/Server/modules/Wallets/api";
import {
    getCurrentServer,
    getCurrentService,
    getCurrentWallet,
    getInstalledApps,
    getInstalledPackages,
    getPrimaryEngine,
    isInstalledAppsLoading,
    isInstalledPackagesLoading,
    setCurrentData,
    setInstalledPackages,
    setInstalledPackagesLoading,
} from "../../../modules/Server/slices/currentServerSlice";
import { getServerStatus, ServerStatusSlice, setServerStatus } from "../../../modules/Server/slices/serverStatusSlice";
import { PublicServer } from "../../../modules/Server/types";
import { UserTypes } from "../../../modules/User";
import { hasUserCapability } from "../../../modules/User/helpers/hasCapability";
import useUserData from "../../../modules/User/hooks/useUserData";
import { HostUrl } from "../../../rootTypes";
import showToast, { showApiError } from "../../helpers/showToast";
import useCurrentServerIdentitites from "../../hooks/useCurrentServerIdentitites";
import { UserPermissions } from "../../types";
import GlobalTransitionLoader from "../GlobalTransitionLoader";

interface Props {
    children: JSX.Element;
    rewriteServerId?: string;
    disableTransition?: boolean;
}

export const CurrentServerContext = createContext({
    server: {} as PublicServer,
    service: {} as BillingService,
    webFTP: null as CsrvWebFTP | null,
    wallet: {} as UserTypes.Wallet,
    csrvfs: null as CsrvFS | null,
    installedPackages: {
        data: {} as Record<
            string,
            {
                version: string;
                hash: string;
                provides: string[];
                labels: Record<string, string>;
            }
        >,
        primaryEngine: "",
        loading: true,
    },
    installedApps: {
        data: [] as MarketProject[],
        loading: true,
    },
    primaryEngine: "",
    serverStatus: {
        pod_name: null,
        game_status: null,
        pod_image: null,
        hypnos_version: null,
    } as ServerStatusSlice,
    refreshCurrentServerData: async () => {
        return;
    },
    refreshInstalledPackages: async () => {
        return;
    },
    clearServerData: () => {
        return;
    },
});

const CurrentServerProvider: React.FC<Props> = ({ children, rewriteServerId, disableTransition }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [generateRemotefsToken] = useGenerateRemotefsTokenMutation();

    //data
    const { serverId: currentServerId } = useCurrentServerIdentitites();
    const { user, updateUserData, isLoading: isUserLoading } = useUserData();

    const serverId = useMemo(() => rewriteServerId ?? currentServerId, [rewriteServerId, currentServerId]);

    // queries
    const {
        data: serverData,
        isLoading,
        refetch,
        isError,
    } = useServerByIdQuery(serverId ?? "", {
        skip: !serverId,
    });
    const [fetchServerStatus] = useLazyServerStatusQuery();

    const {
        data: serviceData,
        isLoading: isServiceLoading,
        refetch: refetchStatus,
        isError: isServiceError,
        error: serviceError,
    } = useGetServiceByIdQuery(serverId ?? "", {
        skip: !serverId,
    });

    const {
        data: serviceCost,
        isLoading: isCostLoading,
        refetch: refetchServiceCost,
    } = useGetServiceCostQuery(
        { id: serverId ?? "" },
        {
            skip: !serverId,
        }
    );
    const { data: walletData, refetch: refreshWallet } = useWalletByIdQuery(serviceData?.wallet_id || "", {
        skip: !serviceData?.wallet_id,
    });

    // selectors
    const dispatch = useAppDispatch();
    const server = useAppSelector(getCurrentServer);
    const service = useAppSelector(getCurrentService);
    const wallet = useAppSelector(getCurrentWallet);
    const serverStatus = useAppSelector(getServerStatus);
    const installedPackages = useAppSelector(getInstalledPackages);
    const installedApps = useAppSelector(getInstalledApps);
    const appsLoading = useAppSelector(isInstalledAppsLoading);
    const isPackagesLoading = useAppSelector(isInstalledPackagesLoading);
    const primaryEngine = useAppSelector(getPrimaryEngine);

    const [pingServer] = usePingServerMutation();
    const keepAwakeHandler = useRef<NodeJS.Timer>();

    // useStates
    const [inProp, setInProp] = useState(isLoading || serverStatus.loading || !service);

    const url = window.location.origin.includes("localhost") ? HostUrl : window.location.origin;
    const remotefsUrl = useMemo(() => {
        if (!serverData) return null;
        return `https://${serverData.last_node}/${serverData.id}/remotefs`;
    }, [serverData]);

    const csrvfsInstance = useRef<CsrvFS | null>(null as CsrvFS | null);
    const [webFTPInstance, setWebFTPInstance] = useState<CsrvWebFTP | null>(null);

    useEffect(() => {
        const initCsrvfs = async () => {
            if (!serviceData || !remotefsUrl) return;

            try {
                const tokenResponse = await generateRemotefsToken(serviceData.service_id).unwrap();
                csrvfsInstance.current = new CsrvFS(url, remotefsUrl, serviceData.service_id, {
                    token: tokenResponse.token,
                });
                const webFTPInstance = new CsrvWebFTP(url, remotefsUrl, serviceData.service_id, {
                    token: tokenResponse.token,
                });

                setWebFTPInstance(webFTPInstance);
            } catch (e) {
                showApiError(e);
            }
        };

        initCsrvfs();
    }, [serviceData, url, remotefsUrl]);

    const { refresh, loading: packagesLoading } = useServerPackages(webFTPInstance || null, server);

    useEffect(() => {
        if (serviceData && walletData) {
            updateUserData();
            refreshWallet();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serverId]);

    const removeKeepAwakeListener = () => {
        clearInterval(keepAwakeHandler.current as unknown as number);
    };

    const reinitKeepAwakeListener = () => {
        clearInterval(keepAwakeHandler.current as unknown as number);

        if (serverStatus.game_status !== null && serverId) {
            pingServer(serverId);
        }
        if (keepAwakeHandler.current) {
            clearInterval(keepAwakeHandler.current as unknown as number);
        }
        keepAwakeHandler.current = setInterval(() => {
            if (serverStatus.game_status !== null && serverId) {
                pingServer(serverId);
            }
        }, 30000);
    };

    useEffect(() => {
        if (serverStatus.game_status) {
            window.removeEventListener("blur", removeKeepAwakeListener);
            window.removeEventListener("focus", reinitKeepAwakeListener);

            window.addEventListener("blur", removeKeepAwakeListener);
            window.addEventListener("focus", reinitKeepAwakeListener);

            if (keepAwakeHandler.current) {
                clearInterval(keepAwakeHandler.current as unknown as number);
            }

            keepAwakeHandler.current = setInterval(() => {
                if (serverStatus.game_status !== null && serverId) {
                    pingServer(serverId);
                }
            }, 30000);
        }

        return () => {
            keepAwakeHandler.current && clearInterval(keepAwakeHandler.current as unknown as number);
        };
    }, [serverStatus]);

    useEffect(() => {
        return () => {
            dispatch(
                setCurrentData({
                    server: null,
                    service: null,
                    serviceCost: null,
                    wallet: null,
                    installedPackages: {
                        primaryEngine: "",
                        data: {},
                        loading: true,
                    },
                    installedApps: {
                        data: [],
                        loading: true,
                    },
                    loading: false,
                    error: null,
                })
            );

            dispatch(
                setInstalledPackages({
                    installed: {},
                    primaryEngine: "",
                })
            );

            dispatch(setInstalledPackagesLoading(true));
        };
    }, []);

    useEffect(() => {
        if (
            serverData &&
            serviceData &&
            walletData &&
            serviceCost &&
            installedPackages &&
            installedApps &&
            !isLoading &&
            !isServiceLoading &&
            !isCostLoading &&
            !packagesLoading
        ) {
            dispatch(
                setCurrentData({
                    server: serverData,
                    service: serviceData,
                    serviceCost: serviceCost.cost,
                    wallet: walletData,
                    installedPackages: {
                        data: installedPackages,
                        primaryEngine: "",
                        loading: false,
                    },
                    installedApps: {
                        data: installedApps,
                        loading: false,
                    },
                    loading: false,
                    error: null,
                })
            );

            const getServerStatus = async () => {
                if (serverId) {
                    try {
                        const data = await fetchServerStatus(serverId).unwrap();
                        dispatch(setServerStatus(data));
                    } catch (e) {
                        showToast({
                            text: t("errors.server_status"),
                            variant: "error",
                        });
                        navigate("/account");
                        console.error(e);
                    }
                }
            };

            getServerStatus();
        }
    }, [
        dispatch,
        serverData,
        serviceData,
        walletData,
        isLoading,
        isCostLoading,
        isServiceLoading,
        serviceCost,
        packagesLoading,
    ]);

    useEffect(() => {
        setInProp(isLoading || serverStatus.loading || !service || !server || !service || !wallet);
    }, [isLoading, serverStatus, isPackagesLoading, service, server, wallet]);

    if (isServiceError) {
        navigate("/account");
        showApiError(serviceError);
    }

    if (!isLoading && !serverData && !isUserLoading && serverId !== undefined) {
        if (isError && hasUserCapability(user, UserPermissions.SERVER)) {
            if (user.services.length > 0) {
                navigate("/unauthorized-server", {
                    replace: true,
                    state: {
                        from: window.location.pathname.replace(serverId as string, ":serverid"),
                    },
                });
            } else {
                navigate("/account");
            }
            return null;
        }
        if (!serverData) {
            return <Navigate to="/account" />;
        }
    }

    if (disableTransition) {
        return (
            <CurrentServerContext.Provider
                value={{
                    server,
                    service,
                    wallet,
                    installedPackages: {
                        data: installedPackages,
                        primaryEngine,
                        loading: packagesLoading,
                    },
                    installedApps: {
                        data: installedApps,
                        loading: appsLoading,
                    },
                    primaryEngine,
                    refreshInstalledPackages: refresh,
                    refreshCurrentServerData: async () => {
                        refreshWallet();
                        refetchStatus();
                        updateUserData();
                        refetch();
                        refetchServiceCost();
                    },
                    clearServerData: () => {
                        dispatch(
                            setCurrentData({
                                server: null,
                                service: null,
                                serviceCost: null,
                                wallet: null,
                                installedPackages: {
                                    primaryEngine: "",
                                    data: {},
                                    loading: true,
                                },
                                installedApps: {
                                    data: [],
                                    loading: true,
                                },
                                loading: false,
                                error: null,
                            })
                        );
                    },
                    csrvfs: csrvfsInstance.current,
                    webFTP: webFTPInstance,
                    serverStatus,
                }}
            >
                {children}
            </CurrentServerContext.Provider>
        );
    }

    return (
        <CurrentServerContext.Provider
            value={{
                server,
                service,
                wallet,
                installedPackages: {
                    data: installedPackages,
                    primaryEngine,
                    loading: packagesLoading,
                },
                installedApps: {
                    data: installedApps,
                    loading: appsLoading,
                },
                primaryEngine,
                refreshInstalledPackages: refresh,
                refreshCurrentServerData: async () => {
                    refreshWallet();
                    refetchStatus();
                    updateUserData();
                    refetch();
                    refetchServiceCost();
                },
                clearServerData: () => {
                    dispatch(
                        setCurrentData({
                            server: null,
                            service: null,
                            serviceCost: null,
                            wallet: null,
                            installedPackages: {
                                primaryEngine: "",
                                data: {},
                                loading: true,
                            },
                            installedApps: {
                                data: [],
                                loading: true,
                            },
                            loading: false,
                            error: null,
                        })
                    );
                },
                webFTP: webFTPInstance,
                csrvfs: csrvfsInstance.current,
                serverStatus,
            }}
        >
            <GlobalTransitionLoader text={t("loading.data")} inProp={inProp} />
            {!isLoading && !serverStatus.loading && !!server && !!service && children}
        </CurrentServerContext.Provider>
    );
};

export default CurrentServerProvider;
