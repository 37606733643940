import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import * as Sentry from "@sentry/browser";
import { HttpStatus } from "../../constants";
import { HostUrl } from "../../rootTypes";
import { ServerID } from "../../shared/types";
import { BillingService } from "../Billings/types";
import { RenewServerPayload } from "../Payments/types";

export const api = createApi({
    reducerPath: "services",
    baseQuery: fetchBaseQuery({
        baseUrl: `${HostUrl}/api/billings/services`,
        prepareHeaders: (headers) => {
            const transactionId = Math.random().toString(36).substr(2, 9);
            Sentry.configureScope((scope) => {
                scope.setTag("transaction_id", transactionId);
            });
            headers.set("X-transation-ID", transactionId);

            return headers;
        },
    }),
    endpoints: (builder) => ({
        addVoucher: builder.mutation<void, { serverId: ServerID; voucher: string }>({
            query: (payload) => ({
                url: `/${payload.serverId}/voucher`,
                method: "POST",
                body: { voucher: payload.voucher },
                validateStatus: (response) => response.status === HttpStatus.Created,
            }),
        }),
        renewService: builder.mutation<void, { serviceId: ServerID; payload: RenewServerPayload }>({
            query: ({ serviceId, payload }) => ({
                url: `/${serviceId}`,
                method: "POST",
                body: JSON.stringify(payload),
                validateStatus: (response) => response.status === HttpStatus.Created,
            }),
        }),
        getById: builder.query<BillingService, string>({
            query: (id) => ({
                url: `/${id}`,
                method: "GET",
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
        }),
    }),
});

export const { useAddVoucherMutation, useRenewServiceMutation, useGetByIdQuery } = api;
