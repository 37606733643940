import styled from "styled-components";

export const Grid = styled.div`
    display: grid;
    gap: 36px;
    grid-template-columns: 1fr;
    width: 100%;
    align-items: start;

    @media (min-width: 1000px) {
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
            "left-top right-top"
            "full-width full-width";
    }

    > div {
        min-height: 100%;
    }

    > div:nth-child(1) {
        grid-area: left-top;
    }

    > div:nth-child(2) {
        grid-area: right-top;
    }

    > div:nth-child(3) {
        grid-area: full-width;
    }

    > div:nth-child(4) {
        grid-column: 1 / span 2;
    }

    > div:nth-child(5) {
        grid-column: 1 / span 2;
    }
`;

export const SmallerGrid = styled.div<{ itemCount: number }>`
    display: grid;
    grid-gap: 16px;
    grid-template-columns: ${({ itemCount }) =>
        itemCount === 1 ? "1fr 1fr" : "repeat(auto-fill, minmax(350px, 1fr))"};
`;
