import { useState, useEffect } from "react";

const getDebugEnabled = () => JSON.parse(localStorage.getItem("craftserve-debug-enabled") || "false");
const setDebugEnabled = (enabled: boolean) => localStorage.setItem("craftserve-debug-enabled", JSON.stringify(enabled));

const useDebugState = () => {
    const [isDebugEnabled, setIsDebugEnabled] = useState(getDebugEnabled());

    const toggleDebug = () => {
        const newDebugState = !isDebugEnabled;
        setDebugEnabled(newDebugState);
        window.localStorage.setItem("craftserve-debug-enabled", JSON.stringify(newDebugState));
        window.dispatchEvent(new Event("storage"));
        setIsDebugEnabled(newDebugState);
    };

    useEffect(() => {
        const handleStorageChange = () => {
            const newDebugState = getDebugEnabled();
            if (newDebugState !== isDebugEnabled) {
                setIsDebugEnabled(newDebugState);
            }
        };

        window.addEventListener("storage", handleStorageChange);

        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, [isDebugEnabled]);

    return [isDebugEnabled, toggleDebug] as const;
};

export default useDebugState;
