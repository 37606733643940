import { useAppSelector } from "../../hooks";
import usePreviewState from "../../modules/Server/hooks/usePreviewState";
import { getCurrentServer } from "../../modules/Server/slices/currentServerSlice";
import { selectCurrentUser } from "../../modules/User/slices/authSlice";
import { hasCapabilityInObject } from "../helpers/capabilities";
import { Capability } from "../types";

export default function usePermissions() {
    const user = useAppSelector(selectCurrentUser);
    const capabilities = user ? user.user_capabilities : {};
    const server = useAppSelector(getCurrentServer);
    const [isPreview] = usePreviewState();

    const isAdmin = hasCapabilityInObject(capabilities, Capability.IsAdmin) && !isPreview;

    return {
        canManageCurrentServer:
            (server && hasCapabilityInObject(server.user_capabilities, Capability.CanManageCurrentServer)) ||
            hasCapabilityInObject(capabilities, Capability.CanManageServer),
        canCreateMarketplaceProject: hasCapabilityInObject(capabilities, Capability.CanCreateMarketplaceProject),
        isAdmin,
        canAdminMenu: hasCapabilityInObject(capabilities, Capability.IsAdmin),
        canManageServersAsAdmin: hasCapabilityInObject(capabilities, Capability.CanManageServersAsAdmin),
        canManageServerParameters: hasCapabilityInObject(capabilities, Capability.CanManageServerParameters),
        canTopupAsAdmin: hasCapabilityInObject(capabilities, Capability.CanTopupAsAdmin),
        canUserBrowseMarketProjects: hasCapabilityInObject(capabilities, Capability.CanBrowseMarketplaceProjects),
        canEditMarketProject: hasCapabilityInObject(capabilities, Capability.CanEditMarketplaceProject),
        canReadMarketProject: hasCapabilityInObject(capabilities, Capability.CanReadMarketplaceProject),
        canCreateProjectWithoutPrefix: hasCapabilityInObject(capabilities, Capability.CanCreateProjectWithoutPrefix),
    };
}
