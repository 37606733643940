import { Card, Image, Space, Tag, Title } from "csrvui";
import { isAfter } from "date-fns";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import activeIcon from "../../../assets/icons/active-server-icon.svg";
import inactiveIcon from "../../../assets/icons/inactive-server-icon.svg";
import { BillingService } from "../../../modules/Billings/types";
import { Server } from "../../../modules/Server/types";
import useTheme from "../../hooks/useCustomTheme";
import ServicesCraftumItem from "../ServicesCraftumItem";
import { TbSquareFilled } from "react-icons/tb";
import getServerIdentificator from "../../../modules/Server/helpers/getServerIdentificator";

interface Props {
    service?: BillingService;
    server: Server;
    craftums?: BillingService[];
    minimalView?: boolean;
    ignoreIsExpired?: boolean;
    disableNavigation?: boolean;
}

const ServicesListItem: React.FC<Props> = ({
    service,
    server,
    craftums = [],
    minimalView = false,
    ignoreIsExpired = false,
    disableNavigation = false,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { domains, id } = server;

    const expires = service ? service.expires : undefined;

    const serverLink = domains && domains.length > 0 ? `/s/${domains[0].sub_domain}` : `/s/${id}`;

    const handleDisableNavigation = (e: React.MouseEvent<HTMLAnchorElement>) => {
        if (disableNavigation) {
            e.preventDefault();
        }
    };

    return (
        <Link
            to={!expires || isAfter(new Date(), new Date(expires)) ? `${serverLink}/payments` : serverLink}
            style={{ textDecoration: "none" }}
            title={id}
            {...(disableNavigation && { onClick: handleDisableNavigation })}
        >
            <Card
                style={{
                    width: "100%",
                    borderLeft: `2px solid ${
                        ignoreIsExpired
                            ? theme.colors.info
                            : !expires || isAfter(new Date(), new Date(expires))
                            ? theme.colors.danger
                            : theme.colors.success
                    }`,
                }}
            >
                <Space $direction="horizontal" $fullWidth $oppositeAlign="center" $align="space-between">
                    <Space $direction="horizontal" $oppositeAlign="center" $gap={24} $padding={0}>
                        {ignoreIsExpired ? (
                            <TbSquareFilled size={27} style={{ color: theme.colors.info }} />
                        ) : !expires || isAfter(new Date(), new Date(expires)) ? (
                            <Image width={24} height={24} src={inactiveIcon} alt="" />
                        ) : (
                            <Image width={24} height={24} src={activeIcon} alt="" />
                        )}

                        <Title style={{ margin: 0, fontWeight: 500 }} $level={4}>
                            {getServerIdentificator(server)}
                        </Title>
                    </Space>
                    <Space $direction="horizontal" $padding={0} $align="end">
                        {minimalView || ignoreIsExpired ? (
                            <Tag $backgroundColor={theme.colors.lavender} id={id}>
                                {id}
                            </Tag>
                        ) : (
                            <>
                                {domains && domains.length > 0 && (
                                    <Tag $backgroundColor={theme.colors.lavender} id={domains[0].fqdn}>
                                        {domains[0].fqdn}
                                    </Tag>
                                )}
                                {!expires || isAfter(new Date(), new Date(expires)) ? (
                                    <Tag $backgroundColor={theme.colors.lavender} id="expires">
                                        {t("common.expires_null")}
                                    </Tag>
                                ) : (
                                    <Tag $backgroundColor={theme.colors.lavender} id={expires.toString()}>
                                        {`${t("common.active_to")} ${t("dates.simple", {
                                            date: new Date(expires),
                                        })}`}
                                    </Tag>
                                )}
                            </>
                        )}
                    </Space>
                </Space>
            </Card>
            {craftums && craftums.length > 0 && (
                <Space $padding="12px 0 0 0" $gap={12}>
                    {craftums.map((craftum) => {
                        return <ServicesCraftumItem key={craftum.service_id} service={craftum} nested />;
                    })}
                </Space>
            )}
        </Link>
    );
};

export default ServicesListItem;
