import styled from "styled-components";

export const StyledHeader = styled.header<{ dark?: boolean }>`
    position: fixed;
    --border: 2px solid ${({ theme }) => theme.colors.border};
    background-color: ${({ theme }) => theme.colors.primaryDark};
    z-index: 1001;
    height: 72px;
    width: 100%;
    top: 0;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    justify-content: flex-start;
    gap: 30px;
    align-items: center;
    padding: 20px 0;
    border-bottom: var(--border);
    & > a {
        color: ${({ theme }) => theme.colors.danger};
    }

    white-space: nowrap;

    @media screen and (min-width: ${({ theme }) => theme.mobileBreakpoint}) {
        background-color: ${({ theme, dark }) => (dark ? theme.colors.primary : theme.colors.secondaryBackground)};
        padding: 20px;

        & > a {
            color: unset;
        }
    }
`;
