import { PublicMarketProjectMedia } from "../../Server/types";
import { MarketFilesName, MarketProject } from "../types";

const useProjectAssets = (data?: MarketProject | PublicMarketProjectMedia[]) => {
    const target = data && "media" in data ? data.media : data;

    if (!target || target.length === 0) {
        return {
            thumbnail: undefined,
            video: undefined,
            icon: undefined,
            gallery: [],
        };
    }

    const thumbnail = target.find((media) => media.name.includes(MarketFilesName.THUMBNAIL));
    const video = target.find((media) => media.name.includes(MarketFilesName.VIDEO));
    const icon = target.find((media) => media.name.includes(MarketFilesName.ICON));
    const gallery = target.filter(
        (media) =>
            !media.name.includes(MarketFilesName.THUMBNAIL) &&
            !media.name.includes(MarketFilesName.VIDEO) &&
            !media.name.includes(MarketFilesName.ICON)
    );

    return {
        thumbnail: thumbnail?.url,
        video: video?.url,
        icon: icon?.url,
        gallery: gallery.map((media) => media.url),
    };
};

export default useProjectAssets;
