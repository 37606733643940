import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import * as Sentry from "@sentry/browser";
import { array } from "superstruct";
import { HttpStatus } from "../../constants";
import { HostUrl } from "../../rootTypes";
import { transformToStruct } from "../../shared/helpers/customQuery";
import { transformError } from "../../shared/helpers/errors";
import {
    CheckAvailabilityDomainResponse,
    CheckAvailabilityDomainResponseStruct,
    EventDetails,
    ServerID,
} from "../../shared/types";
import { BillingService } from "../Billings/types";
import { CreateProjectFromServerPayload, InstallReleaseOptions, MarketProject } from "../Marketplace/types";
import { ServerTypes } from "../Server";
import { UserTypes } from "../User";
import { AdminPayload } from "./components/AdminSettings";
import {
    CheckAvailabilityDomainPayload,
    CreateServerFromVoucherPayload,
    CreateServerResponse,
    Engine,
    GenerateRemotefsTokenResponse,
    MigratePayload,
} from "./types";

export const api = createApi({
    reducerPath: "servers",
    baseQuery: fetchBaseQuery({
        baseUrl: `${HostUrl}/api/servers`,
        prepareHeaders: (headers) => {
            const transactionId = Math.random().toString(36).substr(2, 9);
            Sentry.configureScope((scope) => {
                scope.setTag("transaction_id", transactionId);
            });
            headers.set("X-transation-ID", transactionId);

            return headers;
        },
    }),

    endpoints: (builder) => ({
        availableGames: builder.query<ServerTypes.SearchGamesResponse, void>({
            query: () => ({
                url: "/games",
                method: "GET",
                credentials: "include",
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformResponse(data: ServerTypes.SearchGamesResponse) {
                return transformToStruct(data, ServerTypes.SearchGamesResponse, "/games");
            },
            transformErrorResponse: transformError,
        }),
        serverById: builder.query<ServerTypes.PublicServer, ServerID>({
            query: (serverId: ServerID) => ({
                url: `/${serverId}`,
                method: "GET",
                credentials: "include",
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformResponse(data: ServerTypes.PublicServer) {
                return transformToStruct(data, ServerTypes.PublicServer, "/:serverId");
            },
            transformErrorResponse: transformError,
        }),

        createServer: builder.mutation<
            {
                server: ServerTypes.Server;
                billing_services: BillingService[];
            },
            ServerTypes.CreateServerPayload
        >({
            query: (payload: ServerTypes.CreateServerPayload) => ({
                url: "",
                method: "POST",
                credentials: "include",
                body: payload,
                validateStatus: (response) => response.status === HttpStatus.Created,
            }),
            transformResponse(data: ServerTypes.Server) {
                return transformToStruct(data, ServerTypes.Server, "/");
            },
            transformErrorResponse: transformError,
        }),

        changeEngine: builder.mutation<
            void,
            {
                server_id: ServerID;
                data: ServerTypes.ChangeEnginePayload;
            }
        >({
            query: ({ data, server_id }) => ({
                url: `/${server_id}/engine`,
                method: "POST",
                credentials: "include",
                body: data,
                validateStatus: (response) => response.status === HttpStatus.Created,
            }),
            transformResponse(data: any) {
                return transformToStruct(data, ServerTypes.ChangeEnginePayload, "/");
            },
            transformErrorResponse: transformError,
        }),

        setSecondaryDomain: builder.mutation<
            void,
            {
                serverId: ServerID;
                data: ServerTypes.SetSecondaryDomainPayload;
            }
        >({
            query: ({ serverId, data }) => ({
                url: `/${serverId}/domains/secondary`,
                method: "POST",
                credentials: "include",
                body: data,
                validateStatus: (response) => response.status === HttpStatus.Created,
            }),
            transformErrorResponse: transformError,
        }),
        setPrimaryDomain: builder.mutation<
            void,
            {
                serverId: ServerID;
                data: ServerTypes.SetSecondaryDomainPayload;
            }
        >({
            query: ({ serverId, data }) => ({
                url: `/${serverId}/domains/primary`,
                method: "POST",
                credentials: "include",
                body: data,
                validateStatus: (response) => response.status === HttpStatus.Created,
            }),
            transformErrorResponse: transformError,
        }),
        serverEvents: builder.query<UserTypes.ServerEventsResponse, UserTypes.EventsQueryData>({
            query: (q) => ({
                url: `/${q.id}/events`,
                method: "GET",
                credentials: "include",
                params: {
                    after: q.after,
                    action: q.action,
                    fromDate: q.fromDate,
                    toDate: q.toDate,
                },
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformResponse(data: UserTypes.ServerEventsResponse) {
                return transformToStruct(data, UserTypes.ServerEventsResponse, "/:serverId/events");
            },
            transformErrorResponse: transformError,
        }),
        installPackage: builder.mutation<void, { serverId: ServerID; payload: InstallReleaseOptions }>({
            query: ({ payload, serverId }) => ({
                url: `/${serverId}/package`,
                method: "POST",
                credentials: "include",
                body: payload,
                validateStatus: (response) => response.status === HttpStatus.Created,
            }),
            transformErrorResponse: transformError,
        }),
        serverStatus: builder.query<UserTypes.ServerStatusResponse, ServerID>({
            query: (serverId: ServerID) => ({
                url: `/${serverId}/status`,
                method: "GET",
                credentials: "include",
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformResponse(data: UserTypes.ServerStatusResponse) {
                return transformToStruct(data, UserTypes.ServerStatusResponse, "/:serverId/status");
            },
            // transformErrorResponse: transformError,
        }),
        serverQuota: builder.query<ServerTypes.ServerQuota, ServerID>({
            query: (serverId: ServerID) => ({
                url: `/${serverId}/files/statfs`,
                method: "GET",
                credentials: "include",
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformResponse(data: ServerTypes.ServerQuota) {
                return transformToStruct(data, ServerTypes.ServerQuota, "/:serverId/files/statfs");
            },
            transformErrorResponse: transformError,
        }),
        enableAutostart: builder.mutation<void, ServerID>({
            query: (serverId: ServerID) => ({
                url: `/${serverId}/autostart?value=1`,
                method: "POST",
                credentials: "include",
                validateStatus: (response) => response.status === HttpStatus.Created,
            }),
            transformErrorResponse: transformError,
        }),
        disableAutostartAndStop: builder.mutation<void, ServerID>({
            query: (serverId: ServerID) => ({
                url: `/${serverId}/autostart?value=0&stop=1&timeout=60`,
                method: "POST",
                credentials: "include",
                validateStatus: (response) => response.status === HttpStatus.Created,
            }),
            transformErrorResponse: transformError,
        }),
        createFromVoucher: builder.mutation<CreateServerResponse, CreateServerFromVoucherPayload>({
            query: (payload: CreateServerFromVoucherPayload) => ({
                url: "/voucher",
                method: "POST",
                credentials: "include",
                body: payload,
                validateStatus: (response) => response.status === HttpStatus.Created,
            }),
            transformResponse(data: CreateServerResponse) {
                return transformToStruct(data, CreateServerResponse, "/voucher");
            },
            transformErrorResponse: transformError,
        }),
        formatServer: builder.mutation<void, ServerID>({
            query: (serverId: ServerID) => ({
                url: `/${serverId}/format`,
                method: "POST",
                credentials: "include",
                validateStatus: (response) => response.status === HttpStatus.Created,
            }),
            transformErrorResponse: transformError,
        }),
        stopServer: builder.mutation<void, ServerID>({
            query: (serverId: ServerID) => ({
                url: `/${serverId}/stop?timeout=120`,
                method: "POST",
                credentials: "include",
                validateStatus: (response) => response.status === HttpStatus.Created,
            }),
            transformErrorResponse: transformError,
        }),
        startServer: builder.mutation<void, ServerID>({
            query: (serverId: ServerID) => ({
                url: `/${serverId}/start`,
                method: "POST",
                credentials: "include",
                validateStatus: (response) => response.status === HttpStatus.Created,
            }),
            transformErrorResponse: transformError,
        }),
        migrate: builder.mutation<void, MigratePayload>({
            query: ({ serverId, ...values }) => ({
                url: `/${serverId}/migrate`,
                method: "POST",
                credentials: "include",
                body: { ...values },
                validateStatus: (response) => response.status === HttpStatus.Created,
            }),
            transformErrorResponse: transformError,
        }),
        createProjectFromServer: builder.mutation<
            MarketProject,
            {
                data: CreateProjectFromServerPayload;
                server_id: ServerID;
            }
        >({
            query: ({ data, server_id }) => {
                // const formData = new FormData();
                // Object.entries(data).forEach(([key, value]) => {
                //     if (value) {
                //         formData.append(key, value);
                //     }
                // });

                return {
                    url: `${server_id}/package/create`,
                    method: "POST",
                    credentials: "include",
                    body: JSON.stringify(data),
                    validateStatus: (response) => response.status === HttpStatus.Created,
                };
            },
            transformErrorResponse: transformError,
        }),

        deletePackages: builder.mutation<void, { serverId: ServerID; packageName: string; delete_data: boolean }>({
            query: ({ serverId, packageName, delete_data }) => ({
                url: `${serverId}/package`,
                method: "DELETE",
                credentials: "include",
                body: {
                    package: packageName,
                    delete_data,
                },
                validateStatus: (response) => response.status === HttpStatus.Created,
            }),
            transformErrorResponse: transformError,
        }),
        releasePort: builder.mutation<
            void,
            {
                serverId: ServerID;
                payload: ServerTypes.ReleasePortPayload;
            }
        >({
            query: ({ serverId, payload }) => ({
                url: `/${serverId}/ports/release`,
                method: "POST",
                credentials: "include",
                body: payload,
                validateStatus: (response) => response.status === HttpStatus.NoContent,
            }),

            transformErrorResponse: transformError,
        }),
        CreatePort: builder.mutation<
            ServerTypes.AllocatePortResponse,
            {
                serverId: ServerID;
                payload: ServerTypes.AllocatePortPayload;
            }
        >({
            query: ({ serverId, payload }) => ({
                url: `/${serverId}/ports/acquire`,
                method: "POST",
                credentials: "include",
                body: payload,
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformResponse(data: ServerTypes.AllocatePortResponse) {
                return transformToStruct(data, ServerTypes.AllocatePortResponse);
            },
            transformErrorResponse: transformError,
        }),
        consoleHistory: builder.query<
            {
                data: string;
                contentRange: string;
            },
            {
                serverId: ServerID;
                startByte: number;
                endByte: number;
            }
        >({
            query: (payload) => ({
                url: `/${payload.serverId}/console`,
                method: "GET",
                headers: {
                    Range: `bytes2=${payload.startByte}:${payload.endByte}`,
                },
                credentials: "include",
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),

            transformResponse(response, meta) {
                return {
                    data: response as string,
                    contentRange: meta?.response?.headers.get("Content-Range") || "",
                };
            },

            transformErrorResponse: transformError,
        }),
        updateServerParameters: builder.mutation<
            void,
            { serverId: ServerID; data: Partial<ServerTypes.PublicServer & AdminPayload> }
        >({
            query: ({ serverId, data }) => ({
                url: `/${serverId}/parameters`,
                method: "PATCH",
                credentials: "include",
                body: data,
                validateStatus: (response) => response.status === HttpStatus.Created,
            }),
            transformErrorResponse: transformError,
        }),
        antibotSetting: builder.mutation<void, { serverId: ServerID; enabled: boolean }>({
            query: ({ serverId, enabled }) => ({
                url: `/${serverId}/antibot`,
                method: "POST",
                credentials: "include",
                body: {
                    enabled,
                },
                validateStatus: (response) => response.status === HttpStatus.Created,
            }),
            transformErrorResponse: transformError,
        }),
        generateRemotefsToken: builder.mutation<GenerateRemotefsTokenResponse, ServerID>({
            query: (serverId) => ({
                url: `/${serverId}/remotefs/token`,
                method: "POST",
                credentials: "include",
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformErrorResponse: transformError,
        }),
        pingServer: builder.mutation<void, ServerID>({
            query: (serverId) => ({
                url: `/${serverId}/ping?duration=1m`,
                method: "POST",
                credentials: "include",
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformErrorResponse: transformError,
        }),
    }),
});

export const domainsApi = createApi({
    reducerPath: "domains",
    baseQuery: fetchBaseQuery({
        baseUrl: `${HostUrl}/api`,
        prepareHeaders: (headers) => {
            const transactionId = Math.random().toString(36).substr(2, 9);
            Sentry.configureScope((scope) => {
                scope.setTag("transaction_id", transactionId);
            });
            headers.set("X-transation-ID", transactionId);

            return headers;
        },
    }),
    endpoints: (builder) => ({
        checkDomainAvailability: builder.mutation<
            void,
            {
                payload: CheckAvailabilityDomainPayload;
            }
        >({
            query: ({ payload }) => ({
                url: "/domains/availability",
                method: "POST",
                credentials: "include",
                body: payload,
                validateStatus: (response) => response.status === HttpStatus.NotFound,
            }),
        }),
        getServerByDomain: builder.mutation<CheckAvailabilityDomainResponse, CheckAvailabilityDomainPayload>({
            query: (payload: CheckAvailabilityDomainPayload) => ({
                url: "/domains/availability",
                method: "POST",
                credentials: "include",
                body: payload,
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformResponse(data: CheckAvailabilityDomainResponse) {
                return transformToStruct(data, CheckAvailabilityDomainResponseStruct, "/domains/availability");
            },
            transformErrorResponse: transformError,
        }),
    }),
});

export const eventLogsApi = createApi({
    reducerPath: "eventLogs",
    baseQuery: fetchBaseQuery({
        baseUrl: `${HostUrl}/api/event_logs`,
        prepareHeaders: (headers) => {
            const transactionId = Math.random().toString(36).substr(2, 9);
            Sentry.configureScope((scope) => {
                scope.setTag("transaction_id", transactionId);
            });
            headers.set("X-transation-ID", transactionId);

            return headers;
        },
    }),
    endpoints: (builder) => ({
        eventLogById: builder.query<EventDetails, string>({
            query: (id: string) => ({
                url: `/${id}`,
                method: "GET",
                credentials: "include",
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformResponse(data: EventDetails) {
                return transformToStruct(data, EventDetails, "/:id");
            },
        }),
    }),
});

export const packagesApi = createApi({
    reducerPath: "packages",
    baseQuery: fetchBaseQuery({
        baseUrl: `${HostUrl}/api/v1/packages`,
        prepareHeaders: (headers) => {
            const transactionId = Math.random().toString(36).substr(2, 9);
            Sentry.configureScope((scope) => {
                scope.setTag("transaction_id", transactionId);
            });
            headers.set("X-transation-ID", transactionId);

            return headers;
        },
    }),
    endpoints: (builder) => ({
        // TODO: to nie są Engine tylko MarketProjects czy coś...
        searchPackages: builder.mutation<Engine[], Record<string, string>>({
            query: (q) => ({
                url: "/search",
                params: q,
                method: "GET",
                credentials: "include",
                validateStatus: (response) => response.status === 200,
                transformResponse(data: Engine[]) {
                    return transformToStruct(data, array(Engine), "/search");
                },
                responseHandler: async (res) => {
                    const text = await res.text();
                    const results = text
                        .split("\n")
                        .filter((el) => el.length)
                        .map((el) => JSON.parse(el));
                    return results;
                },
            }),
        }),
    }),
});

export const { useSearchPackagesMutation } = packagesApi;

export const {
    useServerByIdQuery,
    useLazyServerByIdQuery,
    useCreateServerMutation,
    useServerEventsQuery,
    useLazyServerEventsQuery,
    useReleasePortMutation,
    useServerQuotaQuery,
    useStartServerMutation,
    useStopServerMutation,
    useEnableAutostartMutation,
    useDisableAutostartAndStopMutation,
    useFormatServerMutation,
    useAvailableGamesQuery,
    useSetSecondaryDomainMutation,
    useSetPrimaryDomainMutation,
    useInstallPackageMutation,
    useCreateFromVoucherMutation,
    useMigrateMutation,
    useCreateProjectFromServerMutation,
    useDeletePackagesMutation,
    useLazyConsoleHistoryQuery,
    useUpdateServerParametersMutation,
    useCreatePortMutation,
    useAntibotSettingMutation,
    useLazyServerStatusQuery,
    useChangeEngineMutation,
    useGenerateRemotefsTokenMutation,
    usePingServerMutation,
} = api;

export const { useEventLogByIdQuery, useLazyEventLogByIdQuery } = eventLogsApi;

export const { useCheckDomainAvailabilityMutation, useGetServerByDomainMutation } = domainsApi;
