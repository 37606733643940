import { isAfter } from "date-fns";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { InstanceStatus } from "../../../rootTypes";
import { CurrentServerContext } from "../../../shared/components/CurrentServerProvider";
import { showApiError } from "../../../shared/helpers/showToast";
import { useActiveVouchersQuery } from "../../Billings/api";
import { BillingService } from "../../Billings/types";
import useUserData from "../../User/hooks/useUserData";
import {
    useDisableAutostartAndStopMutation,
    useEnableAutostartMutation,
    useStartServerMutation,
    useStopServerMutation,
} from "../api";
import isServicePaidFor from "../helpers/isServerPaidFor";
import { useWalletLogsQuery, useWalletTransactionsQuery } from "../modules/Wallets/api";

export default function () {
    const { refreshCurrentServerData, server, service } = useContext(CurrentServerContext);
    const [memoryUsed] = useState(0);
    const [isRunning, setIsRunning] = useState(true);
    const [isAutostartEnabled, setIsAutostartEnabled] = useState(false);
    const {
        serverStatus: { game_status },
    } = useContext(CurrentServerContext);
    const { user } = useUserData();
    const isServerOwner = useMemo(() => {
        if (service && !user.services.some((s: BillingService) => s.service_id === service.service_id)) {
            return false;
        }
        return true;
    }, [service]);

    const { data: walletLogs, isLoading: walletLogsLoading } = useWalletLogsQuery(service?.wallet_id, {
        skip: !service,
    });
    const { data: walletTransactions, isLoading: walletTransactionsLoading } = useWalletTransactionsQuery(
        service?.wallet_id,
        {
            skip: !service,
        }
    );
    const { data: activeVouchers, isLoading: activeVouchersLoading } = useActiveVouchersQuery(server?.id, {
        skip: !server,
    });
    const [enableAutostartRequest, { isLoading: isEnableAutostartLoading }] = useEnableAutostartMutation();
    const [disableAutostartAndStopRequest, { isLoading: isDisableAutostartLoading }] =
        useDisableAutostartAndStopMutation();
    const [startServerRequest] = useStartServerMutation();
    const [stopServerRequest] = useStopServerMutation();

    const refreshServerData = () => {
        // dispatch(Actions.serverCurrentRequest(serverId, navigate));
        // dispatch(Actions.activeVouchersRequest(serverId));
        // dispatch(Actions.serverQuotaRequest(serverId));
        // dispatch(Actions.serverPaymentsRequest(serverId));
    };

    const changeServerName = async (name: string) => {
        try {
            changeServerNameRequest({ serverId: server.id, name }).unwrap();
        } catch (e) {
            showApiError(e);
        }
    };

    useEffect(() => {
        if (!server || !service) {
            return;
        }
        setIsAutostartEnabled(server.autostart && !!isServicePaidFor(service));
        setIsRunning(
            !!game_status &&
                [
                    InstanceStatus.IRL_RUNNING,
                    InstanceStatus.IRL_IDLE,
                    InstanceStatus.IRL_NEW,
                    InstanceStatus.IRL_LIGHT_SLEEP,
                    InstanceStatus.IRL_DEEP_SLEEP,
                    InstanceStatus.IRL_HIBERNATED,
                ].includes(game_status as InstanceStatus)
        );
    }, [server, service, game_status]);

    const enableAutostart = useCallback(async () => {
        if (!server) {
            return false;
        }
        try {
            await enableAutostartRequest(server.id).unwrap();
            await refreshCurrentServerData();
            return true;
        } catch (e) {
            showApiError(e);
            return false;
        }
    }, [enableAutostartRequest, server]);

    const disableAutostartAndStop = useCallback(async () => {
        if (!server) {
            return false;
        }
        try {
            await disableAutostartAndStopRequest(server.id).unwrap();
            await refreshCurrentServerData();
            return true;
        } catch (e) {
            showApiError(e);
            return false;
        }
    }, [disableAutostartAndStopRequest, server]);

    const startServer = useCallback(async () => {
        try {
            await startServerRequest(server.id).unwrap();
        } catch (e) {
            showApiError(e);
        }
    }, [server, startServerRequest]);

    const stopServer = useCallback(async () => {
        try {
            await stopServerRequest(server.id).unwrap();
        } catch (e) {
            showApiError(e);
        }
    }, [server, stopServerRequest]);

    const restartServer = useCallback(() => {
        // TODO: restart
    }, []);

    const walletData = useMemo(() => {
        return {
            logs: walletLogs || [],
            transactions: walletTransactions || [],
        };
    }, [walletLogs, walletTransactions, walletLogsLoading, walletTransactionsLoading]);

    const isExpired =
        useMemo(() => service && service.expires && isAfter(new Date(), new Date(service.expires)), [service]) || false;

    return {
        isAutostartEnabled,
        refreshServerData,
        memoryUsed,
        isRunning,
        startServer,
        stopServer,
        autostartLoading: isEnableAutostartLoading || isDisableAutostartLoading,
        restartServer,
        changeServerName,
        walletDataLoading: walletLogsLoading || walletTransactionsLoading,
        walletData,
        activeVouchers,
        activeVouchersLoading,
        enableAutostart,
        disableAutostartAndStop,
        isServerOwner,
        isExpired,
    };
}
