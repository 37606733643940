import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import * as Sentry from "@sentry/browser";
import { array } from "superstruct";
import { Types } from ".";
import { HttpStatus } from "../../constants";
import { ModpileUrl } from "../../rootTypes";
import { transformToStruct } from "../../shared/helpers/customQuery";
import { transformError } from "../../shared/helpers/errors";
import { UserID } from "../../shared/types";
import { QueryParamsObject } from "./hooks/useMarketplace";
import { DeleteMediaPayload, ProjectRelease, ProjectReleaseQuery } from "./types";

export const marketplaceApi = createApi({
    reducerPath: "marketplace",
    baseQuery: fetchBaseQuery({
        baseUrl: `${ModpileUrl}/api/v1/marketplace`,
        prepareHeaders: (headers) => {
            const transactionId = Math.random().toString(36).substr(2, 9);
            Sentry.configureScope((scope) => {
                scope.setTag("transaction_id", transactionId);
            });
            headers.set("X-transation-ID", transactionId);

            return headers;
        },
    }),

    endpoints: (builder) => ({
        searchProjectReleaseByUser: builder.query<Types.MarketProject[], UserID>({
            query: (userId) => ({
                url: `/projects/search?projectMember=${userId}`,
                method: "GET",
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformResponse: (response: Types.MarketProject[]) => {
                return transformToStruct(
                    response,
                    array(Types.MarketProject),
                    "/projects/search?projectMember=:userId"
                );
            },
            transformErrorResponse: transformError,
        }),
        insertProjectRelease: builder.mutation<
            ProjectRelease,
            {
                data: Types.insertProjectReleasePayload;
                projectName: string;
            }
        >({
            query: ({ data, projectName }) => {
                return {
                    url: `/projects/${projectName}/releases`,
                    method: "POST",
                    body: data,
                    validateStatus: (response) => response.status === HttpStatus.OK,
                };
            },
            transformResponse: (response: ProjectRelease) => {
                return transformToStruct(response, ProjectRelease, "/projects/:projectName/releases");
            },
            transformErrorResponse: transformError,
        }),
        getMarketplaceMetrics: builder.query<Types.MarketEventMetric[], Types.MarketplaceMetricPayload>({
            query: (q) => {
                const queryParams = new URLSearchParams();
                Object.entries(q).forEach(([key, value]) => {
                    if (value) {
                        queryParams.append(key, value.toString());
                    }
                });

                return {
                    url: `/metrics?${queryParams}`,
                    method: "GET",
                    credentials: "include",
                    validateStatus: (response) => response.status === HttpStatus.OK,
                };
            },
            transformErrorResponse: transformError,
        }),
        getProjectReleases: builder.mutation<
            Types.ProjectReleaseResponse,
            { name: string; query?: ProjectReleaseQuery }
        >({
            query: ({ name, query }) => {
                return {
                    url: `/projects/${name}/releases`,
                    method: "GET",
                    params: query,
                    validateStatus: (response) => response.status === HttpStatus.OK,
                };
            },
            transformResponse: (response: Types.ProjectReleaseResponse) => {
                return transformToStruct(response, Types.ProjectReleaseResponse, "/projects/:name/releases");
            },
            transformErrorResponse: transformError,
        }),

        searchProject: builder.query<Types.MarketProject[], QueryParamsObject>({
            query: (params) => {
                const queryParams = new URLSearchParams();
                Object.entries(params).forEach(([key, value]) => {
                    if (value) {
                        queryParams.append(key, value.toString());
                    }
                });
                if (params.labels) {
                    queryParams.delete("labels");
                    params.labels
                        .toString()
                        .split(",")
                        .map((label) => {
                            queryParams.append("labels", label);
                        });
                }
                return {
                    url: `/projects/search?${queryParams}`,
                    method: "GET",
                    validateStatus: (response) => response.status === HttpStatus.OK,
                };
            },
            transformResponse: (response: Types.MarketProject[]) => {
                return transformToStruct(response, array(Types.MarketProject), "/projects/search");
            },
            transformErrorResponse: transformError,
        }),
        searchProjectReleasesByLabels: builder.query<Types.MarketProject[], QueryParamsObject>({
            query: (filters) => {
                const query = new URLSearchParams();
                Object.entries(filters).forEach(([key, value]) => {
                    if (value) {
                        query.append(key, value.toString());
                    }
                });
                return {
                    url: `/projects/search?${query}`,
                    method: "GET",
                    validateStatus: (response) => response.status === HttpStatus.OK,
                };
            },
            transformResponse: (response: Types.MarketProject[]) => {
                return transformToStruct(response, array(Types.MarketProject), "/projects/search");
            },
            transformErrorResponse: transformError,
        }),
        getProjectReleaseByName: builder.query<
            Types.MarketProject,
            {
                projectid: string;
                releaseid: string;
            }
        >({
            query: ({ projectid, releaseid }) => ({
                url: `/projects/${projectid}/releases/${releaseid}`,
                method: "GET",
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformResponse: (response: Types.MarketProject) => {
                return transformToStruct(response, Types.MarketProject, "/projects/:projectid/releases/:releaseid");
            },
            transformErrorResponse: transformError,
        }),
        createMarketProject: builder.mutation<Types.MarketProject, Types.CreateMarketProjectPayload>({
            query: (project) => {
                const formData = new FormData();
                const { media, ...rest } = project;

                formData.append("payload_json", JSON.stringify(rest));
                media.forEach((file) => {
                    formData.append("media", file);
                });
                return {
                    url: "/projects",
                    method: "POST",
                    body: formData,
                    validateStatus: (response) => response.status === HttpStatus.Created,
                };
            },
            transformResponse: (response: Types.MarketProject) => {
                return transformToStruct(response, Types.MarketProject, "/projects");
            },
            transformErrorResponse: transformError,
        }),
        editMarketProject: builder.mutation<
            Types.MarketProject,
            {
                slug: string;
                payload: Types.EditMarketProjectPayload;
            }
        >({
            query: ({ slug, payload }) => {
                const formData = new FormData();
                const { media, ...rest } = payload;

                formData.append("payload_json", JSON.stringify(rest));
                media.forEach((file) => {
                    formData.append("media", file);
                });

                return {
                    url: `/projects/${slug}`,
                    method: "PATCH",
                    body: formData,
                    validateStatus: (response) => response.status === HttpStatus.OK,
                };
            },
            transformResponse: (response: Types.MarketProject) => {
                return transformToStruct(response, Types.MarketProject, "/projects");
            },
            transformErrorResponse: transformError,
        }),
        deleteMarketProjectMedia: builder.mutation<void, DeleteMediaPayload>({
            query: ({ slug, name }) => ({
                url: `/projects/${slug}/media/${name}`,
                method: "DELETE",
                validateStatus: (response) => response.status === HttpStatus.NoContent,
            }),
            transformErrorResponse: transformError,
        }),
        getReviews: builder.mutation<Types.GetMarketProjectReviewsResponse, Types.ReviewsQueryData>({
            query: (q) => ({
                url: `/projects/${q.projectId}/reviews`,
                method: "GET",
                credentials: "include",
                validateStatus: (response) => response.status === HttpStatus.OK,
                params: {
                    limit: q.limit,
                    before: q.before,
                },
            }),

            transformResponse: (response: Types.GetMarketProjectReviewsResponse) => {
                return transformToStruct(
                    {
                        ...response,
                        reviews: response.reviews.sort(
                            (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
                        ),
                    },
                    Types.GetMarketProjectReviewsResponse,
                    "getReviews /projects/:projectId/reviews"
                );
            },
            transformErrorResponse: transformError,
        }),
        createReview: builder.mutation<Types.PublicMarketProjectReview, Types.CreateReviewData>({
            query: (q) => ({
                url: `/projects/${q.projectId}/reviews`,
                body: q.data,
                method: "POST",
                credentials: "include",
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformResponse: (response: Types.PublicMarketProjectReview) => {
                return transformToStruct(
                    response,
                    Types.PublicMarketProjectReview,
                    "createReview /projects/:projectId/reviews"
                );
            },
            transformErrorResponse: transformError,
        }),
        createFollow: builder.mutation<Types.FollowedMarketplaceProjects, Types.FollowMarketplaceProjectData>({
            query: (q) => ({
                url: `/projects/${q.slug}/follow`,
                method: "POST",
                credentials: "include",
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformErrorResponse: transformError,
        }),
        deleteFollow: builder.mutation<Types.FollowedMarketplaceProjects, Types.FollowMarketplaceProjectData>({
            query: (q) => ({
                url: `/projects/${q.slug}/follow`,
                method: "DELETE",
                credentials: "include",
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformErrorResponse: transformError,
        }),
        getFollowedByUser: builder.query<Types.FollowedMarketplaceProjects, void>({
            query: () => ({
                url: "/projects/follows",
                method: "GET",
                credentials: "include",
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformErrorResponse: transformError,
        }),

        getProject: builder.query<Types.GetMarketProjectResponse, string>({
            query: (slug) => ({
                url: `/projects/${slug}`,
                method: "GET",
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformResponse: (response: Types.GetMarketProjectResponse) => {
                return transformToStruct(response, Types.GetMarketProjectResponse, "/projects/:slug");
            },
            transformErrorResponse: transformError,
        }),

        getProjectBySlug: builder.mutation<Types.GetMarketProjectResponse, string>({
            query: (slug) => ({
                url: `/projects/${slug}`,
                method: "GET",
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformResponse: (response: Types.GetMarketProjectResponse) => {
                return transformToStruct(response, Types.GetMarketProjectResponse, "/projects/:slug");
            },
            transformErrorResponse: transformError,
        }),

        deleteRelease: builder.mutation<
            void,
            {
                slug: string;
                releaseId: string;
            }
        >({
            query: ({ slug, releaseId }) => ({
                url: `/projects/${slug}/releases/${releaseId}`,
                method: "DELETE",
                validateStatus: (response) => response.status === HttpStatus.NoContent,
            }),
            transformErrorResponse: transformError,
        }),
    }),
});

export const {
    useSearchProjectQuery,
    useCreateMarketProjectMutation,
    useEditMarketProjectMutation,
    useSearchProjectReleaseByUserQuery,
    useGetProjectQuery,
    useLazyGetProjectQuery,
    useInsertProjectReleaseMutation,
    useGetProjectBySlugMutation,
    useGetProjectReleasesMutation,
    useGetReviewsMutation,
    useGetMarketplaceMetricsQuery,
    useCreateReviewMutation,
    useCreateFollowMutation,
    useGetFollowedByUserQuery,
    useDeleteFollowMutation,
    useDeleteReleaseMutation,
    useDeleteMarketProjectMediaMutation,
} = marketplaceApi;
