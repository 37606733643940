import { Button, Checkbox, Paragraph, Space, Title } from "csrvui";
import { Field, FieldProps, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { ModalContent } from "../../../../shared/components/CreateTicketModal/styles";
import ResponsiveModal from "../../../../shared/components/ResponsiveModal";
import useEventTracker from "../../../../shared/hooks/useEventTracker";
import { EventAction } from "../../../../shared/types";

interface Props {
    name: string;
    onUninstall?: (shouldDeleteFiles: boolean) => void;
    isOpen: boolean;
    handleClose: () => void;
}

const ConfirmUninstallModal = ({ name, onUninstall, handleClose, isOpen }: Props) => {
    const { t } = useTranslation();
    const { trackEvent } = useEventTracker();
    return (
        <ResponsiveModal center onClose={handleClose} animationDuration={0} open={isOpen}>
            <ModalContent>
                <Formik
                    onSubmit={(values) => {
                        onUninstall && onUninstall(values.shouldDeleteFiles);
                        trackEvent(EventAction.UNINSTALL_PACKAGE, { label: name });
                        handleClose();
                    }}
                    initialValues={{
                        shouldDeleteFiles: false,
                    }}
                >
                    {({ values, errors }) => (
                        <Form>
                            <Title>{t("marketplace.confirm_uninstall.title")}</Title>
                            <Paragraph $level={3}>
                                {t("marketplace.confirm_uninstall.desc")} {name}
                            </Paragraph>
                            <Space $padding="10px 4px" style={{ marginTop: "16px" }} $direction="horizontal">
                                <Field id="shouldDeleteFiles" name="shouldDeleteFiles">
                                    {({ field }: FieldProps) => (
                                        <Checkbox
                                            id="shouldDeleteFiles"
                                            label={t("marketplace.confirm_uninstall.delete_files")}
                                            $error={!!errors.shouldDeleteFiles}
                                            checked={values.shouldDeleteFiles}
                                            {...field}
                                        />
                                    )}
                                </Field>
                            </Space>
                            <Space $padding={0} $direction="horizontal">
                                <Button onClick={(e) => e.stopPropagation()} type="submit" $variant="danger">
                                    {t("marketplace.confirm_uninstall.uninstall")}
                                </Button>{" "}
                                <Button
                                    type="button"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleClose();
                                    }}
                                >
                                    {t("common.cancel")}
                                </Button>
                            </Space>
                        </Form>
                    )}
                </Formik>
            </ModalContent>
        </ResponsiveModal>
    );
};

export default ConfirmUninstallModal;
