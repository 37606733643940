import { Card, ExternalLink, Image, Paragraph, Space, Title } from "csrvui";
import React from "react";
import { Link } from "react-router-dom";
import useWindowSize from "../../../../shared/hooks/useWindowSize";
import { MarketProject } from "../../types";

interface Props {
    item: MarketProject;
    link?: string;
}

const MarketplaceListItem: React.FC<Props> = ({
    item: {
        name,
        slug,
        author_info: { author_name, website_url },
        image,
    },
    link,
}) => {
    const { width } = useWindowSize();
    return (
        <Link to={link || `/m/projects/${slug}`} style={{ textDecoration: "none" }}>
            <Card
                $maxWidth={200}
                $fullHeight
                style={{
                    minWidth: width > 250 ? 250 : "100%",
                }}
            >
                <Space $padding="20px 0 0 0">
                    <Space
                        $padding="0 20px"
                        $direction="horizontal"
                        $align="center"
                        $oppositeAlign="center"
                        $fullHeight
                    >
                        <Image
                            src={image || `https://placehold.co/100x100?text=${name}`}
                            style={{
                                objectFit: "cover",
                                width: "100%",
                                maxHeight: "100%",
                            }}
                        />
                    </Space>

                    <Space $padding="10px 20px 20px 20px">
                        <Space $padding={0} $gap={0}>
                            <Title $level={4}>{name}</Title>
                            <Paragraph>Krotki opis 140 (?) znakow</Paragraph>
                        </Space>
                        <Space $padding={0} $direction="horizontal">
                            <Paragraph $level={5}>
                                Author:{" "}
                                <Paragraph $level={5}>
                                    {website_url ? (
                                        <ExternalLink
                                            href={website_url}
                                            style={{
                                                margin: 0,
                                                padding: 0,
                                                fontSize: "inherit",
                                            }}
                                            target="_blank"
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            {author_name}
                                        </ExternalLink>
                                    ) : (
                                        author_name
                                    )}
                                </Paragraph>
                            </Paragraph>
                        </Space>
                    </Space>
                </Space>
            </Card>
        </Link>
    );
};

export default MarketplaceListItem;
