import { InternalLink, Paragraph, Space } from "csrvui";
import { FaUser } from "react-icons/fa";
import { PublicUser } from "../../../User/types";
import { SearchResultWrapper } from "../../styles";

interface Props {
    user: PublicUser;
}

const UserResult = ({ user }: Props) => {
    return (
        <SearchResultWrapper>
            <Space $padding={0} $align="space-between" $oppositeAlign="center" $direction="horizontal">
                <FaUser size={36} />
                <Space $gap={3} $padding={0}>
                    <Paragraph style={{ fontSize: "20px", fontWeight: 500 }}>{user.email}</Paragraph>
                    <Paragraph style={{ width: "100%", textAlign: "start" }}>#{user.id}</Paragraph>
                </Space>
            </Space>
            <InternalLink to={`/user/${user.id}`}>Przejdź do profilu</InternalLink>
        </SearchResultWrapper>
    );
};

export default UserResult;
